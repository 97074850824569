export default {
    getAssignmentModules(state: any) {
        return state.modules
    },
    getAssignment(state: any) {
        return state.assignment
    },
    userSubmission(state: any) {
        return state.userSubmission
    }

}
