import axios from "@/axios";
import {CourseInterface, CourseSubsectionIds} from "@/interfaces/course.interface";
import {FullSection, Section, Subsection} from "@/interfaces/course-section.interface";

type CourseResponse = {
    data: CourseInterface | null;
}
type CourseModuleResponse = {
    data: FullSection | null;
}
type CourseSubsectionResponse = {
    data: Subsection | null;
}
type CourseCompletedResponse = {
    data: {

        id: number;
        courseSubsectionId: number;
        userId: number;
        createdAt: string;
        updatedAt: string;
    };
}
type CourseSubsectionSortedResponse = {
    data: CourseSubsectionIds[]
}
export default {
    //declare get course function with right type
    async getCourse(context: any, payload: { courseId: number, teaser: boolean }): Promise<number> {
        try {
            const {
                data,
                status
            } = await axios.get<CourseResponse>(`/courses/${payload.courseId}?teaser=${payload.teaser}`);
            context.commit('setCourse', data);
            return status;
        } catch (e) {
            return 500;
        }
    },
    async getCourseModules(context: any, payload: { courseId: number, relations: true, refresh: false }): Promise<number> {
        try {
            if (payload.refresh) {
                await context.dispatch('clearAll')
            }
            const {
                data,
                status
            } = await axios.get<CourseModuleResponse>(`/courses/${payload.courseId}/sections?relations=${payload.relations}`);
            context.commit('setCourseModules', data);
            const fullSection: FullSection | any = data;
            if (fullSection?.lastVisitedSubsection?.subsectionId) {
                await context.dispatch('getCourseSubsection', fullSection?.lastVisitedSubsection?.subsectionId);
                console.log('last visited', fullSection?.lastVisitedSubsection)

            } else {
                //get all subsections sorted by order
                await context.dispatch('getCourseSubsectionSorted', +payload.courseId)
                if (context.state.courseSubsectionsSorted.length > 0) {
                    await context.dispatch('getCourseSubsection', context.state.courseSubsectionsSorted[0])
                }
            }
            return status;
        } catch (error) {
            return 500;
        }
    },
    async getCourseSubsection(context: any, subsection: number): Promise<number> {
        try {
            const {data, status} = await axios.get<CourseSubsectionResponse>(`/course-subsections/${subsection}`);
            context.commit('setCourseActiveSubsection', data);
            context.commit('setCourseActiveSection', (data as any)?.section);
            return status;
        } catch (e) {
            return 500;
        }
    },
    async getCourseSubsectionSorted(context: any, courseId: number): Promise<number> {
        try {
            const {
                data,
                status
            } = await axios.get<CourseSubsectionSortedResponse>(`/courses/${courseId}/subsectionIds`);
            //check if data of type array
            if (Array.isArray(data)) {
                const sorted: number[] = [];
                data.map((item: CourseSubsectionIds) => {
                    sorted.push(item.id)
                })
                context.commit('setCourseSubsectionsSorted', sorted);
            }
            return status;
        } catch (e) {
            return 500;
        }
    },
    async getCourseSection(context: any, sectionId: number): Promise<number> {
        try {
            const section = context.state.courseModules.sections.find((s: Section) => s.id === sectionId);
            context.commit('setCourseActiveSection', section);
            return 200;
        } catch (e) {
            return 404;
        }
    },
    async addNote(context: any, payload: { subsectionId: number, note: string }): Promise<number> {
        try {
            // Add notes to subsection in course section
            const path = `/course-subsections/${payload.subsectionId}/notes`;
            const {status} = await axios.post(path, {
                note: payload.note, position: 0
            });
            return status;
        } catch (e) {
            return 500;
        }
    },
    async completeSubsection(context: any): Promise<number> {
        try {
            if (!context.state.courseActiveSubsection?.isCompleted) {
                const {status} = await axios.post<CourseCompletedResponse>(
                    `/course-subsections/${context.state.activeSubsection?.id}/complete`
                );
                return status;
            }
            return 201;
        } catch (e: any) {
            return e.response.status;
        }
    },
    async nextSubsection(context: any, subsectionId: number): Promise<number> {
        try {
            const info = context.state.courseSubsectionsSorted;
            // Get index of current subsection in array
            const index = info.findIndex((s: number) => s === subsectionId);
            // Get next subsection in array if exists
            const nextSubsection = info[index + 1];
            if (nextSubsection) {
                return context.dispatch('getCourseSubsection', nextSubsection);
            }
            return 404;
            //get the data of next subsection
        } catch (e) {
            return 500;
        }
    },
    async previewsSubsection(context: any, subsectionId: number): Promise<number> {
        try {
            const info = context.state.courseSubsectionsSorted;
            // Get index of current subsection in array
            const index = info.findIndex((s: number) => s === subsectionId);
            // Get previous subsection in array if exists
            const previousSubsection = info[index - 1];
            if (previousSubsection) {
                return context.dispatch('getCourseSubsection', previousSubsection);
            }
            return 404;
        } catch (e) {
            return 500;
        }
    },
    async clearCourse(context: any) {
        context.commit('setCourse', null);
    },
    async clearCourseModules(context: any) {
        context.commit('setCourseModules', null);
    },
    async clearCourseActiveSubsection(context: any) {
        context.commit('setCourseActiveSubsection', null);
    },
    async clearCourseActiveSection(context: any) {
        context.commit('setCourseActiveSection', null);
    },
    async clearAll(context: any) {
        context.commit('setCourse', null);
        context.commit('setCourseModules', null);
        context.commit('setCourseActiveSubsection', null);
        context.commit('setCourseActiveSection', null);
        context.commit('setCourseSubsectionsSorted', []);
    }

}
