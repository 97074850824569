import { mapGetters } from "vuex";
import {
  RubricAssessment,
  RubricAssessmentResponse,
  UploadedMedia,
} from "@/interfaces/assessment.interface";

export default {
  criteria(state: any) {
    return state.criteria;
  },
  getRubric(state: any) {
    return state.rubric;
  },
  title(state: any) {
    return state.title;
  },
  allGradePoint(state: any) {
    let total = 0;
    state.criteria.map((grade: any) => {
      total += grade.maxGrade;
    });
    return total;
  },

  studentRubricAssessment(state: any): RubricAssessment {
    return state.studentRubricAssessment;
  },
  studentAssessment(state: any): RubricAssessmentResponse {
    return state.studentAssessment;
  },
  getStudentRubricAssessment(state: any) {
    return state.studentRubricAssessment;
  },

  activeFile(state: any): UploadedMedia | null {
    return state.activeFile;
  },
  getSelectedRatings(state: any) {
    const newObj = [] as any;
    state.selectedRatings.map((item: any) => {
      newObj.push({
        criteriaId: item.criteriaId,
        ratingId: item.ratingId,
        feedback: item.feedback,
      });
    });
    return newObj;
  },
  getTotalRubricPoint(state: any) {
    let total = 0;
    state.selectedRatings?.map((item: any) => {
      total += item.grade;
    });
    return total;
  },
  getMaxGrade(state: any) {
    let total = 0;

    state.studentRubricAssessment.criteria.map((criteria: any) => {
      total += criteria.maxGrade;
    });
    return total;
  },
  errorInRating(state: any) {
    return state.errorInRating;
  }

};
