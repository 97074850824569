import { RouteRecordRaw } from "vue-router";
const profileRoutes: Array<RouteRecordRaw> = [

    {
    path: "/profile",
        name: "profile",
    component: () => import("@/layout/Profile.vue"),
    props: true,
    meta: {
    requiresAuth: true,
},
    children: [
        {
            path: "",
            name: "Saved Posts",
            component: () => import("@/page/profile/SavedPosts.vue"),
        },
        {
            path: "my-events",
            name: "My Events Profile",
            component: () => import("@/page/profile/MyEvents.vue"),
        },
        {
            path: "my-course",
            name: "My Courses Profile",
            component: () => import("@/page/profile/MyCourses.vue"),
        },
        {
            path: "my-coaching-session",
            name: "My Coaching Session Profile",
            component: () => import("@/page/profile/Dashboard.vue"),
        },
        {
            path: "favorites",
            name: "Favorites Profile",
            component: () => import("@/page/profile/Favorites.vue"),
        },
        {
            path: "purchases",
            name: "Purchase Profile",
            component: () => import("@/page/profile/Purchases.vue"),
        },
        {
            path: "edit-profile",
            name: "Edit Profile",
            component: () => import("@/page/profile/EditProfile.vue"),
        },
    ],
} ] ;


export default profileRoutes;
