export default {
    setSearchList(state: any, results: any) {
        state.searchList = results
    },
    setNotification(state: any, notification: any) {
        state.notificationList = notification;
    },
    clearSearchResult(state: any) {
        state.searchList = []
    },
    setMenuValue(state:any,menuValue:boolean){
        return state.menuActive = menuValue;
    }

}

