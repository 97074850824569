import {
    getCoursesectionsbyId,
    addDiscussionBoardsComment,
    addDiscussionBoardsCommentCourse
} from "@/services/courses.service";
import axios from "@/axios";


export default {
    async getCourseSections(context: any, payload: any) {
        const response = await getCoursesectionsbyId(payload);
        context.commit('setSections', response.data.sections);
        context.commit('sefinishedSubsections', response.data.finishedSubsections);
        context.commit('setSectionsData', response.data);
        try {
            if (response.data.sections) {
                const arry: any = [];
                response.data.sections.map((section: any, index: number) => {
                    section.subsections.map((subsections: any, index: number) => {
                        arry.push({
                            sectionId: section.id,
                            SubsectionId: subsections.id
                        })
                    })
                })


                context.commit('setpaginationArray', arry);
            }
        } catch (error) {
            console.log("there is no sections ")
        }


    },
    async getCourseNotes(context: any, payload: { courseId: number }) {
        const path = `/courses/${payload.courseId}/notes`;
        const response = await axios.get(path);
        const notes = response.data;
        context.commit('setNotes', notes);
    },
    async getCourseSubsectionNotes(context: any, subSectionId: number) {
        const path = `/course-sections/${subSectionId}/notes`;
        const response = await axios.get(path);
        const notes = response.data;
        context.commit('setSubsectionNotes', notes);
    },
    async getCourseAnnouncements(context: any, data: { courseId: number, limit: number, offset: number }) {
        const path = `/courses/${data.courseId}/announcements?limit=${data.limit}&offset=${data.offset}`;
        const response = await axios.get(path);
        context.commit('setAnnouncements', response.data.data);
        context.commit('setAnnouncementsCount', response.data.count);

    },
    async addDiscussionBoardsCommentModule(context: any, payload: any) {
        await addDiscussionBoardsComment(payload.id, payload.content);
    },

    async addDiscussionBoardsCommentCourse(context: any, payload: any) {
        await addDiscussionBoardsCommentCourse(payload.id, payload.content);
    },
    async getCourseModules(context: any, courseId: number) {
        const response = await axios.get(`/courses/${courseId}/sections`);
        context.commit('setCourseModules', response.data);
    },
    async checkEnrolled(context: any, courseId: number) {
        const response = await axios.get(`/courses/${courseId}/check-enrollment`);
        context.commit('setIsEnrolled', response.data);
    },
    async getCourseModule(context: any, sectionId: number) {
        const response = await axios.get(`course-sections/${sectionId}`);
        context.commit('setModule', response?.data.section);
    },
    async getCourseDataOnly(context: any, courseId: number) {
        const path = `/courses/${courseId}?teaser=false`
        const course = await axios.get(path);
        context.commit('setLightCourse', course.data);
    },
    async clearCourseModule(context: any) {
        context.commit('setModule', null);
    },
    async clearCourseModules(context: any) {
        context.commit('setCourseModules', []);
    },
    async clearLightCourse(context: any) {
        context.commit('setCourseModules', null);
    },
    async clearCourseNotes(context: any) {
        context.commit('setNotes', []);
    }

}
