export const library = {
  namespaced: true,
  state: () => ({
    isModalVisible: false,
    libraryMedia: {},
  }),
  mutations: {
    showModal(
      state: { isModalVisible: boolean; libraryMedia: any },
      payload: { libraryMedia: any }
    ) {
      state.isModalVisible = true;
      state.libraryMedia = payload.libraryMedia;
    },
    hideModal(state: { isModalVisible: boolean }) {
      state.isModalVisible = false;
    },
  },
  actions: {
    showModal({ commit }: any, payload: any) {
      commit("showModal", payload);
    },
    hideModal({ commit }: any) {
      commit("hideModal");
    },
  },
  getters: {
    isModalVisible(state: any) {
      return state.isModalVisible;
    },
    libraryMedia(state: any) {
      return state.libraryMedia;
    },
  },
};
