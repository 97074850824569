import axios from "@/axios";

export default {
  async Login(context: any, payload: any) {
    try {
      await axios.post("auth/login", payload);
      context.commit("setlogin", true);
    } catch (error: any) {
      context.commit("setlogin", false);
      context.commit("setLoginError", error);
    }
  },
  async Signup(context: any, payload: any) {
    try {
      await axios.post("auth/register", payload);
      context.commit("setSignup", true);
    } catch (error: any) {
      context.commit("setSignup", false);
      context.commit("setSignupError", error);
    }
  },
  async SignupInstructor(context: any, payload: any) {
    try {
      await axios.post("auth/register/instructor", payload);
      context.commit("setSignup", true);
    } catch (error: any) {
      context.commit("setSignup", false);
      context.commit("setSignupError", error);
    }
  },
};
