import _ from "lodash";

export default {
  getQuzies(state: any) {
    return state.quizes;
  },
  getquiz(state: any) {
    return state.quiz;
  },

  getExam(state: any) {
    return state.getExam;
  },
  getFullGrade(state: any) {
    return state.fullGrade;
  },
  getMyGrade(state: any) {
    return state.myGrade;
  },
  getReviewQuestion(state: any) {
    const grouped_data = _.groupBy(state.reviewQuestion, "id");
    const newObj = Object.entries(grouped_data).map(([key, value]) => {
      return {
        questionId: key,
        answers: value,
      };
    });
console.log("newObj",newObj);
    return newObj;
  },
  getNoAttempt(state: any) {
    return state.NoAttempt;
  },
  getAllQuizesAttempt(state: any) {
    return state.AllQuizesAttempt;
  },
  getAllQuizesInstructor(state: any) {
    return state.AllQuizesInstructor;
  },
  QuizesInstructor(state: any) {
    return state.QuizesInstructor;
  },
};
