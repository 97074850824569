'use strict';
import axios from "@/axios";
import AddQuizAnswerInterface from '@/interfaces/quiz.answer.instructor.interface';

export default {
    createAnswer: async function (context: any, payload: AddQuizAnswerInterface) {
        const response = await axios.post(`/answers`, payload);
        context.commit("setAnswers", response.data);
      },
      updateAnswer: async function (context: any, payload: any) {
        const response = await axios.patch(`/answers/${payload.id}`, payload.body);
        context.commit("setAnswers", response.data);
      },

      updateSortingAnswer: async function (context: any, payload: any) {
       await axios.put(`/answers`, payload.body);
      },
      deleteAnswer: async function (context: any, id: number) {
        await axios.delete(`/answers/${id}`);
        context.commit("setAnswers", null);
      },
              //// answers Actions
  getAllAnswers: async function (context: any, questionId: number) {
    const response = await axios.get(
      `/answers?questionId=${questionId}`
    );
    context.commit("setAnswers", response.data);
  },
  
  getAnswer: async function (context: any, id: number) {
    const response = await axios.get(`/answers/${id}`);
    context.commit("setAnswers", response.data);
  },


}

