import axios from "axios";
import store from "@/store";
import { computed } from "vue";

const axiosInstance = axios.create({
  // baseURL: "https://services.himam.com/api/",
  baseURL: "https://development.himam.com/api/",
});

const accessToken = computed(() => {
  return store.getters["auth/accessToken"];
});

const locale: any = computed(() => {
  return store.getters["appLocale"];
});
const language = locale?.value?.toLowerCase();
axiosInstance.interceptors.request.use(
  (config) => {
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }

    config.headers["Authorization"] = `Bearer ${accessToken.value}`;
    config.headers["language"] = `${language}`;

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const {
      response: { status },
    } = error;

    if (status === 401) {
      await store.dispatch("auth/removeAuthentication");
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
