export default {
  "noTimeLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no time limit for exam"])},
  "noAttemptLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no attempt limit for exam"])},
  "rubric": {
    "rubrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrics"])},
    "Add new Rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Rubric"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "longDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long Description "])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "RatingScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating Score"])},
    "RatingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating Title"])},
    "RatingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating Description"])},
    "Criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criteria"])},
    "Ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "AddCriteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Criteria"])},
    "totalPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Point "])},
    "createRubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Rubric"])},
    "No Rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You havn't created any rubric yet. Click to button to create one."])},
    "titleValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter rubric title "])},
    "criteriaValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubric must contain one Criteria at least"])},
    "allRubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALl Rubrics"])},
    "rubricUpdateWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rubric is already used in this course. You can't edit it."])},
    "rubricDeleteWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rubric is already used in this course. You can't delete it."])},
    "rubricAssessmentValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all Criteria Rating for assessment"])},
    "addFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Feedback"])},
    "changeFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Feedback"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Feedback"])},
    "editRubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Rubric"])},
    "unsavedChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have unsaved changes. Are you sure you want to leave this page?"])},
    "gradeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can't create rating with the same grade as previous rating"])}
  },
  "grades": {
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grades"])},
    "Quizas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "isCountedTowardsFinalGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Counted Towards Final Grade"])},
    "createGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Grade Item"])},
    "gradeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "shortGradeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short Name"])},
    "gradeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Description"])},
    "maxPointsGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Maximum Points  "])},
    "gradeCanExceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can Exceed "])},
    "gradeBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus  "])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "grading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grading"])},
    "nameValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade name is required"])},
    "maxPointValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum points is required"])},
    "rubricValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubric is required"])},
    "noGradeItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any student to grade."])},
    "add new grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Grade"])},
    "updateGradeItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Grade Item"])},
    "allGrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Grades"])},
    "rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubric"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "gradeFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade Feedback for"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "addFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Feedback"])},
    "EnterGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Grade"])},
    "gradeFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade for "])},
    "no grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You havn't grade yet.Click to button to add one. "])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "max point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Point"])},
    "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "enter grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Grade"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
    "assessmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment for"])},
    "totalGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Rubric Grade"])},
    "totalGrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Grade"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "allGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Grade"])},
    "desctionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion Board"])},
    "gradeDeleteWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This grade is already used in this course. You can't delete it."])},
    "noGradeStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have grade yet"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
  },
  "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graded?"])},
  "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempts"])},
  "studentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student Name"])},
  "Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])},
  "Rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrics"])},
  "studentGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Grade"])},
  "SearchOrAddTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search or add a topic"])},
  "Grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grades"])},
  "NewRubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Rubric"])},
  "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
  "allQuizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Quizzes"])},
  "AddTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Topic"])},
  "addQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Question"])},
  "timeLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Limit  (minutes)"])},
  "invalidtimeLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid time limit"])},
  "choosePoolAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the answer of question:"])},
  "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true"])},
  "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["false"])},
  "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz "])},
  "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade:"])},
  "correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correct"])},
  "unCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["false answer "])},
  "correctAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" the correct answer is:"])},
  "Question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
  "writeAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your answer here ..."])},
  "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])},
  "YourGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Grade:"])},
  "ReviewExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Exam"])},
  "Ooops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ooops!"])},
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can’t find the page you requested."])},
  "backCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to course"])},
  "backToStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to student"])},
  "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Home"])},
  "oneDirection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Direction"])},
  "twoDirection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two Direction"])},
  "noOfQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Question"])},
  "timeRemaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time remaining"])},
  "invalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity must larger than 0 and less or equal than "])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Quantity:"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title:"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description:"])},
  "invalidattemptLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid attempt limit"])},
  "attemptLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempt Limit"])},
  "SHowALlEnrollment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show All Enrollments"])},
  "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollments"])},
  "Random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random"])},
  "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])},
  "ExamType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Type:"])},
  "enableDiscussionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Discussion Board"])},
  "examTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Title"])},
  "examTitlerequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam title is required"])},
  "examDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Description"])},
  "examTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Topic"])},
  "examDescriptionrequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam description is required"])},
  "isOmniDirectional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is one Directional"])},
  "isTimeLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time limit"])},
  "isAttemptLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No attempt limit"])},
  "QuestionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Limit"])},
  "invalidQuestionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Question Limit"])},
  "createExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Exam"])},
  "editExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Exam"])},
  "DeleteExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Exam"])},
  "editquestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit question"])},
  "lockDiscussionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock Discussion Board"])},
  "openDiscussionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Discussion Board"])},
  "Discussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Discussion"])},
  "disableDiscussionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable Discussion Board"])},
  "learningTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning Types"])},
  "Passwords_not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
  "OnlineEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institute"])},
  "Face2Face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite"])},
  "noResultFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No result found"])},
  "incorrictOldpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incorrict old password"])},
  "Hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
  "welcomePlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our platform"])},
  "paymentPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Policy"])},
  "No_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No requests"])},
  "Date_is_Invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Date is Invalid ( Start Date must be After Today )"])},
  "Please_Valid_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a Valid URL"])},
  "Visit_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Link"])},
  "liveOnlineLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live lecture online"])},
  "onSiteLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-site lecture"])},
  "visitLectureLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit lecture link"])},
  "SELF_PACED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Paced "])},
  "LIVE_ONLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live  Classes"])},
  "ON_SITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Site"])},
  "validlocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter location"])},
  "validtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter title"])},
  "validemployementType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Employment Type"])},
  "validorganizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Organization Name"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
  "workEmploymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Employment Type"])},
  "credentialType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of Credential"])},
  "PleaseEnterIssuingOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter issuing organization"])},
  "credentialTypeisRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credential Type is required"])},
  "certificateNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate Name is required"])},
  "linkedIn_should_be_a_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkedin address must be valid"])},
  "introductoryVideo_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introductory video must be less than 99MB"])},
  "Please_enter_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter start date"])},
  "Please_enter_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter end date"])},
  "compare_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure that ending date coming after starting dates"])},
  "Price_shouldnt_be_empty_or_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price must not be empty or zero It must be more than 100 SAR  "])},
  "Please_enter_specializations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter specializations"])},
  "selectlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please select language"])},
  "proficiency_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proficiency is required"])},
  "Please_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required"])},
  "Select_dgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select degree"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "Overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "tellMeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell Me More"])},
  "Coming_Soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon..."])},
  "there_is_no_content_in_this_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is no content in this section"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "There_is_no_attachments_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no attachments yet"])},
  "Download_File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download File"])},
  "Course_Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Content"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "Mark_as_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as complete"])},
  "Resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "isDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add discount"])},
  "PrivateGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Group"])},
  "PublicGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Group"])},
  "Group_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group created at"])},
  "No_upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No upcoming events."])},
  "Joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joined"])},
  "People": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
  "About-this-Upcoming-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming events"])},
  "Visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible"])},
  "About-this-gorup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About this group"])},
  "Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "EditPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Phone Number"])},
  "Oldpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
  "ContentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content should not be empty"])},
  "currentlyNotfication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently don’t have any notifications"])},
  "groupCommentEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit comment"])},
  "Public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
  "Private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
  "Anyone-P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anyone can see who’s in the group and what they post."])},
  "No-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No one can see who’s in the group and what they post."])},
  "AttachVidio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach and edit an explanatory video about yourself"])},
  "AddPositionsYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add positions you have worked with"])},
  "incorrictpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect old password"])},
  "Program-Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program & Courses"])},
  "Program-Courses-p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join highly interactive programs designed to help participants implement new learning with immediate impact."])},
  "Mulhem-Program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulhem Program"])},
  "Mulhem-Program-p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational journey that takes leaders through different tracks depending on their individualized leadership development needs."])},
  "Webinars-workshops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webinars & Events"])},
  "Webinars-workshops-p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join highly interactive programs designed to help participants implement new learning with immediate impact."])},
  "NoEventsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Events Available"])},
  "SeeMorePosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more posts"])},
  "postMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop post media..."])},
  "coverMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop cover event..."])},
  "Seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...See more"])},
  "Newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "EditPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Password"])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Your Location Address"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "Favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
  "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRUSTED BY"])},
  "TermsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Privacy Policy"])},
  "ViewMorePost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More Post"])},
  "ViewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More"])},
  "Invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
  "noQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no qize yet."])},
  "noAnncouncment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no announcement yet."])},
  "PublicGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public group, Join us to see "])},
  "posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posts."])},
  "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["events."])},
  "Auth": {
    "codeModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two Factor Authentication"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email has been sent to your inbox"])},
      "sub-body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign in using the email sent,if you can't find it please check your spam folder."])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    },
    "loginModal": {
      "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t have an account?"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register here"])}
    },
    "LoginPage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
      "Empty-Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a years of Experience"])},
      "login-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])},
      "invalid-ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid ID Number"])},
      "invalid-SCFHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid SCFHS ID "])},
      "invalid-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a location"])},
      "invalid-institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a Institute"])},
      "invalid-occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a occupation"])},
      "invalid-gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a gender"])},
      "invalid-Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid years of experience"])},
      "required-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address is required"])},
      "required-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
      "User-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or password is incorrect"])},
      "server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something wrong please try again later"])},
      "invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a password of 8 characters or more"])},
      "invalid-firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a first name"])},
      "invalid-lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a last name"])},
      "invalid-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid phone number"])}
    },
    "SignupPage": {
      "EmailAndPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email and phone number must be valid"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure you fill all information correctly"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "IDNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Number"])},
      "currentPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Position"])},
      "workingAreas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Areas"])},
      "yearsOfExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of Experience"])},
      "selectNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Now"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code shown below"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
      "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number Must Be a Valid"])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Must be a Valid"])},
      "alreadyUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email address is already used"])},
      "invalid-password-phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number and password must be valid"])},
      "invalid-password-phoneNumber-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number , password and email must be valid"])},
      "invalid-email-phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number and email must be valid"])}
    },
    "signupModal": {
      "signupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signup"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "sName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "tName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login here"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])}
    },
    "forgetPasswordModal": {
      "forgetPasswordHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
    },
    "verificationModal": {
      "verificationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you didn’t receive any email, please click the resend button"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Email"])}
    },
    "messagesModal": {
      "messagesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "emailMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Email Has Been Sent"])},
      "emailMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Check Your Inbox,if you can't find it please check your spam folder."])},
      "passwordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Password Has Been Reset!"])}
    },
    "resetPasswordModal": {
      "restPasswordHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Your Password"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])}
    }
  },
  "library": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library is currently empty."])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA endeavors to be a lifelong learning partner for your leadership journey.  In addition to the other interventions we offer, we also aim to provide access to a rich resource of information and materials for your personal reference and self-directed learning."])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPOTLIGHT SERVIES"])},
    "specialPublications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPECIAL PUBLICATIONS AND REPORTS"])},
    "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARTICLES"])}
  },
  "Profile": {
    "editAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Account"])},
    "editAccountADDpOSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a position"])},
    "favoritesEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites Events"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "myCoursesNOtFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Course Found"])},
    "myEventNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Events Found"])},
    "mySessionNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Sessions Found"])},
    "myPurchaseNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Purchase Found"])},
    "goCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Course "])},
    "Purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchases"])},
    "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activities"])},
    "CourseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Name:"])},
    "savedPosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Posts"])},
    "emptyLikedPosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't like any post yet."])},
    "emptysavePost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't save any post yet."])},
    "Piinedpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin pinned this post"])},
    "likedPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Liked This Post"])},
    "savedPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Saved This Post"])},
    "Favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "allCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Courses"])},
    "viewCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Course As Student"])},
    "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Bank"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Bank"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
    "lmsInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
    "Coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach"])},
    "noEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no event yet."])}
  },
  "Event": {
    "programOutline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Outline"])},
    "targetAudience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Audience"])},
    "similarEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar Events"])},
    "ArabicEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "EnglishEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "f2fEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onsite event"])},
    "onlineEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Event"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list of the participants"])},
    "shareEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share This Event"])},
    "Hosted-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead Instructor"])},
    "Enrollment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollment"])},
    "EventOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Overview"])},
    "AboutEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About this Program"])},
    "AboutEventError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About this Event should not be empty"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "EventFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Event Fee"])},
    "ENROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENROLL"])},
    "ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENROLLED"])},
    "addfav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD TO FAVORITES"])},
    "removefav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REMOVE FROM FAVORITES"])},
    "Guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructors"])},
    "addHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add Host"])},
    "titleHostPo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Host"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Instructors"])},
    "addhost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" + Add Host"])},
    "removehost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added As Host"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "addGuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add Guest "])},
    "titleGuestPo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Guest"])},
    "titleEditGuestPo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Guest"])},
    "resetfi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Reset Fields"])},
    "Guestname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest Name"])},
    "guestEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest Email Address"])},
    "guestOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest Organization"])},
    "guestOcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest Occupation"])},
    "guestPic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest Picture"])},
    "guestAbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Guest"])},
    "Guestnamepl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter guest name "])},
    "guestEmailpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter guest email "])},
    "guestOrgpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter guest Organization"])},
    "guestOcupl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Enter guest Occupation"])},
    "guestAbopl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Enter information about guest"])},
    "otherEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Events By Your Group"])},
    "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" View More"])},
    "joinGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Group"])},
    "AddEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Event"])},
    "EventDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Details"])},
    "eventType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Type"])},
    "EventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Name"])},
    "oldFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Old Fee"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Fee"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Language"])},
    "MeetingUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting Url"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "eventDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Date & Time"])},
    "eventDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid event Date & Time"])},
    "EventTitleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the event name"])},
    "enterFeemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter fee more than or equal 0"])},
    "EventFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event From"])},
    "EventTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event To"])},
    "aboutEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About This Event"])},
    "coverImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Cover Event Images"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Event Files"])},
    "SaveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Draft"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose One.."])},
    "eventNamePl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Write event Name"])},
    "eventlocationPl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Event Location "])},
    "eventaboutPl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write your event’s description "])}
  },
  "landingPage": {
    "EduTrackTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR EDUCATIONAL SERVICES"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Healthcare Leadership Academy (HLA)"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" was established to develop healthcare leaders at all professional levels providing them with world-class learning experiences that equip them to transform health systems."])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" RE-IMAGINING HEALTHCARE AND EDUCATION"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision"])},
    "visionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be the global choice for healthcare leadership development"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
    "missionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unleashing individual and collective leadership potential to achieve better health and caree"])},
    "CEOdr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sami Yousif, MBBS, MSc."])},
    "CEOSubtitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO, Health Leadership Academy"])},
    "CEOQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership is a key factor in organizational success and continued growth, and leaders who are capable of inspiring change and achieving goals are always in high demand. This is especially true in complex industries like healthcare that exalt clinical and technical expertise above everything else, an approach that may have contributed to many of the flaws and shortcomings of healthcare systems around the world, including escalating healthcare costs, reduced clinical efficiency, and growing health disparities, to name a few.  \n \n Healthcare reform is one of the main programs in the Saudi Vision 2030. The Saudi Commission for Health Specialties (SCFHS), as one of the major stakeholders and contributors to the healthcare transformation program, was tasked with the creation of the Healthcare Leadership Academy (HLA).\n \nSince its establishment in late 2017, the HLA has collaborated with top business schools and international consultants around the world to design and deliver bespoke leadership journeys and eLearning programs with the highest standards.\n \nWe are always keen to develop new partnerships and new ideas that can advance leadership development, achieve the desired impact on healthcare services, and make the healthcare transformation program a resounding success. I am delighted to invite you to explore our profile and contact us to identify the best solutions and services we offer for your needs."])},
    "MulhemParallaxTi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO DEVELOP EMERGING HEALTH LEADERS"])},
    "MulhemParallaxparg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership training programs, designed locally with international scale; Focuses on cases and challenges in the Saudi healthcare system. For this reason, each program is unique; It combines local and international experiences to produce programs that help develope leadership capabilities based on evidence. We target all levels of leadership; Emerging leaders, Middle management, and Executive leaders."])},
    "HORIZONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership Development Training Programs"])},
    "HORIZONSPa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our development programs incorporate modern philosophies, models and approaches that reflect the universal challenges facing health systems around the world.  We offer: "])},
    "HORIZONSPa1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programs aimed at all levels we equip healthcare leaders to develop a systems view of leadership anchored in a robust, evidence based approach."])},
    "HORIZONSPa2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In person or on-line, programs that are highly interactive with energizing content designed to help participants implement new learning with immediate impact."])},
    "LEADERSHIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executive Coaching"])},
    "LEADERSHIPPa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The HLA assessment service provides:"])},
    "LEADERSHIPCi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Leadership Assessment"])},
    "LEADERSHIPCiPa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The HLA assessment service provides:"])},
    "LEADERSHIPCi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to a broad range of tools including 360 assessment designed to identify skills, abilities and competencies "])},
    "LEADERSHIPCi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can be used by individuals and organizations to profile strengths and development areas "])},
    "LEADERSHIPCi3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help in assessing suitability for higher office and eligibility for further development "])},
    "LEADERSHIPPa1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to a broad range of tools including 360 assessment designed to identify skills, abilities and competencies "])},
    "LEADERSHIPPa2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can be used by individuals and organizations to profile strengths and development areas "])},
    "LEADERSHIPPa3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help in assessing suitability for higher office and eligibility for further development "])},
    "FUTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA Library"])},
    "FUTUREPa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rich world of knowledge, where future leaders can have access to information and educational resources which enable them finding their inspirations and reaching their ambitions. We hold into our vision in providing knowledge and best practice in the field and share them with the globe."])},
    "eduTackTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Healthcare Leadership Academy?"])},
    "eduTrackD1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first Healthcare Leadership Academy in the region"])},
    "eduTrackD2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conduct activities all over Saudi"])},
    "eduTrackD3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborations with renowned leadership institutions"])},
    "eduTrackD4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programs cover all leadership levels"])},
    "eduTrackD5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programs use different learning methodologies"])},
    "eduTrackD6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision 2030 healthcare transformation focused"])},
    "eduTrackD7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilingual Programs"])},
    "eduTrackD8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local content"])},
    "eduTrackD9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programs customization capabilities"])},
    "helthcareValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" HLA Core Values"])},
    "coreValueNa1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration"])},
    "coreValueNa2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovation"])},
    "coreValueNa3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivation"])},
    "coreValueNa4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact"])},
    "coreValueTe1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We communicate and work effectively internally, with our partners, and across the healthcare system"])},
    "coreValueTe2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nurturing a culture that  promotes creativity and innovative solutions that impact internally and externally and across the system    "])},
    "coreValueTe3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We aim to be  the inspiration for individuals, teams and providers to become better leaders    "])},
    "coreValueTe4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We strive to produce measurable leadership  advancements through continual improvement of products and services    "])}
  },
  "mulhemPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulhem Program"])},
    "subtitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Develope Emerging Health Leaders"])},
    "aboutmulhem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An integrated national program offered by Academic Affairs and the Healthcare Leadership Academy (HLA) at the Saudi Commission for  Health Specialities (SCFHS) for emerging health leaders. The program offers essential healthcare leadership competences and skills development which is required to lead multidisciplinary healthcare teams with confidence and efficiency."])},
    "mulhemTrack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulhem Tracks"])},
    "welcomeMulhem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Mulhem"])},
    "WelcomeMulhemPa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Our Goal is to provide quality leadership education and development to postgraduate trainees from all health specialties with the aim of unleashing their leadership potential to develop accountable and effective healthcare leaders"])},
    "WelcomeTruckT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulhem Tracks"])},
    "WelcomeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our educational tracks"])},
    "WelcomeTruckP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mulhem program is an educational journey that takes you through different tracks depending on your individualized leadership  development needs. Mulhem gives you the opportunity to create your personalized journey through applying to one or more of it's tracks"])},
    "whyMulhem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why HLA?"])},
    "whyMulhemP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Believing in the abilities of emerging leaders within all health  specialities. Mulhem is designed to enable and inspire them to learn and grow continuously through it's educational journey which have:"])},
    "whyMulhemT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthcare Focused"])},
    "whyMulhemP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Specialized tracks in healthcare leadership focused on the competencies and skills found in the HLA leadership model"])},
    "whyMulhemT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renowned International faculty"])},
    "whyMulhemP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality leadership programs that leverage trainees unique leadership capabilities"])},
    "whyMulhemT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidence-Based Leadership Development Programs"])},
    "whyMulhemP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Specialized tracks in healthcare leadership focused on the competencies and skills found in the HLA leadership model"])},
    "whyMulhemT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Customization Capabilities"])},
    "whyMulhemP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Specialized tracks in healthcare leadership focused on the competencies and skills found in the HLA leadership model"])},
    "faqTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
    "faqQ1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" I am facing a techincal issue during submitting my application. Who should I contact?"])},
    "faqQ2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  What is the time commitment for the Horizons program?"])},
    "faqQ3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Horizons an online program?"])},
    "faqQ4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How is the selection process conducted for Horizons?     "])},
    "faqQ5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Is Mulhem program only for Saudi nationals?"])},
    "faqQ6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Mulhem a paid program? "])},
    "faqQ7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Are there any CME hours presented within Mulhem Program tracks? "])},
    "faqQ8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many CME hours are presented for each track? "])},
    "faqA2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The program requires 40 HRs distributed through 16 weeks. Once accepted to the Program, we will contact your residency program director to support your education and facilitate your attendance."])},
    "faqA3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons is a hybrid program that include both in-person modules based in Riyadh and virtual sessions."])},
    "faqA4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Participants go through a rigorous selection process and are chosen through a third party of SMEs . This process includes:    "])},
    "faqA41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Submission"])},
    "faqA42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Shortlisting"])},
    "faqA43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview Stage"])},
    "faqA44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Committee Stage"])},
    "faqA45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Announcements"])},
    "faqA5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The program is for residents and fellows enrolled in SCFHS programs from all health specialities in the GCC region    "])},
    "faqA6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  The program is fully sponsored by SCFHS."])},
    "faqA62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Please note that the sponsorship does not cover the cost of travel and accommodation for in person activities/ modules."])},
    "faqA7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" CME Hours are given for all Mulhem program tracks"])},
    "faqA81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future health leaders’ forum"])},
    "faqA812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 CME"])},
    "faqA82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residents leadership webinars"])},
    "faqA822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 CME/ Webinar"])},
    "faqA83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief residents’ leadership circles"])},
    "faqA832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CME/ Circle"])},
    "faqA84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons program"])},
    "faqA842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 CME"])}
  },
  "HorizonxProgram": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership Development Training Programs"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develope Emerging Health Leaders"])},
    "hozText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our development programs incorporate modern philosophies, models and approaches that reflect the universal challenges facing health systems around the world. We offer:Programs aimed at all levels we equip healthcare leaders to develop a systems view of leadership anchored in a robust, evidence based approach and In person or on-line, programs that are highly interactive with energizing content designed to help participants implement new learning with immediate impact."])},
    "horizans1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-training programs"])},
    "horizans2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-person training programs"])},
    "horizans3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webinars - Events"])},
    "horizans4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons Programs"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Closed"])},
    "completeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you complete the program"])},
    "completeSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will find yourself able to:"])},
    "complete1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Explain the competencies, skills, and behaviors needed to lead to individuals who contribute to healthcare"])},
    "complete2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identify strengths and weaknesses"])},
    "complete3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Comparing different leadership styles and roles used for healthyleadership"])},
    "complete4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practicing effective communication skills and methods to increase leadership influence."])},
    "complete5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application of systems thinking approaches in health care."])},
    "complete6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Apply the basics of healthcare quality and patient safety principles."])},
    "complete7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translating knowledge to increase responsibility and improve patient-centered care."])},
    "complete8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishing a strategy to maintain the practitioner's well-being and ontinuous growth"])},
    "horizons2020T": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons 2022"])},
    "horizansDQ1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length:"])},
    "horizansDQ2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Dates:"])},
    "horizansDQ3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost:"])},
    "horizansDQ4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMEs:"])},
    "horizansDQ5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Deadline:"])},
    "horizansDQ6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview Date:"])},
    "horizansDA1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16 Weeks"])},
    "horizansDA2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Jan - 13 Apr 2022"])},
    "horizansDA3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free - sponsored by SCFHS* Traveling expenses are not included"])},
    "horizansDA4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 Hours"])},
    "horizansDA5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25 Nov 2021"])},
    "horizansDA6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 and 9 Dec 2021"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Program Brouchure"])}
  },
  "Webinars": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webinars"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership Webinars"])},
    "WbinarsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Monthly lectures, presented virtually, focusing on the leadership competencies for individuals participating in the health sector"])}
  },
  "LeaderShip": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Leadership Circles"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Resident Leadership Circles   "])},
    "LeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Bimonthly facilitated discussion circles offered for chief residents"])}
  },
  "createGroup": {
    "all_data_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All data required"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Group"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Image"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Name"])},
    "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter group name"])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Privacy"])},
    "selectPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select Group Privacy"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Description"])},
    "writeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write group description"])},
    "coverImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Cover Image"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
  },
  "newEventPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Events"])},
    "addEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Event"])},
    "pastEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Event"])},
    "eventname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Name"])},
    "eventtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Type"])},
    "eventdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Date & Time"])},
    "enrollmentEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollments"])}
  },
  "contactus": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "nameplac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your name"])},
    "cusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Type"])},
    "cusTypeplac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Type"])},
    "reqType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Type"])},
    "nreqTypeplac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Type"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailplac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email here"])},
    "Inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiry"])},
    "Inquiryplac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your inquiry here"])},
    "nameValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Your Name"])},
    "emailValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Valid Email"])},
    "requestValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select Request Type"])},
    "customerValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select Customer Type"])},
    "MessageValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Your Inquiry"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
    "servicerequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Request"])},
    "requestforinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for Info"])},
    "nameValidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Guest Name"])},
    "organizationValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Guest Organization"])},
    "occupationValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Guest Occupation "])},
    "briefValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter About Guest "])}
  },
  "lmcLogin": {
    "MyExams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Exams"])},
    "myQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Question"])},
    "createQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Question"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "MyDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Dashboard"])},
    "MyCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Courses"])},
    "PerformanceSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance Summary"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "forgotPassDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email here and we will send you an email to reset your password"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
    "varificationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you didn’t receive a email, please check your spam folder, if you still can’t find it, please click on the resend button to receive a new one"])},
    "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Email"])},
    "createCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Course"])},
    "createExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an Exam"])},
    "createNewExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Exam"])},
    "myCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Courses"])}
  },
  "lms": {
    "imageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image must be less than 99MB"])},
    "videoError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video must be less than 99MB"])},
    "syllabusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syllabus must be less than 99MB"])},
    "selectLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Lecture"])},
    "normalLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Lecture"])},
    "videoConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Conference"])},
    "onCampus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Campus"])},
    "LectureName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Name"])},
    "editAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Assignment"])},
    "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New"])},
    "courseContentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Content"])},
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By"])},
    "onlineHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you can share a lecture link like (zoom, skype, vimeo , etc.)"])},
    "lectureName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Name"])},
    "assignmentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "selectAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Answer"])},
    "assignmentGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "assignmentDueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
    "editFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit File"])},
    "cancelEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "startQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Exam"])},
    "nextQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next "])},
    "prevQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous "])},
    "SubmitAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Answer"])},
    "calendlycon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected with Calendly"])},
    "OrganizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Name"])},
    "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment"])},
    "lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture"])},
    "assignmentBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "CourseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Name"])},
    "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])},
    "assignmentFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and Drop File or attach it"])},
    "createAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Assignment"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Announcements"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enroll"])},
    "viewCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Course"])},
    "invaldQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Question"])},
    "SelectOneOfexamTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select one of the exam topics"])},
    "pleaseAddExamTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add exam topics"])},
    "pleaseAddExamQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add exam questions"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "questionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Title"])},
    "isCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is The Correct Answer"])},
    "editOrDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit/Delete Answer"])},
    "InvalidAnswerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Answer Text"])},
    "InvalidAnswerGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Answer Grade"])},
    "answerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Text"])},
    "optionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option Text"])},
    "addAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Answers"])},
    "addOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Option"])},
    "addTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Topic"])},
    "chooseGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Grade:"])},
    "chooseQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Question "])},
    "invaldGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Grade"])},
    "questionTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Topic "])},
    "invalidTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Topic"])},
    "SelectQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select exist exam"])},
    "questionDirection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question Direction"])},
    "Direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
    "answerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Type"])},
    "MULTICHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple Choice"])},
    "CHECKBOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkbox"])},
    "BOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True or False"])},
    "MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["matching"])},
    "ORDERING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting"])},
    "FILLBLANK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the Blank"])},
    "FREETEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Text"])},
    "oneDirectional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneDirectional"])},
    "twoDirectional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["twoDirectional"])},
    "questionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "createQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Question"])},
    "announcementsTitleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter title"])},
    "announcementsContentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter content"])},
    "announcementSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement sent successfully"])},
    "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "haveComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have completed "])},
    "thisCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of this course"])},
    "noattachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no attachments yet"])},
    "Courseprerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course prerequisites"])},
    "startCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Course"])},
    "AllCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Courses"])},
    "Resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume"])},
    "courseCert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give a Course Certificate"])},
    "createCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Course"])},
    "courseType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Type"])},
    "limitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitations on Enrollment "])},
    "StartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
    "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End  Time"])},
    "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Number of Students"])},
    "courseCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course City"])},
    "Self-paced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-Paced"])},
    "Live-Online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live-Online"])},
    "Exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam"])},
    "onlineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Online"])},
    "onsiteType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Onsite"])},
    "On-Site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Site "])},
    "hybrid-site:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid "])},
    "letsCont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Continue Learning "])},
    "topin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Courses in"])},
    "deleteSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure ?"])},
    "deleteDis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to delete this Section Are you sure you want to delete it?"])},
    "UNpSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to unpublish this course?"])},
    "UnpDis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current students will still have access to this course "])},
    "UnpDis2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" You can republish it anytime"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "SAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAR"])},
    "clock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "Enrollments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollments"])},
    "ViewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "SortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort By"])},
    "MostPopular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Popular"])},
    "Newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newest"])},
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1: Course details"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2: Course Content"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3: Course Publishing"])},
    "courseIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Introduction"])},
    "courseOnbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course onboarding"])},
    "courseCur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Curriculum"])},
    "coursepub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course publishing"])},
    "courseDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Details"])},
    "coursePic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Picture"])},
    "Mylearning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Courses"])},
    "coursename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Name"])},
    "coursenamereq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Name is required"])},
    "coursecityreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course City is required"])},
    "courseTypeReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Type is required"])},
    "courseLevelReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course level is required"])},
    "courseStartTimeReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date is invalid"])},
    "courseENdTimeReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date must be After Start date"])},
    "addWish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add To Wishlist"])},
    "removeWish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from Wishlist"])},
    "aboutCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About this course "])},
    "whatLearn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What will you learn?"])},
    "CourseSyllabus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Syllabus"])},
    "MOREDETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" MORE DETAILS"])},
    "LESSDETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LESS DETAILS"])},
    "syllabusandco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syllabus & Course overview"])},
    "coursecate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Category"])},
    "selectcat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Course Category"])},
    "coursecatreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Category is required"])},
    "writehere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Here"])},
    "Addananswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "courselan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Language"])},
    "selectlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Course Language"])},
    "courselanreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Language is required"])},
    "QuestionNameReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question title is required"])},
    "questionTopicReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question topic is required"])},
    "AnswerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Type is required"])},
    "QuestionDesReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes is required"])},
    "Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "coursedisC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Write Description about this course"])},
    "Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "coursedis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a brief description of what this course is about"])},
    "coursedisreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description is required"])},
    "courseout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are the learning outcomes of this course"])},
    "courseOutRe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learning outcomes is required"])},
    "coursepreRe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course prerequisites is required"])},
    "coursetargRe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["target students is required"])},
    "coursePre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are there any course prerequisites"])},
    "coursetarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who are your target students"])},
    "coursehoue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Hours"])},
    "Beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner"])},
    "Intermediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermediate"])},
    "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
    "coursele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is this course’s level"])},
    "coursePrev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Attach a course preview"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
    "courseTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  What does your course mainly teach"])},
    "SelectTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Select One or More Topic"])},
    "courseoldfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "feeoldreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course price after discount must be less than course price"])},
    "newfeeReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course price is required"])},
    "invalidHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course hours must be a number more than 1"])},
    "ivalidLimitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitations on Enrollment must be zero or greater than zero"])},
    "coursenewfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price after discount"])},
    "gotboa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Go To Course On boarding"])},
    "attahP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and Drop picture or attach it"])},
    "attahV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and Drop video or attach it"])},
    "attahF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop the file or attach it"])},
    "attahR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop the resource files or attach it"])},
    "welcomeSt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Your New Students"])},
    "courseW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Welcome Message"])},
    "courseMR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Message is required"])},
    "courseSyl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Syllabus"])},
    "courseSylR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Syllabus is required"])},
    "fILElECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Lecture is required"])},
    "gotostep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Step 2"])},
    "courseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Course Content"])},
    "Section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
    "SectionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module Name"])},
    "SectionNameR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module Name is required"])},
    "SubSectionNameR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Name is required"])},
    "SubSectionNameL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Link must be true"])},
    "SubSectionLOcationR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Location is required"])},
    "sectionAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" About this Module"])},
    "sectionAboutR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module Description is required"])},
    "AddSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Module"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lecture Description"])},
    "ImportSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Module"])},
    "gotostep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Step 3"])},
    "Add Lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Lecture"])},
    "LectureURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture URL"])},
    "Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
    "Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reading"])},
    "Audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
    "linkA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Media File"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Lecture By URL Link"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Course Event"])},
    "NewLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lecture Name"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "Published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
    "Unpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublish"])},
    "UnpublishedD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublish"])},
    "Resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
    "ResourcesFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources Files"])},
    "AddLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish This Course"])},
    "publishbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish this course"])},
    "charExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Enrollments In Course Per Month / Course Popularity"])},
    "coursePo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Popularity"])},
    "generalsta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  General Course Statistics"])},
    "courseCom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Completion"])},
    "totalEnr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Number of Enrollments"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistic"])},
    "CourseOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Overview "])},
    "DownloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download File"])},
    "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Welcome,"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "allDesction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Discussion Board"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of post"])},
    "repliesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of replies"])},
    "allSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Section"])},
    "PleaseChoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Choose"])},
    "CourseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Content"])},
    "desctionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion Board"])},
    "isCountedTowardsFinalGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counted Towards Final Grade"])},
    "notPublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The course not publish"])},
    "noEnroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no enrollments yet"])},
    "noSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no discussion board enable."])},
    "studentsAttendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Attendance"])}
  },
  "footer": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms Of Use"])},
    "Feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" LEARN MORE"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us "])},
  "contactUst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your transformation starts with a conversation"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUBSCRIBE"])},
  "allright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved"])},
  "subscribeFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUBSCRIBE TO OUR NEWSLETTER"])},
  "followhla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow HLA"])},
  "coach": {
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIEW MORE"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Coaches"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "SessionsStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions to get you started"])},
    "Leadership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership Coaching"])},
    "resultsData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there is No Results"])},
    "meetYourInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructors"])}
  },
  "filter": {
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arabic"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Filter"])},
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "pricehtl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price: High to low"])},
    "pricelth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price: Low to high"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["level"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaches"])}
  },
  "CoachDetail": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must Select Both Session"])},
    "issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["if you’re facing any issues, click here.."])},
    "sessionBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Booked"])},
    "sessionfaled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any more free Chemistry Lessons available. Please book a coaching session"])},
    "something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oops something went wrong,please try again later"])},
    "chemistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chemistry session"])},
    "Book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book session"])},
    "Know_your_Coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know your Coach"])},
    "Certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates,Licenses and Assessment tools"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View linkedin Account"])},
    "Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
    "All Coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Coach"])},
    "Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
    "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You recieved an email with the session details."])},
    "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the following link for session details:"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "Attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "introductoryVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introductory Video"])}
  },
  "CoachingPanel": {
    "Add_Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Experience"])},
    "login-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN"])},
    "signup-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGN UP"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for applying!"])},
    "Okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Occurred!"])},
    "maximumW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum number of words  500 words"])},
    "LoginPage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
      "login-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])}
    },
    "SignupPage": {
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Get You Started!"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Information"])},
      "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Picture"])},
      "profDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Details"])},
      "mostjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most recent job title"])},
      "mostOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most recent organization"])},
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "Specialities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialities"])},
      "Institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institute"])}
    },
    "header": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page"])},
      "coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["switch to coach panel"])}
    },
    "sideNav": {
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
    },
    "settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Settings"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
      "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts"])}
    },
    "calendar": {
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up your Availability"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let us know when you’re available to coach sessions by signing in with Calendly and setting up your profile!"])},
      "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with Calendly"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your session"])},
      "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please define two event types as follows:"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to create a new event type in calendly:"])},
      "solo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event type must be on. Event must be solo."])},
      "onetoone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One to One Session Event"])},
      "chooseone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindly note that the sessions duration for this event is one hour (60 minutes)"])},
      "yourChemistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chemistry Session Event"])},
      "chooseChemistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Kindly note that the sessions duration for this event is half an hour (30 minutes)"])},
      "chemistrySession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chemistry Session:"])},
      "selectEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Event"])},
      "yourEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Session Event"])},
      "chooseEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose your session from the list below"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event:"])},
      "selectSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Session"])},
      "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])}
    },
    "dashboard": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start coaching on Himam"])},
      "stepOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step One"])},
      "stepTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step Two"])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your profile"])},
      "set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Set you Availability with Calendly"])},
      "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with calendly to set all your available time slots"])},
      "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Calendar"])},
      "goP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Profile"])},
      "notreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not registered in any course"])},
      "ifwont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to register"])},
      "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Courses"])},
      "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming on Calendar"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session details"])},
      "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
      "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total bookings this "])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
      "taught": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total taught sessions"])},
      "chemistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chemistry Sessions"])},
      "chemistryBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booked"])},
      "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked after chemistry "])},
      "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session"])},
      "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])}
    },
    "nda": {
      "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello Coach"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Contract is valid untill "])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note you will find a copy of this contract in settings"])},
      "agreementNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read and accept the contract details"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Have read and accepted the contract details"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the box before continue"])}
    },
    "profile": {
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "validData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid data"])},
      "validPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a price more than 100 "])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View linkedin Account"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Me"])},
      "addAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add about me"])},
      "describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe who you are as a professional. Talk about relevant skills and accomplishments"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "addLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Language"])},
      "editLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Language"])},
      "editVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Video"])},
      "editcv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit CV"])},
      "cancelEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Edit "])},
      "editAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Account"])},
      "monthes": {
        "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
        "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
        "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
        "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
        "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
        "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
        "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
        "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
        "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
        "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
        "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
        "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
      },
      "availible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My coaching sessions are available in this language"])},
      "proficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proficiency"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video introduction of yourself"])},
      "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about yourself"])},
      "videioPre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Attach a Video preview"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "addEducation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Education"])},
      "editEducation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Education"])},
      "EditExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Experience"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
      "Degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment tools, certificates and licenses"])},
      "addTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License or Certification"])},
      "editTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit License or Certification"])},
      "issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued at"])},
      "credType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credential Type"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "issOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuing organization"])},
      "notExp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This credential does not expire"])},
      "issuedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued Date"])},
      "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiraton Date"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credential ID"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credential URL"])},
      "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work experience"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "employementType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment Type"])},
      "orgName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization Name"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "currentlyWorking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am currently working in this role"])},
      "strtDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
      "edDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
      "jobRes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Responsibilities (Optional)"])},
      "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
      "postions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions you have worked with"])},
      "enterBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your bio"])},
      "language": {
        "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
        "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
        "begginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner"])},
        "intermediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermediate"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
        "nativeorfluent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native Or Fluent"])}
      },
      "degree": {
        "masters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masters"])},
        "bachelors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelors"])},
        "associate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate"])},
        "doctoral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctoral"])}
      },
      "types": {
        "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License"])},
        "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
        "assessmenttool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Tool"])}
      },
      "emp": {
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full-Time"])},
        "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part-Time"])},
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-Employed"])},
        "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])}
      }
    }
  },
  "lmsdahbord": {
    "Upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming on Calendar"])},
    "learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Courses"])},
    "JoinNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Now"])},
    "MoreDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Details"])},
    "Certified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified Courses"])}
  },
  "UpcomingEventGroup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Our Program Of Events For Better Learning Experience."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attending events will give you many benefits. You can build meaningful connections, learn from others, gain a better understanding of different subjects, and much more."])},
    "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All Groups"])},
    "viewAllEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All Events"])},
    "Upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Events"])},
    "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Groups"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no Upcoming Events"])},
    "suggestedEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Events"])}
  },
  "nopurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Didn't Purchase Anything"])},
  "ReadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
  "OurGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Groups"])},
  "OurEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Events"])},
  "validEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Valid Email"])},
  "subscribeBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "alreadySub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is already subscribed"])},
  "subscribeThan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks For Your Subscription"])},
  "followus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Us"])},
  "teerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms Of Use"])},
  "howto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Guide"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "chatSupport": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Support"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello there!"])},
    "help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I help you?"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’re Online!"])},
    "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How may I help you today?"])}
  },
  "landingPageSlider": {
    "we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HEALTHCARE LEADERS"])},
    "empower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMPOWERING"])},
    "motivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOTIVATING"])},
    "develop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEVELOPING"])}
  },
  "landingPageExcerpt": {
    "hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Healthcare Leadership Academy (HLA)"])},
    "excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Health Leadership Academy was established by the Saudi Commission for Health Specialties which started its activities in 2017 with a focus on developing leaders at all levels within the healthcare system and providing them with world-class educational experiences to support the transformation of the healthcare system"])}
  },
  "landingPageTracks": {
    "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROFESSIONAL LEADERSHIP ASSESSMENT"])},
    "leadership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEADERSHIP DEVELOPMENT PROGRAMS"])},
    "executive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXECUTIVE COACHING"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORGANIZATIONAL SOLUTIONS"])}
  },
  "aboutUs": {
    "main": {
      "accreddited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Healthcare Leadership Academy is Accreddited by"])}
    },
    "impact-vision": {
      "hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Healthcare Leadership Academy (HLA)"])},
      "excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received the Gold Award for Impact in Training from the International Association of Facilitators (IAF) for the (ENABLE) facilitation program for Healthcare Leaders."])},
      "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VISION"])},
      "vision-excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be the global choice for healthcare leadership development"])},
      "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MISSION"])},
      "mission-excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unleashing individual and collective leadership potential to achieve better health and care"])}
    },
    "values": {
      "our-values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR VALUES"])}
    },
    "why-us": {
      "why-hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHY HLA .. ?"])},
      "health-focused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthcare focused"])},
      "renowned-international-faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renowned international faculty"])},
      "evidence-based-leadership-development-programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidence-based leadership development programs"])},
      "Program customizable capabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program customizable capabilities"])}
    },
    "leadershipModel": {
      "leadership-model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR LEADERSHIP MODEL"])},
      "excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The HLA has designed the Healthcare Leadership model in collaboration with top local and international experts. This model is a guide to the specific skills, behaviors and leadership competencies expected for a healthcare leader in the health ecosystem."])}
    },
    "statistics": {
      "Programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programs"])},
      "Training-Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Hours"])},
      "Beneficiaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiaries"])}
    }
  },
  "Agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "addBlank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Blank"])},
  "howtoUse": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HLA Platform Guide"])},
    "coachPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use the coach panel"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to sign up on himam"])},
    "speakWithCustomerAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to speak with customer agent"])},
    "AddCourseAsInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to add course as instructor"])},
    "addCourseAsInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to add course as instructor"])},
    "bookASessionWithACoach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to book a session with a coach"])},
    "createAGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to create a group"])},
    "createAnEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to create an event"])},
    "createNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to create new user"])},
    "filterCourseToFindYourCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to filter course to find your course"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to forget password"])},
    "joinAGroupAsAStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to join a group as a student"])},
    "participateInDiscussionForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to participate in discussion form"])},
    "postSomethingToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to post something to group"])},
    "purchaseTicketsForAnEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to purchase tickets for an event"])},
    "registerInNewCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to register in new course"])},
    "submitAnAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to submit an assignment"])},
    "loginToHimam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to login to himam"])},
    "createCourseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to create course content"])},
    "createRubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to create rubric"])}
  },
  "survey": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire to assess the level of satisfaction of the beneficiaries of HLA e-learning"])}
  },
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
  "saPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAR"])},
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your feedback"])}
  },
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "backInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to my courses"])},
  "backQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to my Questions"])},
  "backQuizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to my Exams"])},
  "englishVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download English Version"])},
  "Enrollments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollments"])},
  "savedPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Post"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Now"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account?"])},
  "haveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have Account?"])},
  "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click Here"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "noSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no post save yet."])},
  "noFavoraites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no favorites course and event yet."])},
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "lms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Learning"])},
    "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DASHBOARD"])},
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIBRARY"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "Favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
    "notif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTIFICATION"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "markAllAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all as Read"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
    "SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICES"])},
    "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
    "eLearning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "switchInst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor View"])},
    "switchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User View"])},
    "switchCoach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach View"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "searchNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Result Round"])},
    "allGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])}
  },
  "mulhem": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulhem program"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To develop emerging health leaders"])},
    "bannerData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An integrated national program offered by Academic Affairs and the Healthcare Leadership Academy (HLA) at the Saudi Commission for Health Specialties (SCFHS). The program offers essential healthcare leadership competences and skills development which is required to lead multidisciplinary healthcare teams with confidence and efficiency.\n"])},
    "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulhem Tracks"])},
    "videoLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video to make you closer"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Mulhem"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Goal is to provide quality leadership education and\ntraining to postgraduate trainees from all health specialties\nwith the aim of unleashing their leadership potential to\ndevelop accountable and effective healthcare leaders"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Start Now"])},
    "tracksHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulhem Tracks"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our educational tracks"])},
    "educationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulhem program is an educational journey that takes you through different tracks depending on your individualized leadership development needs. Mulhem gives you the opportunity to create your personalized journey through applying to one or more of it’s tracks"])},
    "leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Health Leaders’ Forum Track"])},
    "registerNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now"])},
    "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Mulhem?"])},
    "whyMulhem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Believing in the abilities of the Saudi emerging leaders within all health specialities \n, Mulhem is designed to enable and inspire them to learn and grow continuously through it's educational journey which have:"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership Community"])},
    "communityDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An opportunity to join inspiring health leaders and participate in the leadership circles and webinars and more by joining the health leadership platform community."])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High Quality"])},
    "qualityDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality leadership programs that leverage trainees unique leadership capabilities."])},
    "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Healthcare Leadership Tracks"])},
    "healthcareDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialized tracks in healthcare leadership focused on the competencies and skills found in the HLA leadership Model."])},
    "ourPrinciples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our principles"])},
    "leadershipModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about our leadership model"])},
    "leadershipModelTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership model"])},
    "leadershipDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulhem program is based on the competencies and behaviors within HLA leadership model"])},
    "leadershipModelContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The HLA has designed the Healthcare Leadership model in collaboration with top local and international experts. This model is a guide to the specific skills, behaviors and leadership competencies expected for a healthcare leader in the health ecosystem."])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "freeQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently asked questions"])},
    "freeQuestionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect With Us Now"])},
    "leadershipWebinar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residents Leadership Webinars"])},
    "leadershipLectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership Webinars"])},
    "leadershipLecturesHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly lectures, presented virtually, focusing on the leadership\ncompetencies for individuals praticipating in the health sector"])},
    "leaderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Health Leaders’\nForum Track"])},
    "leaderContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual leadership forum available for all\npostgraduate trainees from all health \nspecialties"])},
    "residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residents Leadership Webinars"])},
    "residentsContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webinar series available for all postgraduate\ntrainees from all health specialties"])},
    "chief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Residents Leadership Circles (CRCs)"])},
    "chiefContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitated discussion circles available for chief residents"])},
    "horizon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons Program"])},
    "horizonContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A leadership development journey for a specific group of postgraduate trainees"])},
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Mulhem program only for Saudi nationals?"])},
    "answer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The program is for residents and fellows \nenrolled in SCFHS programs from all health \nspecialties in the GCC region."])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Mulhem a paid program?"])},
    "answer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The program is fully sponsored by SCFHS."])},
    "answer2Danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that the sponsorship does not cover the cost of travel and accommodation for in person activities modules"])},
    "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are there any CME hours presented within Mulhem Program tracks?"])},
    "answer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CME Hours are given for all Mulhem program tracks."])},
    "question4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many CME hours are presented for each track?"])},
    "answer4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CME hours are given according to time and length of the educational track as following:"])},
    "answer4Future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future health leaders’ forum"])},
    "answer4Residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residents leadership webinars"])},
    "answer4Chief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief residents leadership circles"])},
    "answer4Horizons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief residents leadership circles"])},
    "CME8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 CME"])},
    "CME1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 CME/ Webinar"])},
    "CME2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 CME/ Circle"])},
    "CME40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 CME"])},
    "question5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am facing a technical issue during submitting my application. Who I should I contact?"])},
    "answer5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact"])},
    "question6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["what is the time commitment for Horizons program?"])},
    "answer6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The program requires 40 HRs distributed through 16 weeks. Once accepted to the Program, we will contact your residency program director to support your education and facilitate your attendance."])},
    "question7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Horizons an online program?"])},
    "answer7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons is a hybrid program that \ninclude both in-person modules \nbased in Riyadh and virtual sessions."])},
    "question8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How is selection Process \nconducted for Horizons?"])},
    "answer8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants go through a rigorous \nselection process and are chosen \nthrough a third party of SMEs. \nThis process includes:"])},
    "answer8Submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Submission"])},
    "answer8Scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV Scoring"])},
    "answer8Initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial Shortlisting"])},
    "answer8Final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Shortlisting"])},
    "answer8Interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview Stage"])},
    "answer8Committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Committee Stage"])},
    "answer8Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Announcements"])},
    "horizonProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizon Program"])},
    "healthLeaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizons Program"])},
    "horizonProgramHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A supportive training trip for 50 trainees of the Saudi Commission for Health Specialties training programs."])},
    "applyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Now"])},
    "registerMove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])},
    "horizonBenefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you complete the program"])},
    "horizonSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will find yourself able to"])},
    "horizonBenefit1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain the competencies, skills, and behaviors needed to lead on an individual level"])},
    "horizonBenefit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identify strengths and weaknesses and develop an individual improvement plan"])},
    "horizonBenefit3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparing different leadership styles and roles used for healthy leadership"])},
    "horizonBenefit4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practicing effective communication skills and methods to increase leadership influence"])},
    "horizonBenefit5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application of systems thinking approaches in health care"])},
    "horizonBenefit6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply the basics of healthcare quality and patient safety principles."])},
    "horizonBenefit7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translating knowledge to increase responsibility and improve patient-centered care."])},
    "horizonBenefit8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishing a strategy to Maintain the practitioner's well-Being and continuous growth"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Brochure"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Type"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Size"])},
    "uploadDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Date"])},
    "journey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Journey"])},
    "journeyHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspirational journey offered for group of elected residents from Saudi Commission for Health Specialities Programs"])},
    "lecture1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective Communication"])},
    "lecture1Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By the end of the webinar, participants will be able to:\nUnderstand knowledge and key theoretical approaches of communication.\nDemonstrate increased competence by identifying, explaining and applying effective communication skills in a variety of contexts.\nBuild appropriate communication strategies for personal and professional success."])},
    "lecture1Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12/15/2021"])},
    "lecture1Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 8:00 pm"])},
    "lecture2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Teams"])},
    "lecture2Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By the end of the webinar, participants will be able to:\nUnderstand the theories and best practices of team building in healthcare.\nDiscuss the role of multidisciplinary healthcare teams in delivering safe and reliable care.\nDemonstrate competence of achieving high performance for frontline healthcare leaders."])},
    "lecture2Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01/24/2022"])},
    "lecture2Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 8:00 pm"])},
    "lecture3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Management and Prioritisation  "])},
    "lecture3Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By the end of the webinar, participants will be able to:\nDiscuss the importance of time management for leaders\nRecognize the variety of causes for procrastination and distraction\nAdopt strategies to reduce time wasters and set priorities successfully."])},
    "lecture3Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02/21/2022"])},
    "lecture3Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 8:00 pm"])},
    "lecture4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trust and Leadership Flexibility "])},
    "lecture4Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By the end of the webinar, participants will be able to:\nDefine workplace trust and it’s role in delivering effective patient care\nRecognize behaviours that foster trust and flexibility\nDemonstrate competence in building trust and flexibility for frontline healthcare leaders."])},
    "lecture4Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03/21/2022"])},
    "lecture4Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm  8:00 pm"])},
    "lecture5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared Decision Making"])},
    "lecture5Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By the end of the webinar, participants will be able to:\nUnderstand the key components of shared decision making\nIdentify barriers to shared decision making from the patient’s and provider’s perspective.\nDescribe strategies needed for successful implementation of shared decision making in healthcare."])},
    "lecture5Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07/20/2022"])},
    "lecture5Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm  8:00 pm"])},
    "lecture6Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenging Conversations"])},
    "lecture6Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By the end of the webinar, participants will be able to:\nUnderstand what leadership presence means and how to develop it\nRecognize the impact of leadership presence on patient care\nCreate an action plan to improve professional leadership presence."])},
    "lecture6Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07/18/2022"])},
    "lecture6Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 8:00 pm"])},
    "lecture7Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership Presence"])},
    "lecture7Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By the end of the webinar, participants will be able to:\nUnderstand the factors that can contribute to and create challenging conversations\nRecognize the impact of challenging conversation on everyone taking part in them\nAdopt a range of strategies that can help you manage challenging conversations effectively."])},
    "lecture7Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18/07/2022"])},
    "lecture7Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 8:00 pm"])},
    "lecture8Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emotional Intelligence"])},
    "lecture8Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By the end of the webinar, participants will be able to:\nUnderstand emotional intelligence and the science behand it.\nRecognize the role of emotional intelligence in creating productive professional relationships and team environments\nAdopt emotional intelligence skills and behaviours that can maximize your influence as a leader."])},
    "lecture8Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09/14/2022"])},
    "lecture8Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 8:00 pm"])},
    "ChiefResidentLeadershipCircles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Resident Leadership Circles"])},
    "ChiefContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bimonthly facilitated discussion Circles offered for chief residents."])},
    "lectureChief1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Across the System"])},
    "lectureChief1Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To discuss networking and the benefits of collaboration for individuals and for organizations.\n"])},
    "lectureChief1Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12/25/2021"])},
    "lectureChief1Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 9:00 pm"])},
    "lectureChief2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caring for Others"])},
    "lectureChief2Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To discuss how healthcare leaders can create caring professional environments, improve staff engagement and promote joy at work."])},
    "lectureChief2Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01/24/2022"])},
    "lectureChief2Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 9:00 pm"])},
    "lectureChief3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving for Value"])},
    "lectureChief3Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To discuss value-based leadership and understand how leaders can create alignment between their own personal values and organizational values."])},
    "lectureChief3Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03/13/2022"])},
    "lectureChief3Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 9:00 pm"])},
    "lectureChief4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Putting Patients First                            "])},
    "lectureChief4Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To discuss patient-centric care and its practical applications on different leadership levels in healthcare systems."])},
    "lectureChief4Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05/25/2022"])},
    "lectureChief4Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 9:00 pm"])},
    "lectureChief5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leading the Way"])},
    "lectureChief5Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To discuss role modeling and explore strategies that frontline leaders can adopt to maximize their influence on others."])},
    "lectureChief5Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07/20/2022"])},
    "lectureChief5Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 pm - 9:00 pm"])},
    "flow1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership Assessment"])},
    "flow2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential Workshop"])},
    "flow3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Modules"])},
    "flow4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership projects and tasks"])},
    "flow5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching ALS"])},
    "flow6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing Ceremony"])}
  },
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "profile": {
    "myCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Courses"])},
    "myEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Events"])},
    "myCoachingSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Coaching Sessions"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date: "])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price: "])},
    "lmsInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LMS Instructor"])}
  },
  "arabicVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Arabic Version"])},
  "registerCoach": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Me"])},
    "specializations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specializations"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Price"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
    "proficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proficiency"])},
    "credentialType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credential Type"])},
    "recentOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recent Org"])},
    "coachingAvailableInLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching available in language"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["school"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start Date"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end Date"])},
    "issuingOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["issuing organization"])},
    "doesNotExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does not expire"])},
    "credentialId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credential Id"])},
    "credentialUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credential Url"])},
    "introductoryVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introductory Video"])},
    "Cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV"])},
    "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linkedIn"])},
    "issuedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["issued Date"])},
    "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date"])},
    "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
    "certificateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate Name"])},
    "registerNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Now"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your registration. Now you are a coach in our platform"])},
    "registerAsCoach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as Coach"])}
  },
  "Hybrid:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
  "courseDetails": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About this course"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "announcementMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement Resources"])},
    "whatToLearn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What you'll learn"])},
    "courseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Content"])},
    "announcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])},
    "meetYourInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructors"])},
    "getStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
    "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
    "prerequisite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prerequisite"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "aboutCoach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the coach"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
    "issuedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued at"])},
    "addNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Private Note"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "noNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notes found"])},
    "writeNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Your Note Here ..."])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My notes"])},
    "editNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Note"])},
    "deleteNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Note"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "courseDiscussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Discussion"])},
    "selectDiscussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Discussion"])},
    "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])},
    "allNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Notes"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "courseMyNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Notes"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section"])},
    "lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "entryFeeTaxAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Tax will be added to the price of the course"])},
    "priceAfterTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclusive Price with 15% VAT"])},
    "discussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion board"])},
    "discussionDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discussion board is disabled"])}
  },
  "courseContent": {
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
    "lectureLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture Link"])}
  },
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Show More"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Less"])},
  "group": {
    "Reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "startSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Session"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "Liked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liked"])},
    "validLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter a valid link"])},
    "PastEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Events"])},
    "CreateGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Group"])},
    "selectEditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Profile Picture "])},
    "fnValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Your First Name"])},
    "flValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Your Last Name"])},
    "selectGroupPi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Group Picture .."])},
    "selectGroupco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Group Cover Image..."])},
    "selectOcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["write Your Occupation"])},
    "enterTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["write Your title"])},
    "enterlinkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your  linkedIn link"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linkedin link"])},
    "selectLoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["write Your Location"])},
    "selcetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Enter Information About You"])},
    "leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave Group"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOIN GROUP"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REQUEST PENDING"])},
    "request2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Requests"])},
    "contentgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join group to see content"])},
    "noupcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Upcoming Events."])},
    "nopinned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No posts available."])},
    "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Name"])},
    "sname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "tname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Name"])},
    "Occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "aboutU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About User"])},
    "upoladPic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Profile Picture"])},
    "titleGrou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "RecentActivityErorr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don’t have any recent activity."])},
    "GroupD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not part of any group."])},
    "recentAc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Activity"])},
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
    "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
    "likes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Likes"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replay"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "writeCom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a comment..."])},
    "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "AboutGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" About this group "])},
    "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "Admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admins"])},
    "Members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
    "inviteMem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite Members"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "viewMoreCom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" View more ", _interpolate(_named("num")), " comments"])},
    "createpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Post"])},
    "createpost2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
    "whatMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is in your mind? "])},
    "editpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Post"])},
    "updatepost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Post"])},
    "searchh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "pinned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinned Posts"])},
    "upcomingEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Events"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollments"])},
    "recentMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Media"])},
    "recentpPinned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently Pinned Posts"])},
    "noPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No posts available."])},
    "pinnedmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Admin pinned this post"])},
    "unsave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsave"])},
    "unpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpin"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAR"])},
    "mySession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Sessions"])},
    "myCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Courses"])},
    "myEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Events"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "going": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enroll"])},
    "suggestedCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Courses"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
    "suggestedCoaches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Coaches"])},
    "suggestedEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Events"])},
    "suggestedGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested Groups"])},
    "enrollNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enroll Now"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy:"])},
    "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Group"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
    "myGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Groups"])},
    "posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joined"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "upcomingEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Events"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See All"])},
    "messageLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You left this group"])},
    "messageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Waiting for your acceptance"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])},
    "enrollments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrollments"])}
  },
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "event": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About this event"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosted By"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location and Type"])},
    "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Site"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "entryFeeTaxAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax will be added to the price of the event"])}
  },
  "switchToCoachView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to Coach View"])},
  "organization": {
    "nameOfOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of Organization:"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "targetedLeaderShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targeted leadership development levels"])},
    "emerging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emerging Leaders"])},
    "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle Leaders"])},
    "senior": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senior / Executive Leaders"])},
    "programDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What program duration suites you best?"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short courses (For emerging leaders 3-10 days)\n"])},
    "journeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership development journeys (For Middle Leaders 3-6 months)"])},
    "executive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executive leadership development programs (For Senior / Executive Leaders 9 months)"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programs you are interested in:"])},
    "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional certification programs"])},
    "managerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managerial programs "])},
    "CME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program with CME credits"])},
    "internationalOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programs in collaboration with international organizations"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training needs assessments"])},
    "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional leadership assessments (individual / 360)"])},
    "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executive coaching"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthcare leadership research"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "numberOfTrainees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of expected trainees:"])},
    "suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have more suggestions, please type here"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services you want:"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been sent successfully"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your interest in our program. We will contact you shortly."])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send your request"])},
    "failedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again later."])}
  },
  "emailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address"])},
  "phoneError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid phone number"])},
  "nameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid name"])},
  "moreThanMinusOneError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a number greater than -1"])},
  "requiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
  "courseSyllabus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Syllabus"])},
  "GradeError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter a valid grade that is between 0 and assignment ", _interpolate(_named("grade"))])},
  "assignment": {
    "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Resource"])},
    "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drag file here or click to upload"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "studentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Name"])},
    "assignmentSections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment Sections"])},
    "studentAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Assignment"])},
    "studentAssignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Assignment"])},
    "studentSubmissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Submissions"])},
    "gradeNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade Now"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "feedBackComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "announcementSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement has been successfully send"])}
  },
  "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transcript"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "autoScroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Scroll"])},
  "assessment": {
    "rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
    "noAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assessment available for this student."])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submitted"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updated"])}
  },
  "noQuizzesSubmissionYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No quiz attempts yet."])},
  "noAssignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No assignments yet."])}
}