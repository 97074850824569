import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import {CourseInterface} from "@/interfaces/course.interface";
import {Section, FullSection, Subsection} from "@/interfaces/course-section.interface";

export default {
    namespaced: true,
    state() {
        return {
            course: null as CourseInterface | null,
            courseModules: null as FullSection | Section | null,
            activeSubsection: null as Subsection | null,
            activeSection: null as Section | null,
            courseSubsectionsSorted: [] as number[]
        };
    },
    mutations,
    actions,
    getters,
};
