export default {
  namespaced: true,
  state: {
    supportModal: false,
  },
  getters: {
    supportModal(state:any){

        return state.supportModal;
    }
  },
  mutations: {
    TOGGLE_SUPPORT_MODAL(state: any, status: boolean) {
        state.supportModal = status;
    },
  },
  actions: {
      
    toggleSupportModal({commit}: { commit: any }, status: boolean) {
        commit('TOGGLE_SUPPORT_MODAL', status);
    },

  },
};
