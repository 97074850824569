import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import { Rubric } from "@/interfaces/rubric.interface";
import {
  RubricAssessment,
  RubricAssessmentResponse,
  UploadedMedia,
} from "@/interfaces/assessment.interface";
export default {
  namespaced: true,
  state() {
    return {
      criteria: [] as Rubric["criteria"],
      rubric: [],
      title: "",
      indexColumn: 0,
      indexRow: 0,
      studentAssessment: {} as RubricAssessmentResponse | null,
      studentRubricAssessment: {} as RubricAssessment | null,
      activeFile: null as UploadedMedia | null,
      indexCriteriaId: -1,
      indexGradeId: -1,
      selectedRatings: [],
      totalGradeRubric:[],
      errorInRating:false
    };
  },
  mutations,
  actions,
  getters,
};
