import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
      return {
        quizes:[],
        quiz:null,
        answers:null,
        getExam:null,
        fullGrade:null,
        myGrade:null,
        reviewQuestion:[],
        NoAttempt:null,
    AllQuizesAttempt : null,
    AllQuizesInstructor : null,
    QuizesInstructor : null,
      };
    },
    mutations,
    actions,
    getters,
  };
  