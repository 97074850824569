export default {
    namespaced: true,

    state: {
        howToData: {},
        howToList: [
            {
                id: 1,
                name: 'speakWithCustomerAgent',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/_Speak%20with%20a%20customer%20agent.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 2,
                name: 'addCourseAsInstructor',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Add%20course%20as%20an%20instructor.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 3,
                name: 'bookASessionWithACoach',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Book%20a%20session%20with%20a%20coach.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 4,
                name: 'createAGroup',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Create%20a%20group.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 5,
                name: 'createAnEvent',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Create%20an%20event.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 6,
                name: 'createNewUser',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Creating%20a%20new%20user%20account.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 7,
                name: 'filterCourseToFindYourCourse',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Filter%20courses%20to%20find%20what%20you%20want.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 8,
                name: 'forgetPassword',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Forgot%20Password.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 9,
                name: 'joinAGroupAsAStudent',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Join%20a%20group%20as%20a%20student.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 10,
                name: 'participateInDiscussionForm',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Participate%20in%20a%20course%20discussion%20form.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 11,
                name: 'postSomethingToGroup',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Participate%20in%20a%20course%20discussion%20form.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 12,
                name: 'purchaseTicketsForAnEvent',
                url:"https://himam.fra1.digitaloceanspaces.com/documentation/Purchase%20tickets%20for%20an%20event.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 13,
                name: 'registerInNewCourse',
                url:"https://himam.fra1.digitaloceanspaces.com/documentation/Purchase%20tickets%20for%20an%20event.pdf",
                img: require("@/assets/images/play-button.svg"),
            }
            ,
            {
                id: 14,
                name: 'submitAnAssignment',
                url:"https://himam.fra1.digitaloceanspaces.com/documentation/Submit%20an%20assignment.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 15,
                name: 'loginToHimam',
                url:"https://himam.fra1.digitaloceanspaces.com/documentation/Login%20to%20Himam.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 16,
                name: 'createCourseContent',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Create%20course%20content.pdf",
                img: require("@/assets/images/play-button.svg"),
            },
            {
                id: 17,
                name: 'createRubric',
                url: "https://himam.fra1.digitaloceanspaces.com/documentation/Create%20Rubric.pdf",
                img: require("@/assets/images/play-button.svg"),

            }
        ],
    },

    getters: {
        howToItem(state: any) {
            return state.howToData;
        },
        howToList(state: any) {
            return state.howToList;
        }
    },

    mutations: {
        setHow(state: any, payload: any) {
            state.howToData = payload;
        },
    },

    actions: {
        setHowTo(context: any, id: number): number {
            context.state.howToList.map((item: any) => {
                if (item.id === id) {
                    context.commit("setHow", item);
                    return 200;
                }
            });
            return 404;
        },
    },
};
