import axios from "@/axios";

export default {
    async searchGroup(context: any, searchKeyord: string) {
        const res = await axios.get(`search?search=${searchKeyord}`);
        context.commit('setSearchList', res.data.groups)
    },
    async getUserNotification(context: any, payload: { offset: number, limit: number }) {
        const res = await axios.get("notifications", {
            params: {
                offset: payload.offset,
                limit: payload.limit,
            },
        })
        context.commit('setNotification',res.data)

    },
    clearSearch(context: any): boolean {
        context.commit('clearSearchResult');
        return true;
    },
    menuActive(context: any, active: boolean){
        context.commit('setMenuValue',active)
    }


}
