import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      recentPost: [],
      groupPost: [],
      suggestedCourses: [],
      suggestedCoaches: [],
      suggestedEvents: [],
      suggestedGroups: [],
      userMainData: [],
      group: {},
      allGroups: [],
      admins: [],
      groupMember: [],
      groupRequest: [],
      groupEvents: [],
    };
  },
  mutations,
  actions,
  getters,
};
