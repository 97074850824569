export default {
    setAssignmentModules(state: any, modules: any) {
        state.modules = modules;
    },
    setAssignment(state: any, assignment: any) {
        state.assignment = assignment;
    },
    setAssignmentSubmissions(state: any, submissions: any) {
        state.submissions = submissions;
    },
    setUserSubmission(state: any, submission: any) {
        state.userSubmission= submission;
        console.log('submission', state.userSubmission);
    }

}
