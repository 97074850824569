import axios from "@/axios";
import { Event } from "@/interfaces/event.interface";

type EventResponse = {
  url: string;
  data: Event | null;
};

export default {
  async getEventData(context: any, id: any) {
    try {
      const { data, status } = await axios.get<EventResponse>(`events/${id}`);
      context.commit("setEventData", data);
      return status;
    } catch (e: any) {
      return 500;
    }
  },
  async enrollEvent(context: any, id: number) {
    const res = await axios.post<EventResponse>(
      `users/${context.rootState?.auth?.user?.id}/enrolled-events/${id}`,
      {}
    );
    const url = res?.data?.url;
    if (url) {
      window.location.assign(url);
    } else {
      location.reload();
    }
  },
  async addToFavorites(context: any, id: number) {
    await axios.post(
      `users/${context.rootState?.auth.user?.id}/favorited-events/${id}`,
      {
        favorite: !context.state?.event?.isFavorited,
      }
    );
  },
  async getGuestEvent(context: any, payload: any) {
    const { data } = await axios.post<EventResponse>(
      `events/${payload.id}/guests`,
      payload.formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    await context.dispatch("getEventData", payload.id);
  },
  async removeGuestEvent(context: any, payload: any) {
    await axios.delete<EventResponse>(`events/${payload.id}/guests`, {
      data: {
        email: payload.email,
      },
    });
    await context.dispatch("getEventData", payload.id);
  },
  async editGuestEvent(context: any, payload: any) {
    const res = await axios.patch<EventResponse>(
      `events/${payload.id}/guests`,
      payload.formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    await context.dispatch("getEventData", payload.id);
  },
};
