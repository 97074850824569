
export default {
    namespaced: true,

    state: {
      roomStatus: "OPEN",
      referenceNumber : "",
      roomId : "",
      referenceNumberDetails : [],
      closeRooms : [],
      chatModal : false ,
      messageCounter : 0 ,
      connection : false,
      dataRequired : true ,
    },

    getters: {
      getRoomStatus(state : any) {
        return state.roomStatus;
      },
      getReferenceNumber (state : any) {
          return state.referenceNumber
      },
      getRoomId (state : any){
          return state.roomId
      },
      getMessageCounter (state : any){
        return state.messageCounter
    },
      getReferenceNumberDetails(state : any) {
          return state.referenceNumberDetails
      },
      getCloseRooms (state : any){
        return state.closeRooms
      },
      showChatModal(state : any) {
        return state.chatModal;
      },
      getConnection (state :any){
        return state.connection
      },
      getDataRequired (state : any){
        return state.dataRequired
      }
    },

    mutations: {
      CHANGE_ROOM_STATUS(state : any, status : string) {
          state.roomStatus = status;
      },
      CHANGE_REFERENCE_NUMBER(state :any, value : string) {
        state.referenceNumber = value;
    },
    CHANGE_ROOM_ID(state : any , value : string) {
        state.roomId = value;
    },
    CHANGE_COUNTER(state : any , value : string) {
      state.messageCounter = value;
  },
    CHANGE_REFERENCE_NUMBER_DETAILS(state : any , value : any){
        state.referenceNumberDetails = value
    },
    TOGGLE_CHAT_MODAL(state : any, status : boolean) {
        state.chatModal = status;
    },

    },
    actions: {
      changeRoomStatus( {commit} : {commit: any} , status : string) {
        commit("CHANGE_ROOM_STATUS", status);
      },
      addReferenceNumber ({commit} : {commit: any} , value : string){
        commit("CHANGE_REFERENCE_NUMBER", value);
      },
      addRoomId ({commit} : {commit: any} , value : string){
        commit("CHANGE_ROOM_ID", value);
      },
      addMessageCounter({commit} : {commit: any} , value : string){
      commit("CHANGE_COUNTER", value);
    },
      addReferenceNumberDetails ({commit} : {commit: any} , value : any ) :void{
        commit("CHANGE_REFERENCE_NUMBER_DETAILS", value);
      },
      toggleChatModal({commit} : {commit: any}, status : boolean) {
        commit("TOGGLE_CHAT_MODAL", status);
      },
      changeConncetion({state} : {state: any}) {
        state.connection = true
      },
      changeDataRequired({state} : {state: any} , value : any) {
        state.dataRequired = value
      },
    },
  };
