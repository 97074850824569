import axios from "@/axios";

export default {
    async getAssignmentModules(context: any, courseId: number) {
        try {
            const response = await axios.get(`/courses/${courseId}/assignments/subsections`);
            context.commit('setAssignmentModules', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async getAssignment(context: any, payload: { courseId: number, assignmentId: number }) {
        try {
            const res = await axios.get(`/courses/${payload.courseId}/assignments/${payload.assignmentId}`);
            context.commit('setAssignment', res.data);
            context.commit('setAssignmentSubmissions', res.data.submissions);
        } catch (error) {
            console.error(error);
        }

    },
    async getUserSubmission(context: any, submissionId: number) {
        try {
            const res = context.state.submissions.find((submission: any) => submission.id === submissionId);
            console.log('res', res);
            context.commit('setUserSubmission', res);
        } catch (error) {
            console.error(error);
        }
    },
    async grade(context: any, payload: { courseId: number, assignmentId: number, submissionId: number, grade: number, comment: string }) {
        const body = new FormData();
        body.append('grade', String(payload.grade));
        body.append('comment', payload.comment);
        body.append('media', '');
        try {
            const res = await axios.post(`/courses/${payload.courseId}/assignments/${payload.assignmentId}/submissions/${payload.submissionId}`, body);
            context.commit('setAssignment', res.data);
            context.commit('setAssignmentSubmissions', res.data.submissions);
        } catch (error) {
            console.error(error);
        }

    }


}
