import axios from "@/axios";
import { computed } from "vue";
import store from "..";

export const calendar = {
  namespaced: true,
  state: {
    events: [
    ],
    toggle: false,

  },

  getters: {
    newEvents(state: any) {
      return state.events;
    },
    getToggle(state: any) {
      return state.toggle;
    },
  },

  mutations: {
    setEvents(state: any, newEvents: any) {
     // console.log(newEvents)
      const newFormedEvents = newEvents.map((event:any) => {
        return {
          title: event.event.title,
          start: event.event.startTime,
          end: event.event.endingTime,
          allDay: false,
          backgroundColor	:event.event.type==='ONLINE'?'#075995':event.event.type==='FACETOFACE'?'#36b080' :'#75ADDF'
        };
      });
      state.events=[...state.events,...newFormedEvents]
    },
    setuToggle(state: any, payload: boolean) {
      state.toggle=payload;
  },
  },

  actions: {
    // after i get events
     async getEvents(context:any){
         const user = computed(()=>{
             return store.getters['auth/user'];
         })
        const events=await axios.get(`users/${user.value.id}/enrolled-events?limit=10&offset=0`);
        context.commit('setEvents',events.data)
     },
     setToggle(context:any, payload: boolean) {
      context.commit('setuToggle',payload)
  },
  },
};
