import { RouteRecordRaw } from "vue-router";
const coachRoutes: Array<RouteRecordRaw> = [
    {
        path: "/coaches",
        name: "coaching",
        meta: {
            requiresAuth: true,
        },
        component: () => import("@/layout/CoachingApp.vue"),

        children: [
            {
                path: "",
                name: "Coaching app",
                component: () => import("@/page/coach/Coaching.vue"),
            },
            {
                path: "coach-detail/:id?",
                name: "Coach Detail",
                component: () => import("@/page/coach/CoachDetails.vue"),
            }
        ],
    },

];



export default coachRoutes;
