'use strict';
export default {
    getQuestion(state: any) {
        return state.question;
      },
    
      getAllQuestion(state: any) {
        return state.questions;
      },
    
}