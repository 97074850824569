<template>
  <div class="bg-app" :class="{ ar: locale === 'ar' }">
    <Header/>
    <div class="main-class">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component"/>
        </transition>
      </router-view>
    </div>
    <div class="space-loading" v-if="isloading"></div>
    <Footer/>
    <chat-modal></chat-modal>
  </div>
</template>
<script>
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";
import {mapGetters} from "vuex";
import ChatModal from "@/component/chat-support/chat-UI/ChatModal.vue";
import Header from "@/layout/header/Header";
import Footer from "@/layout/footer/Footer";

export default defineComponent({
  components: {
    Header,
    Footer,
    ChatModal,
  },
  data() {
    return {
      isloading: true,
    };
  },
  computed: {
    ...mapGetters({
      locale: "appLocale",
      menuStatus: "header/getMenuStatus",
    }),
  },
  created() {
    setTimeout(() => {
      this.isloading = false;
    }, 2000);
  },
  setup() {
    const store = useStore();
    const authenticated = computed(() => {
      return store.getters["auth/authenticated"];
    });

    if (authenticated.value) {
      store.dispatch("auth/autoLogin");
    }
    return {
      authenticated,
    };
  },
});
</script>

<style lang="scss">
@import "~@/assets/scss/app.scss";

.space-loading {
  min-height: 100vh;
}

.main-class {
  min-height: 90vh;

  &.mobile {
    overflow: hidden;
    width: 100vw;
    position: fixed;
    height: 100vh;
    margin-bottom: 100vh;
    z-index: 10;
  }
}
</style>
