import moment from "moment";

export default {
    setActiveTranscript(context: any, time: number) {
        const currentTime = moment(time).millisecond();
        context.commit("setActiveTranscript", currentTime);
    },
    runTranscript(context: any, value:boolean) {
        context.commit("runTranscript", value);
    },
    runTranscriptAudio(context: any, value:boolean) {
        context.commit("runTranscriptAudio", value);
    }

}
