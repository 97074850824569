import axios from "@/axios";
import { first } from "lodash";

export default {
  setGrade(state: any, payload: any) {
    state.grade = payload;
  },
  setSingleGradeItem(state: any, payload: any) {
    state.singleGradeItem = payload;
  },
  setGradeItem(state: any, payload: any) {
    state.gradeItem = payload;

    const addStudentGrade = payload.data.map((std: any) => {
      return {
        firstName: std.firstName,
        lastName: std.lastName,
        id: std.id,
        grade: std.grade,
        comment: std.comment,
      };
    });
    state.StudentListGrade = addStudentGrade;
  },
  findIndex(state: any, payload: any) {
    if (state.studentGrade.length > 0) {
      const index = state.studentGrade.findIndex(
        (student: any) => student.studentId == payload
      );
      state.indexOfStudentGrade = index;
    } else {
      state.indexOfStudentGrade = -1;
    }

    if (state.StudentListGrade.length > 0) {
      const index = state.StudentListGrade.findIndex(
        (student: any) => student.id == payload
      );
      state.indexOfStudentList = index;
    } else {
      state.indexOfStudentList = -1;
    }
  },
  setStudentGrade(
    state: any,
    payload: {
      studentId: number;
      grade: number;
      comment: string;
    }
  ) {
    if (state.indexOfStudentGrade == -1) {
      state.studentGrade.push({
        studentId: payload.studentId,
        grade: payload?.grade,
      });
    } else {
      state.studentGrade[state.indexOfStudentGrade].grade = payload.grade;
      state.studentGrade[state.indexOfStudentGrade].comment = payload.comment;
    }
    state.StudentListGrade[state.indexOfStudentList].grade = payload.grade;
    state.StudentListGrade[state.indexOfStudentList].comment = payload.comment;
  },
  setStudentFeedback(state: any, payload: any) {
    if (state.indexOfStudentGrade == -1) {
      state.studentGrade.push({
        studentId: payload.studentId,
        grade: payload.grade,
        comment: payload.comment,
      });
    } else {
      state.studentGrade[state.indexOfStudentGrade].comment = payload.comment;
    }
    state.StudentListGrade[state.indexOfStudentList].comment = payload.comment;
  },
  clearGradesArray(state: any) {
    state.studentGrade = [];
  },
  setModalFeedback(state: any, payload: any) {
    state.modalFeedback = payload;
  },
  setModalFeedbackGrade(state: any, payload: any) {
    state.modalFeedbackGrade = payload;
  },
  setModalFeedbackItemGrade(state: any, payload: any) {
    state.modalFeedbackItemGrade = payload;
  },
};
