export default {
    setSections(state: any, payload: any) {
        state.sections = payload
        //  return true;
    },
    sefinishedSubsections(state: any, payload: any) {
        state.finishedSubsections = payload
        //  return true;
    },
    setSectionsData(state: any, payload: any) {

        state.SectionsData = payload
        //  return true;
    },
    setpaginationArray(state: any, payload: any) {
        state.paginationArray = payload
    },
    setNotes(state: any, payload: any) {
        state.courseNotes = payload
    },
    setAnnouncements(state: any, payload: any) {
        state.announcements = payload
    },
    setAnnouncementsCount(state: any, payload: any) {
        state.announcementsCount = payload
    },
    setCourseModules(state: any, payload: any) {
        state.courseModules = payload
    },
    setIsEnrolled(state: any, payload: any) {
        state.isEnrolled = payload.isEnrolled
    },
    setModule(state: any, payload: any) {
        state.module = payload
    },
    setLightCourse(state: any, payload: any) {
        state.lightCourse = payload
    },
    setSubsectionNotes(state: any, payload: any) {
        state.sectionNotes = payload
    }

}

