import axios from "@/axios";

export default {
    async addNote(context: any, payload: { subsectionId: number, note: string }) {
        // Add notes to subsection in course section
        const path = `/course-subsections/${payload.subsectionId}/notes`;
        const response = await axios.post(path, {
            note: payload.note, position: 0
        });
        return response;
    },
    async deleteNote(context: any, payload: { subsectionId: number, noteId: number }){
        const path = `/course-subsections/${payload.subsectionId}/notes/${payload.noteId}`;
        const response = await axios.delete(path);
        return response;
    },
    async updateNote(context: any, payload: { subsectionId: number, noteId: number, note: string }) {
        const path = `/course-subsections/${payload.subsectionId}/notes/${payload.noteId}`;
        const response = await axios.patch(path, {
            note: payload.note,
            position: 0
        })
        return response;
    },

};
