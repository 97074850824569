export default {
  "noTimeLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حد زمني محدد للامتحان"])},
  "noAttemptLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد عدد محاولات محدد للامتحان"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب ؟"])},
  "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنقر هنا"])},
  "haveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك حساب ؟"])},
  "rubric": {
    "rubrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاير التقيم"])},
    "Rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاير التقيم"])},
    "Add new Rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة معاير التقيم"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
    "longDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف بالتفصيل "])},
    "RatingScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقاط التقييم"])},
    "RatingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان التقييم"])},
    "RatingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف التقييم"])},
    "Criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعايير"])},
    "Ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييمات"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "AddCriteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة معيار"])},
    "totalPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع النقاط "])},
    "createRubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء التقييم"])},
    "No Rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لم تقم بإنشاء أي من معاير التقيم حتى الآن.انقر فوق الزر لإنشاء واحد."])},
    "titleValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال العنوان "])},
    "criteriaValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب إضافة معيار واحد على الاقل للتقييم "])},
    "allRubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المعايير"])},
    "rubricAssessmentValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار جميع المعايير للتقييم"])},
    "rubricUpdateWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا التقييم المضاف الى الدورة غير قابلة للتعديل"])},
    "rubricDeleteWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا التقييم المضاف الى الدورة غير قابلة للحذف "])},
    "addFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة تقييم"])},
    "changeFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير تقييم"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التقييم"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
    "editRubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل التقييم"])},
    "unsavedChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغييرات غير محفوظة هل تريد الاستمرار؟"])},
    "gradeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن إنشاء تقييم بنفس الدرجة مثل التقييم السابق"])}
  },
  "grades": {
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلامات"])},
    "isCountedTowardsFinalGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محسوب إلى العلامة النهائية"])},
    "gradeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "createGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
    "assessmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم ل"])},
    "shortGradeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم المختصر"])},
    "gradeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الوصف"])},
    "maxPointsGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  الحد الأقصى للنقاط "])},
    "gradeCanExceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن تجاوزه "])},
    "gradeBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علامات اضافية  "])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])},
    "grading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع العلامات"])},
    "nameValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العلامة مطلوب "])},
    "maxPointValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للنقاط مطلوب"])},
    "rubricValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المعايير مطلوبة"])},
    "noGradeItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد طلاب لتقييمهم"])},
    "add new grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة علامات جديدة"])},
    "updateGradeItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث العلامة"])},
    "rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معايير التقييم"])},
    "allGrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع العلامات"])},
    "gradeFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم علامة الطالب "])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم"])},
    "addFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة تقييم"])},
    "EnterGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علامات الطلاب "])},
    "gradeFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلامة الى"])},
    "no grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد علامات. انقر فوق الزر لإنشاء واحدة."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "max point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلامة القصوى"])},
    "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلامات الاضافية"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "enter grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخال علامة"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "totalGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلامة الكلية"])},
    "totalGrades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلامة الكلية"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
    "assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم"])},
    "allGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع العلامات"])},
    "gradeDeleteWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذه العلامة المضافة الى الدورة غير قابلة للحذف "])},
    "noGradeStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد لك علامات."])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])}
  },
  "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصحح؟"])},
  "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور"])},
  "studentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسم الطالب"])},
  "Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشورات"])},
  "Rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معاير التقيم"])},
  "Grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات"])},
  "studentGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الطالب"])},
  "AddTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة موضوع"])},
  "SearchOrAddTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث أو إضافة موضوع"])},
  "addQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة سؤال"])},
  "Question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال ال "])},
  "Point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علامة"])},
  "correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صحيحة"])},
  "choosePoolAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر اجابة السؤال"])},
  "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صحيح"])},
  "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاطئ"])},
  "unCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجابتك خاطئة  "])},
  "correctAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجابة الصحيحة هي:"])},
  "NewRubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قواعد جديدة"])},
  "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأختبارات"])},
  "backCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة الى الدورة"])},
  "backToStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة الى الطلاب"])},
  "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة الى الصفحة الرئيسية"])},
  "allQuizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الأختبارات"])},
  "noOfQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاسئلة "])},
  "oneDirection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتجاه واحد"])},
  "twoDirection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتجاهين"])},
  "SHowALlEnrollment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض جميع الطلاب"])},
  "YourGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علامتك:"])},
  "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلامة:"])},
  "Ooops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هناك خطاء "])},
  "ReviewExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الامتحان "])},
  "writeAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكتب الجواب هنا..."])},
  "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلاب"])},
  "enableDiscussionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين لوح المناقشة"])},
  "disableDiscussionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعطيل لوحة المناقشة"])},
  "lockDiscussionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قفل لوحة المناقشة"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاسئلة:"])},
  "invalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن تكون عدد الاسئلة أكبر من 0 وأقل أو تساوي"])},
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لا يمكن ايجاد الصفحة المطلوبة"])},
  "invalidattemptLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المحاولات غير صالح"])},
  "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإختبار"])},
  "timeRemaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت المتبقي"])},
  "Random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عشوائي"])},
  "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثابت"])},
  "ExamType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نوع الإختبار:"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان:"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف:"])},
  "QuestionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأعلى  للاسئلة"])},
  "timeLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأعلى للوقت(دقائق)"])},
  "invalidtimeLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأعلى للوقت غير صالح"])},
  "attemptLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأعلى للمحاولات"])},
  "invalidQuestionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأعلى للاسئلة غير صالح"])},
  "examTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الامتحان"])},
  "examTitlereq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الامتحان مطلوب"])},
  "examDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الامتحان"])},
  "examDescriptionreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الامتحان مطلوب"])},
  "examTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسئلة الامتحان"])},
  "createExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء امتحان"])},
  "isOmniDirectional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هو اتجاه واحد"])},
  "isTimeLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حد زمني"])},
  "isAttemptLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حد محاوللات"])},
  "editExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل امتحان"])},
  "DeleteExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف امتحان"])},
  "editquestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل السؤال"])},
  "openDiscussionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فتح لوحة المناقشة"])},
  "Discussion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناقشة"])},
  "tellMeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخبرني المزيد"])},
  "No_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد طلبات"])},
  "OnlineEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث عبر الإنترنت"])},
  "Passwords_not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور غير مطابقة"])},
  "visitLectureLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زيارة رابط المحاضرة"])},
  "Face2Face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالموقع"])},
  "groupCommentEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل التعليق"])},
  "paymentPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية وشروط الاستخدام"])},
  "noResultFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على نتائج"])},
  "institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهة العمل"])},
  "postMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسحب او اسقط ملفات المنشور"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشر"])},
  "ViewMorePost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المزيد"])},
  "ViewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المزيد"])},
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا"])},
  "welcomePlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نرحب بك في منصتنا "])},
  "Hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هجين"])},
  "Date_is_Invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا التاريخ غير صالح (يجب أن يكون تاريخ البدء بعد اليوم)"])},
  "Please_Valid_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رابط صحيح من فضلك"])},
  "Visit_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الذهاب إلى الرابط"])},
  "liveOnlineLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرة مباشرة عبر الإنترنت"])},
  "onSiteLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرة في الموقع"])},
  "learningTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع التعلم"])},
  "SELF_PACED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعلم الذاتي"])},
  "LIVE_ONLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دروس أونلاين"])},
  "ON_SITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالموقع"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
  "Overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص"])},
  "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليم"])},
  "credentialType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد نوع الاعتماد"])},
  "workEmploymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد نوع التوظيف"])},
  "validlocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال الموقع"])},
  "validtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال العنوان "])},
  "validemployementType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال نوع التوظيف"])},
  "validorganizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم المنظمة"])},
  "credentialTypeisRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الاعتماد مطلوب"])},
  "certificateNameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشهادة مطلوب"])},
  "PleaseEnterIssuingOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إختيار المؤسسة المصدرة"])},
  "Please_enter_specializations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إختيار التخصصات"])},
  "Please_enter_start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إختيار تاريخ البدء"])},
  "compare_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى التأكد من أن تاريخ الانتهاء يأتي بعد تواريخ البدء"])},
  "Please_enter_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إختيار تاريخ الانتهاء"])},
  "linkedIn_should_be_a_URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون عنوان لينكد إن صحيح"])},
  "introductoryVideo_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفيديو التعريفي يجب ان يكون اقل من 99 ميجابايت"])},
  "Price_shouldnt_be_empty_or_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا يكون السعر فارغًا أو صفرًا يجب ان يكون اعلى من 100 ريال سعودي"])},
  "selectlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد اللغة"])},
  "proficiency_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكفاءة مطلوبة"])},
  "Please_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحقل مطلوب"])},
  "Select_dgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد الدرجة"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحتوى"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
  "Coming_Soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قريبا ..."])},
  "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])},
  "Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفات"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجانا"])},
  "there_is_no_content_in_this_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد محتوى في هذا القسم"])},
  "Course_Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوى الدورة"])},
  "There_is_no_attachments_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مرفقات حتى الان"])},
  "Download_File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل الملف"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتمل"])},
  "Mark_as_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع علامة كمكتمل"])},
  "Resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موارد"])},
  "Seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...شاهد المزيد"])},
  "PrivateGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة خاصة"])},
  "PublicGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة عامة"])},
  "Group_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء المجموعة في"])},
  "No_upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد أحداث قادمة."])},
  "About-this-gorup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول هذه المجموعة"])},
  "Joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصبح عضوا"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن المجموعة"])},
  "Visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرئي"])},
  "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ"])},
  "People": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعضاء"])},
  "About-this-Upcoming-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحداث القادمة"])},
  "isDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد خصم؟"])},
  "EditPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل رقم الهاتف"])},
  "Oldpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة"])},
  "Newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
  "ContentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا يكون المحتوى فارغًا"])},
  "AttachVidio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرفاق وتعديل فيديو توضيحي عن نفسك"])},
  "AddPositionsYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف المناصب التي عملت بها"])},
  "currentlyNotfication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حاليًا أي إشعارات"])},
  "Anyone-P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".يمكن لأي شخص رؤية من في المجموعة وما ينشرونه"])},
  "No-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".لا أحد يستطيع رؤية من في المجموعة وما الذي ينشرونه"])},
  "EditPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل كلمة المرور"])},
  "Public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عامة"])},
  "Private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاصة"])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال عنوان موقعك"])},
  "incorrictOldpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر القديمة غير صحيحة"])},
  "NoEventsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد أحداث متاحة"])},
  "SeeMorePosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهدة المزيد من المنشورات"])},
  "Program-Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرنامج والدورات"])},
  "Program-Courses-p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم إلى برامج تفاعلية للغاية مصممة لمساعدة المشاركين على تنفيذ تعلم جديد بتأثير فوري."])},
  "Mulhem-Program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج ملهم"])},
  "Mulhem-Program-p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رحلة تعليمية تأخذ القادة عبر مسارات مختلفة اعتمادًا على احتياجات تنمية القيادة الفردية الخاصة بهم."])},
  "Webinars-workshops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات ونشاطات"])},
  "Webinars-workshops-p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم إلى برامج تفاعلية للغاية مصممة لمساعدة المشاركين على تنفيذ تعلم جديد بتأثير فوري."])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب جديد"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل دخول"])},
  "Favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة"])},
  "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موثوق بها من قبل"])},
  "TermsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية وشروط الاستخدام "])},
  "Invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة"])},
  "noQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد اختبار بعد."])},
  "noAnncouncment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد اعلانات بعد."])},
  "PublicGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة عامة ، انضم إلينا لمشاهدة"])},
  "posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المشاركات."])},
  "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاطات."])},
  "Auth": {
    "codeModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحقق"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تم إرسال رسالة إلى بريد الإلكتروني"])},
      "sub-body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء الضغط على الرابط الذي تم إرساله إلى بريدك الإلكتروني"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])}
    },
    "loginModal": {
      "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة السر؟"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب ؟"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])}
    },
    "LoginPage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
      "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور؟"])},
      "login-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "Empty-Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال سنوات الخبرة"])},
      "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال بريد إلكتروني صحيح"])},
      "invalid-Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال سنوات الخبرة صحيحة"])},
      "invalid-SCFHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال معرف SCFHS صالح"])},
      "invalid-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال الموقع"])},
      "invalid-institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال جهة العمل"])},
      "invalid-occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال المهنة"])},
      "invalid-gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء تحديد الجنس"])},
      "invalid-ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال رقم هوية صحيح"])},
      "required-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور مطلوبة"])},
      "groupCommentEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل التعليق"])},
      "required-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الإلكتروني مطلوب"])},
      "User-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على المستخدم"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني أو كلمة المرور غير صحيحة"])},
      "server-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شيء خاطئ يرجى المحاولة مرة أخرى في وقت لاحق"])},
      "invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال كلمة مرور مكونة من 8 خانات و اكثر "])},
      "invalid-firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال الاسم الأول"])},
      "invalid-lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال اسم الأخير"])},
      "invalid-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إدخال رقم هاتف صالح"])}
    },
    "SignupPage": {
      "EmailAndPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون البريد الإلكتروني ورقم الهاتف صالحين"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى التأكد من ملء جميع المعلومات بشكل صحيح"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأول "])},
      "IDNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهوية"])},
      "currentPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظيفة الحالية"])},
      "workingAreas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مناطق العمل"])},
      "yearsOfExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنوات  الخبرة"])},
      "selectNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد الآن"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأخير"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل الرمز الموضح أدناه"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إنشاء حساب جديد"])},
      "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
      "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون رقم الهاتف صالحًا"])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون البريد الإلكتروني صالحًا"])},
      "alreadyUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الإلكتروني هذا مستخدم بالفعل"])},
      "invalid-password-phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون رقم الهاتف وكلمة المرور صالحين"])},
      "invalid-password-phoneNumber-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون رقم الهاتف وكلمة المرور والبريد الإلكتروني صالحين"])},
      "invalid-email-phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون رقم الهاتف والبريد الإلكتروني صالحين"])}
    },
    "signupModal": {
      "signupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب جديد"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأول"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأخير"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
      "sName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأخير"])},
      "tName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الجد"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر"])},
      "occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهنة"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب "])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك حساب مسبقا؟"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجّل هنا"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول"])}
    },
    "forgetPasswordModal": {
      "forgetPasswordHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور؟"])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["                     البريد الإلكتروني"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرسل"])}
    },
    "verificationModal": {
      "verificationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اذا لم تصلك رسالة , الرجاء ضغط الزر"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرسل رسالة مجدداً"])}
    },
    "messagesModal": {
      "messagesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة"])},
      "emailMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تم إرسال رسالة"])},
      "emailMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء التأكد من بريدك الإلكتروني"])},
      "passwordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تم إعادة تعيين كلمة مرورك"])}
    },
    "resetPasswordModal": {
      "restPasswordHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين كلمة المرور"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])}
    }
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارك بتعليقاتك"])}
  },
  "library": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبة"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد عناصر متوفرة حالياً"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسعى أكاديمية القيادة الصحية لتكون شريكًا للتعلم مدى الحياة من خلال توفير عالم غني بالمعرفة ، حيث يمكن لقادة المستقبل الوصول للمعلومات والموارد التعليمية التي تمكنهم من العثور على إلهامهم والوصول إلى طموحاتهم. نتمسك برؤيتنا في توفير المعرفة وأفضل الممارسات في هذا المجال ومشاركتها مع العالم"])},
    "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة إضاءات"])},
    "specialPublications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشورات والتقارير الخاصة"])},
    "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقالات"])}
  },
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعات"])},
    "lms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعلم الإلكتروني"])},
    "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدربين"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة الاعدادات"])},
    "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبة"])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقويم"])},
    "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفي الشخصي"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "Favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة"])},
    "notif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشعارات"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشعارات"])},
    "markAllAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قراءة الكل"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنظمة"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن الأكاديمية"])},
    "SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتنا"])},
    "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "eLearning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفعاليات"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن الأكاديمية"])},
    "switchInst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوجة المعلم"])},
    "switchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة المستخدم"])},
    "switchCoach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة المدرب"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتبة"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتنا"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفي الشخصي"])},
    "searchNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد نتائج"])},
    "allGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعات"])}
  },
  "Profile": {
    "editAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل البيانات"])},
    "editAccountADDpOSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة منصب"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "myEventNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد فعاليات "])},
    "myCoursesNOtFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد دورات"])},
    "mySessionNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد جلسات"])},
    "myPurchaseNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مدفوعات سابقة"])},
    "goCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذهب إلى الدورة "])},
    "Favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة"])},
    "Purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمليات الشراء"])},
    "CourseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدورة:"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاطات"])},
    "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل النشاطات"])},
    "savedPosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشورات المحفوظة"])},
    "emptyLikedPosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تعجب بأي منشور للآن"])},
    "emptysavePost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تحفظ أي منشور للأن"])},
    "likedPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضغطت زر الإعجاب لهذا المنشور"])},
    "savedPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد حفظت هذا المنشور"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات"])},
    "allCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الدورات"])},
    "viewCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الدورة من جهة الطالب"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاطات"])},
    "Piinedpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسؤول ثبت هذا المنشور"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "lmsInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحاضر"])},
    "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنك الأمتحانات"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنك الأسئلة"])},
    "noEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قريبا"])}
  },
  "Event": {
    "programOutline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاور البرنامج"])},
    "targetAudience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمهور المستهدف"])},
    "similarEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرامج مشابهة"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المشاركين"])},
    "ArabicEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
    "EnglishEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنجليزية"])},
    "f2fEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط في الموقع"])},
    "shareEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاركة هذا البرنامج"])},
    "onlineEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط عن بعد"])},
    "Hosted-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرب الرئيسي"])},
    "Enrollment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاركين"])},
    "EventOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظرة عامة"])},
    "AboutEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن البرنامج"])},
    "AboutEventError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول هذا الحدث لا ينبغي أن يكون فارغا"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى"])},
    "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
    "EventFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر النشاط"])},
    "ENROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل"])},
    "ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشارك"])},
    "addfav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة إلى المفضلة"])},
    "removefav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف من المفضلة"])},
    "Guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضيوف"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقدمين"])},
    "addHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ إضافة  مقدم"])},
    "titleHostPo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مقدم"])},
    "titleEditGuestPo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل ضيف"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث في المجموعة"])},
    "addhost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة إلى المقدمين"])},
    "removehost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مضاف إلى المقدمين "])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "addGuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ إضافة ضيف "])},
    "titleGuestPo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة ضيف  "])},
    "resetfi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المعلومات"])},
    "Guestname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الضيف "])},
    "guestEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني "])},
    "guestOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منظمة الضيف"])},
    "guestOcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهنة الضيف"])},
    "guestPic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الضيف "])},
    "guestAbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن الضيف"])},
    "Guestnamepl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب اسم  الضيف "])},
    "guestEmailpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب البريد الإلكتروني   "])},
    "guestOrgpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب المنظمة التي يعمل بها الضيف"])},
    "guestOcupl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب مهنة  الضيف"])},
    "guestPicpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضع صورة  الضيف "])},
    "guestAbopl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اكتب قليلا عن الضيف"])},
    "otherEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاطات أخرى بهذه المجموعة "])},
    "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرفة المزيد"])},
    "joinGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنضم للمجموعة"])},
    "AddEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء نشاط جديد"])},
    "EventDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات النشاط"])},
    "eventType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع النشاط "])},
    "EventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النشاط"])},
    "oldFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر النشاط القديم"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر النشاط الجديد"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لغة النشاط"])},
    "MeetingUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط النشاط"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
    "eventDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت وتاريخ النشاط "])},
    "eventDateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ ووقت الحدث غير صالحين"])},
    "EventTitleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال عنوان الحدث"])},
    "enterFeemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال الرسوم أكثر او يساوي 0"])},
    "EventFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "EventTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى"])},
    "aboutEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن النشاط "])},
    "coverImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل صورة غلاف النشاط "])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ملفات النشاط "])},
    "SaveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ المسودة"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
    "eventNamePl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب اسم النشاط "])},
    "eventlocationPl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب موقع النشاط "])},
    "eventaboutPl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة وصف للنشاط "])}
  },
  "landingPage": {
    "EduTrackTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتنا التعليمية"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكاديمية القيادة الصحية"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تم اعتماد أكاديمية القيادة الصحية من قبل الهيئة السعودية للتخصصات الصحية لدعم أنشطتها وخدماتها.  بدأت أنشطتها في 2017 مع التركيز على تطوير القادة في جميع المستويات داخل نظام الرعاية الصحية وتزويدهم بخبرات تعليمية عالمية المستوى لدعم تحول نظام الرعاية الصحي السعودي   "])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إعادة تصور الرعاية الصحية والقيادة والتعليم"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
    "visionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أن نكون الخيار العالمي لتطوير القيادة الصحية"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالتنا  "])},
    "missionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أن نطلق إمكانات القيادات الفردية و الجماعية لتحقيق صحة و رعاية أفضل"])},
    "CEOdr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["د. سامي يوسف"])},
    "CEOSubtitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيس التنفيذي, أكاديمية القيادة الصحية"])},
    "CEOQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن حريصون دائمًا على تطوير شراكات جديدة وأفكار مبتكرة من شأنها دفع تنمية الريادة وتحقيق التأثير المطلوب على خدمات الرعاية الصحية, وتحقيق نجاح باهر في برنامج التحول في الرعاية الصحية. يسعدني دعوتكم لاستكشاف نبذة مختصرة عنا والتواصل معنا لتلبية احتياجاتكم."])},
    "MulhemParallaxTi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتطوير قادة الصحة الناشئة"])},
    "MulhemParallaxparg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج وطني متكامل مقدم من رئاسة الشؤون الأكاديمية وأكاديمية القيادة الصحية في الهيئة السعودية للتخصصات الصحية , ويتضمن حزمة تدريبية مخصصة لدعم المتدربين في برامج الهيئة السعودية للتخصصات الصحية بالمهارات والكفاءات القيادية اللازم."])},
    "HORIZONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج تطوير المهارات القيادية"])},
    "HORIZONSPa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج تدريبية للقيادات, مُصممة محليًا ولكن بمنظور عالمي؛ تُركز على الحالات والتحديات في نظام الرعاية الصحية بالمملكة العربية السعودية. لهذا يُعد كل برنامج فريدًا من نوعه؛ يجمع بين الخبرات المحلية والدولية والتي تساعد على تطوير المهارات القيادية استنادًا على نتائج الأبحاث والدراسات المنشورة. نستهدف من خلالها جميع المستويات القيادية: القادة الناشئون, والإدارات الوسطى, والقيادات التنفيذية."])},
    "HORIZONSPa1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "HORIZONSPa2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "LEADERSHIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوجيه القيادي"])},
    "LEADERSHIPPa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقدم الأكاديمية توجيه وإرشاد مؤثر للقادة سواء الأفراد أو المجموعات. بالتعاون مع نخبة من الموجهين والمرشدين ذوي الخبرة والمعتمدين دوليًا, تم اختيارهم بما يتماشى مع أفضل الممارسات العالمية:"])},
    "LEADERSHIPCi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم القدرات القيادية الاحترافي"])},
    "LEADERSHIPCiPa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  خدمة تقييم قادة الرعاية الصحية "])},
    "LEADERSHIPCi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول إلى مجموعة واسعة من الأدوات بما في ذلك تقييم 360 المصمم لتحديد المهارات والقدرات والكفاءات"])},
    "LEADERSHIPCi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن استخدامها من قبل الأفراد والمؤسسات لتحديد نقاط القوة ومجالات التنمية"])},
    "LEADERSHIPCi3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساعدة في تقييم مدى ملاءمتها لمنصب أعلى والأهلية لمزيد من التطوير"])},
    "LEADERSHIPPa1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "LEADERSHIPPa2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "LEADERSHIPPa3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "FUTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتبة أكاديمية القيادة الصحية"])},
    "FUTUREPa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عالم غني بالمعرفة , يتيح لقادة المستقبل الوصول إلى المعلومات والموارد التعليمية التي تمكنهم من العثور على إلهامهم والوصول إلى  طموحاتهم. نتمسك برؤيتنا في توفير المعارف وأفضل الممارسات في مجال القيادة ومشاركتها مع العالم."])},
    "eduTackTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا أكاديمية القيادة الصحية؟"])},
    "eduTrackD1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أول أكاديمية للقيادة الصحية في المنطقة"])},
    "eduTrackD2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراء الأنشطة في جميع أنحاء المملكة العربية السعودية"])},
    "eduTrackD3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعاون مع المؤسسات القيادية المعتمدة"])},
    "eduTrackD4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقديم البرامج لجميع المستويات القيادية"])},
    "eduTrackD5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام البرامج لمنهجيات تعلم مختلفة"])},
    "eduTrackD6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التركيز على رؤية 2030 للتحول في القيادة الصحية"])},
    "eduTrackD7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج ثنائية اللغة"])},
    "eduTrackD8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إثراء المحتوى المحلي"])},
    "eduTrackD9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إمكانية تخصيص البرامج"])},
    "helthcareValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيم الأساسية لأكاديمية القيادة الصحية"])},
    "coreValueNa1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعاون"])},
    "coreValueNa2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإبتكار"])},
    "coreValueNa3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحفيز"])},
    "coreValueNa4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التأثير"])},
    "coreValueTe1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتواصل ونعمل بكفاءة مع شركائنا داخليًا وعلى مستوى نظام الرعاية الصحية"])},
    "coreValueTe2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعزيز ثقافة الإبداع والحلول الابتكارية التي تؤثر على الصعيدين الداخلي والخارجي وعلى نطاق المنظمة"])},
    "coreValueTe3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهدف إلى أن نكون مصدر إلهام للأفراد والفرق ومقدمي الرعاية الصحية ليصبحوا قادة أفضل"])},
    "coreValueTe4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسعى جاهدين لتحقيق إنجازات قيادية قابلة للقياس من خلال التحسين المستمر للمنتجات والخدمات"])}
  },
  "mulhemPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج ملهم"])},
    "subtitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتطوير القيادات الصحية الناشئة"])},
    "aboutmulhem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج وطني متكامل تقدمه الرئاسة التنفيذية للشؤون الأكاديمية، وأكاديمية القيادة الصحية بالهيئة السعودية للتخصصات الصحية للقيادات الصحية الناشئة الذي يتضمن حزمة تدريبية مخصصة لدعم متدربي برامج الهيئة السعودية للتخصصات الصحية بالمهارات والجدارات القيادية اللازمة لقيادة الفرق الصحية متعددة التخصصات بثقة وكفاءة."])},
    "mulhemTrack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسارات ملهم"])},
    "welcomeMulhem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أهلا بك معنا في ملهم"])},
    "WelcomeMulhemPa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هدفنا توفير تعليم وتدريب نوعي لمتدربي برامج الهيئة السعودية للتخصصات الصحية بهدف إطلاق قدراتهم القيادية الكامنة لتخريج قادة مسؤولين وفعالين في القطاع الصحي."])},
    "WelcomeTruckT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسارات ملهم"])},
    "WelcomeTruck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بك في مساراتنا التعليمية"])},
    "WelcomeTruckP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج ملهم عبارة عن رحلة تعليمية تأخذك عبر مسارات متنوعة بحسب احتياجاتك القيادية الشخصية , وستعطيك الفرصة لخلق رحلتك الفريدة من خلال التقديم على مسار أو أكثر من مسارات ملهم:"])},
    "whyMulhem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا اكاديمية القيادة الصحية ؟"])},
    "whyMulhemP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيمانا بقدرات القيادات الصحية الناشئة في برامج الإختصاص والزمالة السعودية فقد أنشئ برنامج ملهم لتمكينهم وإلهامهم للتعلم والنمو المستمر من خلال تصميم برامج ذات:"])},
    "whyMulhemT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تركيز الرعاية الصحية"])},
    "whyMulhemP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسارات متخصصة بالقيادة في مجال الرعاية الصحية مرتكزه على الجدارات والمهارات في مصفوفة نموذج القيادة لأكاديمية القيادة الصحية."])},
    "whyMulhemT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هيئة تدريسية دولية مشهورة"])},
    "whyMulhemP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج نوعية تصقل قدرات المتدربين القيادية الفريدة"])},
    "whyMulhemT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" برامج تطوير القيادة المبنية على البراهين"])},
    "whyMulhemP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرصة الانضمام لنخبة قياديي الرعاية الصحية الملهمين ومشاركة الحلقات والندوات والمواضيع وكل ذلك وأكثر من خلال منصة مجتمع القيادة الصحية."])},
    "whyMulhemT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إمكانيات تخصيص البرنامج"])},
    "whyMulhemP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرصة الانضمام لنخبة قياديي الرعاية الصحية الملهمين ومشاركة الحلقات والندوات والمواضيع وكل ذلك وأكثر من خلال منصة مجتمع القيادة الصحية."])},
    "faqTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسئلة الشائعة"])},
    "faqQ1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أواجه مشكلة تقنية أثناء تقديمي لأحد مسارات برنامج  ملهم, بمن أتواصل بهذا الشأن ؟"])},
    "faqQ2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ماهو مقدار الوقت الذي يجب أن التزم فيه مع برنامج ملهم ؟ "])},
    "faqQ3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل يقدم برنامج هوزايزونز حضوريا أم عن بعد ؟"])},
    "faqQ4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كيف تتم عملية اختيار متقدمي برنامج آفاق ؟  "])},
    "faqQ5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل برنامج ملهم للسعوديين فقط ؟"])},
    "faqQ6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل ملهم برنامج مدفوع الثمن؟"])},
    "faqQ7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هناك ساعات تعليم مستمر مقدمة في مسارات ملهم ؟"])},
    "faqQ8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم عدد ساعات التعليم الطبي المستمر المقدمة لكل مسار؟"])},
    "faqA2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتطلب البرنامج 40 ساعة موزعة على 16 أسبوع. في حال تم قبولك في البرنامج سنتواصل مع مدير برنامج تخصصك لدعم تعلمك وتسهيل حضورك."])},
    "faqA3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتضمن برنامج آفاق كلا النوعين:حضورياً بالرياض, جلسات عن بعد  'افتراضية'"])},
    "faqA4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  يخضع المتدربين لعملية اختيار صارمة, ويتم اختيارهم من قبل جهة ثالثة من خبراء في المجال. وتتضمن العملية ما يلي:"])},
    "faqA41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسليم الطلب"])},
    "faqA42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحلة تقييم السيرة الذاتية"])},
    "faqA43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصفية المبدئية"])},
    "faqA44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصفية النهائية"])},
    "faqA45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحلة اللجنة النهائية"])},
    "faqA5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرنامج مخصص لمتدربي برامج الإختصاص والزمالة في جميع التخصصات الصحية في الهيئة السعودية للتخصصات الصحية في دول الخليج    "])},
    "faqA6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نعم, الهيئة السعودية للتخصصات الصحية تتحمل نفقات الدفعة الأولى لعام 2022م."])},
    "faqA62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* الهيئة لا تتحمل نفقات السفر والإقامة للأنشطة الحضورية"])},
    "faqA7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل هناك ساعات تعليم مستمر مقدمة في مسارات ملهم ؟"])},
    "faqAone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مؤتمر قادة المستقبل في القطاع الصحي"])},
    "faqA812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["٨ ساعات ساعات تعليم مستمر"])},
    "faqA82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات المتدربين القيادية"])},
    "faqA822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعة تعليم مستمر لكل ندوة"])},
    "faqA83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حلقات القيادة لممثلي المتدربين"])},
    "faqA832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعتي تعليم مستمر لكل حلقة"])},
    "faqA84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج آفاق"])},
    "faqA842": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["٤٠ ساعة تعليمية تعطى عند استكمال متطلبات البرنامج"])}
  },
  "HorizonxProgram": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج تدريب تطوير القيادة"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطوير القيادات الصحية الناشئة"])},
    "hozText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج تدريبية للقيادات, مُصممة محليًا ولكن بمنظور عالمي؛ تُركز على الحالات والتحديات في نظام الرعاية الصحية بالمملكة العربية السعودية. لهذا يُعد كل برنامج فريدًا من نوعه؛ يجمع بين الخبرات المحلية والدولية والتي تساعد على تطوير المهارات القيادية استنادًا على نتائج الأبحاث والدراسات المنشورة. نستهدف من خلالها جميع المستويات القيادية: القادة الناشئون, والإدارات الوسطى, والقيادات التنفيذية."])},
    "horizans1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم المهارات "])},
    "horizans2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ورشة عمل"])},
    "horizans3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ندوات افتراضية"])},
    "horizans4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسات توجيهية "])},
    "horizans5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مشاريع ومهام قيادية"])},
    "horizans6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الحفل الختامي "])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إغلاق التقديم"])},
    "completeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند إكمالك للبرنامج"])},
    "completeSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستجد نفسك قادرا على:"])},
    "complete1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شرح الكفاءات والمهارات والسلوكيات اللازمة للقيادة على المستوى الفردي"])},
    "complete2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعرف على نقاط القوة والضعف ووضع خطة تحسين فردية"])},
    "complete3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقارنة أساليب القيادة المختلفة والأدوارالمستخدمة لقيادة القرق الصحية"])},
    "complete4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ممارسة مهارات التواصل الفعال وأساليب زيادة التأثير القيادي."])},
    "complete5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق مناهج التفكير المنظومي في الرعاية الصحية."])},
    "complete6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق أساسيات جودة الرعاية الصحية ومبادئ سلامة المرضى."])},
    "complete7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ترجمة المعرفة للرفع من المسؤولية وتحسين الرعاية المتمحورة حول المريض."])},
    "complete8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تأسيس استراتيجية للمحافظة على عافية الممارس ونموه المستمر"])},
    "horizons2020T": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج آفاق 2022 "])},
    "horizansDQ1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة:"])},
    "horizansDQ2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ بداية ونهاية البرنامج:"])},
    "horizansDQ3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التكلفة:"])},
    "horizansDQ4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات التعليم المستمر:"])},
    "horizansDQ5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ أغلاق التقديم:"])},
    "horizansDQ6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ المقابلات الشخصية:"])},
    "horizansDA1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16 أسبوعا "])},
    "horizansDA2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 يناير حتى 14 ابريل"])},
    "horizansDA3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مجاني - تتحمل الهيئة السعودية للتخصصات الصحية تكاليف البرنامج * لا تشمل نفقات السفر والإقامة للأنشطة الحضورية"])},
    "horizansDA4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 ساعة"])},
    "horizansDA5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25 نوفمبر 2021"])},
    "horizansDA6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 8 و9 ديسمبر 2021"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل كتيب البرنامج"])}
  },
  "Webinars": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الندوات الشهرية"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات المتدربين القيادية "])},
    "WbinarsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات شهرية تقدم افتراضيا لجميع متدربي شهادات الدبلوم والاختصاص والزمالات تركز على الجدارات والكفاءات القيادية للأفراد المشاركين بالقطاع الصحي."])}
  },
  "LeaderShip": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الندوات الشهرية"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات المتدربين القيادية "])},
    "LeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات شهرية تقدم افتراضيا لجميع متدربي شهادات الدبلوم والاختصاص والزمالات تركز على الجدارات والكفاءات القيادية للأفراد المشاركين بالقطاع الصحي"])}
  },
  "createGroup": {
    "all_data_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع البيانات المطلوبة"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء مجموعة جديدة"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة المجموعة"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجموعة"])},
    "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال اسم المجموعة"])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خصوصية المجموعة"])},
    "selectPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال خصوصية المجموعة"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف المجموعة"])},
    "writeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال وصف المجموعة"])},
    "coverImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة غلاف المجموعة"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])}
  },
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عرض المزيد"])},
  "group": {
    "Reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رد"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "Liked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعجبني"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤية المزيد"])},
    "startSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدء الجلسة"])},
    "PastEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحداث الماضية"])},
    "enterlinkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ادخال رابط لينكد إن"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط لينكد إن"])},
    "validLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال رابط صالح"])},
    "selectGroupPi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر صورة للمجموعة"])},
    "selectGroupco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر صورة غلاف للمجموعة"])},
    "selectEditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر صورة شخصية "])},
    "fnValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة الاسم الأول"])},
    "flValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة الاسم الأخير"])},
    "selectOcu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة المهنة"])},
    "enterTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة لقبك"])},
    "selectLoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة الموقع"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشرف"])},
    "selcetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة بعض المعلومات عنك"])},
    "CreateGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء مجموعة"])},
    "leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغادرة المجموعة"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم للمجموعة"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد الإنتظار "])},
    "request2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات المعلقة"])},
    "contentgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنضم الأن لمشاهدة المنشورات "])},
    "noupcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد نشاطات قادمة"])},
    "nopinned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد منشورات مثبتة"])},
    "fname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأول"])},
    "lname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الثاني"])},
    "sname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأخير"])},
    "tname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الثالث"])},
    "Occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهنة"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان"])},
    "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
    "aboutU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن المستخدم"])},
    "upoladPic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل الصورة الشخصية"])},
    "RecentActivityErorr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك أي نشاط حديث."])},
    "GroupD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنت لست جزء من أي مجموعة."])},
    "titleGrou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعات"])},
    "recentAc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحدث "])},
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعضاء"])},
    "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عضو"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعجاب"])},
    "likes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعجاب"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليقاَ"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تثبيت"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رد"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "writeCom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب تعليقاَ ..."])},
    "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول "])},
    "AboutGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حول  هذا المجموعة "])},
    "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
    "Admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسؤولون"])},
    "Members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعضاء"])},
    "inviteMem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة أعضاء"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "Invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دعوة"])},
    "viewMoreCom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["عرض ", _interpolate(_named("num")), " تعليقات"])},
    "createpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء منشور "])},
    "createpost2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشر"])},
    "whatMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بم تفكر "])},
    "editpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل المنشور "])},
    "updatepost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التعديل"])},
    "searchh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث ..."])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاطات"])},
    "pinned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشورات المثبتة"])},
    "upcomingEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاطات القادمة"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشارك"])},
    "recentMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحدث الصور"])},
    "recentpPinned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحدث المنشورات المثبتة"])},
    "noPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد منشورات حالية"])},
    "pinnedmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسؤول ثبت هذا المنشور"])},
    "unsave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدم الحفظ"])},
    "unpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدم التثبيت"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ريال"])},
    "mySession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلساتي"])},
    "myCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوراتي"])},
    "myEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاطاتي"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى"])},
    "going": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل"])},
    "suggestedCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دورات مقترحة"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجانا"])},
    "suggestedCoaches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدربين المقترحين"])},
    "suggestedEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاطات المقترحة"])},
    "suggestedGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعات المقترحة"])},
    "enrollNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنضم الآن"])},
    "messageLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد غادرت المجموعة"])},
    "messageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن في انتظار القبول"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصوصية:"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الإنتظار"])},
    "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زيارة"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعات"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج"])},
    "myGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعاتي"])},
    "joined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الأنضمام"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر"])}
  },
  "newEventPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنشطة المجموعة"])},
    "addEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء نشاط جديد"])},
    "pastEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنشطة السابقة"])},
    "eventname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم النشاط"])},
    "eventtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة النشاط"])},
    "eventdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ ووقت النشاط "])},
    "enrollmentEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المشاركين"])}
  },
  "contactus": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسم"])},
    "nameplac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة اسمك "])},
    "cusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العميل"])},
    "cusTypeplac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العميل"])},
    "reqType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلب"])},
    "nreqTypeplac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلب"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "emailplac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الرجاء كتابة البريد الإلكتروني هنا"])},
    "Inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإستفسار"])},
    "Inquiryplac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة الإستفسار هنا"])},
    "nameValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة اسمك هنا"])},
    "emailValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة بريد إلكتروني صحيح"])},
    "requestValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك اختيار نوع الطلب"])},
    "customerValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك اختيار نوع العميل"])},
    "MessageValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك كتابة الإستفسار هنا"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فردي"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منظمة"])},
    "servicerequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب خدمة"])},
    "requestforinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب للحصول على معلومات"])}
  },
  "lmcLogin": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "MyDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
    "MyCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوراتي"])},
    "MyExams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختباراتي"])},
    "PerformanceSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص الأداء"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة السر؟"])},
    "forgotPassDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال بريدك الإلكتروني هنا وسنرسل إليك بريدًا إلكترونيًا لإعادة تعيين كلمة مرورك"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين كلمة المرور"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "Verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
    "varificationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا لم تتلق بريدًا إلكترونيًا , فالرجاء التحقق من مجلد الرسائل غير المرغوب فيها , وإذا كنت لا تزال غير قادر على العثور عليه , فالرجاء النقر فوق زر إعادة الإرسال لتلقي بريد جديد"])},
    "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة إرسال البريد الإلكتروني "])},
    "createCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء دورة"])},
    "createExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء اختبار"])},
    "createNewExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء اختبار جديد"])},
    "createQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء سؤال"])},
    "myQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسئلة الخاصة بي"])},
    "myCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوراتي"])}
  },
  "lms": {
    "imageError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الصورة يجب ان يكون اقل من 99 ميجابايت"])},
    "videoError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الفيديو يجب ان يكون اقل من 99 ميجابايت"])},
    "syllabusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم منهج الدورة يجب ان يكون اقل من 99 ميجابايت"])},
    "selectLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد المحاضرة"])},
    "normalLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحاضرة العادية"])},
    "videoConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرة الفيديو"])},
    "onCampus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحاضرة الموجودة  في الموقع"])},
    "LectureName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحاضرة"])},
    "onlineHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك مشاركة (Vimeo ، Zoom ، إلخ) رابط المحاضرة لطلابك"])},
    "editAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل المهمة"])},
    "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة جديد"])},
    "SubmitAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقديم الإجابة"])},
    "selectAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر الإجابة"])},
    "linkA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط"])},
    "nextQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال التالي"])},
    "prevQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال السابق"])},
    "startQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ الامتحان"])},
    "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلانات"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الإعلانات"])},
    "editFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الملف"])},
    "cancelEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء  "])},
    "Direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الامتحان"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان"])},
    "attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغيابات"])},
    "questionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان السؤال"])},
    "isCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هو الجواب الصحيح؟"])},
    "editOrDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل أو حذف الجواب"])},
    "InvalidAnswerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص إجابة غير صحيح"])},
    "InvalidAnswerGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علامة غير صحية"])},
    "answerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص الإجابة"])},
    "optionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص الخيار"])},
    "addAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة إجابات"])},
    "addOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة خيار"])},
    "questionTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" فئة السؤال "])},
    "SelectQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد امتحان موجود"])},
    "questionDirection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع السؤال"])},
    "answerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الإجابة"])},
    "MULTICHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيار واحد"])},
    "CHECKBOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متعدد الخيارات"])},
    "BOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صحيحة أو خاطئة"])},
    "MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطابقة"])},
    "ORDERING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرز"])},
    "FILLBLANK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["املاء الفراغ"])},
    "FREETEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص"])},
    "questionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف السؤال"])},
    "createQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء سؤال"])},
    "addTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة فئة"])},
    "chooseGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اكتب علامة:"])},
    "chooseQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر السؤال "])},
    "viewCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الدورة"])},
    "invaldGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلامة غير صحيحة"])},
    "pleaseAddExamTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إضافة فئة للاختبار"])},
    "pleaseAddExamQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى إضافة سؤال للاختبار"])},
    "invaldQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال غير صحيح"])},
    "invalidTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة غير صحيحة"])},
    "OrganizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنظمة"])},
    "CourseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الدورة"])},
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بواسطة"])},
    "calendlycon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متصل مع كاليندلي"])},
    "announcementsTitleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال العنوان"])},
    "announcementsContentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال المحتوى"])},
    "announcementSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال الإعلان بنجاح"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل"])},
    "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمر"])},
    "onlineType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% اونلاين"])},
    "onsiteType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% على المنصة"])},
    "haveComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد أنجزت "])},
    "thisCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من هذه الدورة"])},
    "noattachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مرفقات حتى الان"])},
    "courseContentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء محتوى الدورة"])},
    "deleteSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد ؟"])},
    "createCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء الدورة"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
    "deleteDis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنت على وشك حذف هذا القسم هل أنت متأكد من رغبتك في حذفه؟"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
    "courseCert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعط شهادة الدورة"])},
    "UNpSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد من أنك تريد إلغاء نشر هذه الدورة التدريبية؟"])},
    "UnpDis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيظل بإمكان الطلاب الحاليين الوصول إلى هذه الدورة التدريبية "])},
    "UnpDis2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يمكنك اعادة نشره في اي وقت"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطوة 1: تفاصيل الدورة"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطوة 2: محتوى الدورة"])},
    "startCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ الدورة"])},
    "topin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفضل الدورات في "])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطوة 3: نشر الدورة"])},
    "courseIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مقدمة الدورة"])},
    "Mylearning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوراتي"])},
    "clock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات"])},
    "Self-paced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم ذاتي"])},
    "Live-Online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مباشر -اونلاين "])},
    "Exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختبار"])},
    "On-Site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على المنصة "])},
    "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقصى عدد من الطلاب"])},
    "courseCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الدورة"])},
    "limitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  الحد الأعلى للمسجلين"])},
    "StartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت البدء"])},
    "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الإنتهاء"])},
    "courseType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الدورة "])},
    "Courseprerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متطلبات الدورة السابقة"])},
    "addWish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإضافة إلى المفضلة"])},
    "removeWish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الازالة من المفضلة"])},
    "syllabusandco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظرة عامة على المنهج والدورة"])},
    "aboutCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن هذه الدورة"])},
    "whatLearn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما الذي ستتعلمه؟"])},
    "courseOnbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إعدادات الدورة"])},
    "courseCur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" منهج الدورة"])},
    "coursepub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نشر الدورة "])},
    "courseDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الدورة"])},
    "coursePic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" صورة الدورة   "])},
    "coursename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسم الدورة   "])},
    "coursenamereq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة اسم الدورة"])},
    "coursecityreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة موقع الدورة"])},
    "courseTypeReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إختيار نوع الدورة"])},
    "courseLevelReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار مستوى الدورة"])},
    "courseStartTimeReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون التاريخ في المستقبل"])},
    "courseENdTimeReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النهاية يجب أن يكون بعد تاريخ البداية"])},
    "coursecate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" فئة الدورة   "])},
    "selectcat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختار فئة الدورة"])},
    "coursecatreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إختيار فئة الدورة"])},
    "writehere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب هنا "])},
    "Addananswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" أضف"])},
    "courselan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لغة الدورة   "])},
    "courseOutRe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة نتائج التعليم"])},
    "coursepreRe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة متطلب مسبق"])},
    "coursetargRe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة الطلبة المستهدفون"])},
    "Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعة"])},
    "MOREDETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المزيد من التفاصيل"])},
    "LESSDETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل أقل"])},
    "CourseSyllabus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منهج الدورة"])},
    "DownloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنزيل الملف"])},
    "selectlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختار لغة الدورة"])},
    "courselanreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إختيار لغة الدورة"])},
    "QuestionNameReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان السؤال مطلوب "])},
    "questionTopicReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة موضوع السؤال"])},
    "QuestionDesReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف السؤال مطلوب"])},
    "Arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنجليزية"])},
    "coursedisC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة وصف عن الدورة"])},
    "coursedis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اكتب وصفًا موجزًا لما تدور حوله هذه الدورة   "])},
    "coursedisreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة وصف الدورة"])},
    "courseout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ما هي نتائج التعلم من هذه الدورة    "])},
    "coursePre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هناك متطلبات مسبقة للدورة "])},
    "coursetarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من هم الطلاب المستهدفون "])},
    "coursehoue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات الدورة "])},
    "AllCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الدورات"])},
    "Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتائج"])},
    "SAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ريال سعودي"])},
    "Enrollments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسجلين"])},
    "ViewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المزيد"])},
    "LectureURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" رابط المحاضرة"])},
    "Hybrid:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid "])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر"])},
    "SortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صنف حسب"])},
    "MostPopular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأكثر شعبية"])},
    "Newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحدث"])},
    "Resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستمر"])},
    "letsCont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستمر بالتعلم هنا "])},
    "coursele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هو مستوى هذه الدورة "])},
    "Beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبتدئ"])},
    "Intermediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط"])},
    "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقدم"])},
    "coursePrev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو توضيحي عن الدورة"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختياري"])},
    "SelectTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر من هنا"])},
    "courseTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواضيع الدورة الرئيسية"])},
    "courseoldfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الدورة  "])},
    "coursenewfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الدورة بعد الخصم "])},
    "feeoldreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الدورة بعد الخصم يجب أن يكون أقل من سعر الدورة قبل الخصم"])},
    "newfeeReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء كتابة سعر الدورة الجديد"])},
    "invalidHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات الدورة يجب أن تكون أكبر من واحد"])},
    "ivalidLimitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن تكون قيود التسجيل صفرًا أو أكبر من الصفر"])},
    "gotboa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانتقال إلى إعدادات الدورة"])},
    "attahP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بسحب وإسقاط الصورة أو إرفاقها"])},
    "attahV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بسحب وإسقاط الفيديو أو إرفاقه "])},
    "attahF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بسحب وإسقاط الملف أو إرفاقه"])},
    "attahR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بسحب وإسقاط ملفات المصادر أو إرفاقها"])},
    "welcomeSt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بطلابنا الجدد"])},
    "courseW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة الترحيبية للدورة"])},
    "courseMR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك كتابة رسالة ترحيبية "])},
    "courseSyl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخطط الدورة"])},
    "courseSylR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك إرفاق مخطط الدورة"])},
    "fILElECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك إرفاق الملف المطلوب"])},
    "gotostep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانتقال للخطوة الثانية"])},
    "Section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قسم"])},
    "SectionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القسم"])},
    "SectionNameR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القسم مطلوب"])},
    "SubSectionNameR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحاضرة مطلوب"])},
    "SubSectionNameL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يجب ادخال رابط صحيح "])},
    "SubSectionLOcationR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع المحاضرة مطلوب"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
    "sectionAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن هذا القسم"])},
    "sectionAboutR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا القسم مطلوب"])},
    "AddSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   إضافة  قسم   "])},
    "ImportSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل قسم "])},
    "gotostep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانتقال للخطوة الثالثة"])},
    "AddLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  إضافة  محاضرة   "])},
    "Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرة"])},
    "Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قراءة"])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتمل"])},
    "assignmentFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بسحب وإسقاط الواجب أو إرفاقه"])},
    "Audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صوتي"])},
    "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاط"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ملف عن طريق رابط"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة نشاط للدورة"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ملفات متعددة"])},
    "NewLecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرة جديدة"])},
    "Published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم النشر"])},
    "Unpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تنشر"])},
    "UnpublishedD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء النشر"])},
    "Resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادر"])},
    "ResourcesFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفات المصادر"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشر هذه الدورة"])},
    "charExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘عدد المشاركين بالدورة /لكل شهر"])},
    "publishbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشر"])},
    "coursePo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" شهرة الدورة "])},
    "generalsta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحصائيات العامة للدورة"])},
    "courseCom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نسبة الإنجاز "])},
    "totalEnr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي عدد المسجلين"])},
    "CourseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوى الدورة"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة الإعدادات"])},
    "CourseOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظرة عامة"])},
    "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا ,"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
    "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهمة"])},
    "assignmentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المهمة"])},
    "lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرة"])},
    "assignmentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان المهمة"])},
    "assignmentGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة المهمة"])},
    "assignmentDueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ استحقاق المهمة"])},
    "assignmentBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوى المهمة"])},
    "assignmentResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادر المهمة"])},
    "createAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء مهمة"])},
    "desctionBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المناقشات"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسم"])},
    "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد البوستات"])},
    "repliesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد مرات التكرار"])},
    "allSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الاقسام"])},
    "PleaseChoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر من فضلك"])},
    "allDesction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المناقشات"])},
    "isCountedTowardsFinalGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محسوب إلى العلامة النهائية"])},
    "notPublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكورس لم يتم نشره"])},
    "noEnroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لا يوجد مسجلين بعد"])},
    "noSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مناقشات لعرضها."])},
    "studentsAttendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حضور الطلاب"])}
  },
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة الإعدادات"])},
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعرف المزيد"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا "])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الآن "])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الكل"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشتراك"])},
  "subscribeBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشتراك"])},
  "allright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة"])},
  "subscribeFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك في نشرتنا البريدية"])},
  "coach": {
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المزيد"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل مدربين"])},
    "Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج"])},
    "Sessionsstart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابداء الجلسات"])},
    "Leadership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القادة في التدريب"])},
    "Resultsdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد نتائج"])}
  },
  "filter": {
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنجليزية"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنثى"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إعادة تعيين الفلتر"])},
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الافتراضي"])},
    "pricehtl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر: من الأعلى للأدنى"])},
    "pricelth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر: من الأدنى إلى الأعلى"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوى"])}
  },
  "profile": {
    "myCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوراتي"])},
    "myEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشاطاتي"])},
    "myCoachingSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلساتي"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  التاريخ: "])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر: "])},
    "lmsInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحاضر"])}
  },
  "CoachDetail": {
    "issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت تواجه أي مشاكل , انقر هنا .."])},
    "sessionBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حجز الجلسة"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك اختيار كلا الجلستين"])},
    "sessionfaled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك المزيد من دروس الكيمياء المجانية المتاحة. يرجى حجز جلسة التدريب"])},
    "something": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عفوًا حدث خطأ ما , يرجى المحاولة مرة أخرى لاحقًا"])},
    "chemistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجز جلسة تعارفية"])},
    "Book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجز جلسة"])},
    "Know_your_Coach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعرف على مدربك"])},
    "Certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادات والدورات وأدوات التقييم"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض حساب لينكد إن"])},
    "Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة"])},
    "Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخبرة"])},
    "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن نفسي"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تلقيت بريدًا إلكترونيًا بتفاصيل الجلسة."])},
    "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق من الرابط التالي للحصول على تفاصيل الجلسة:"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغات"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم"])},
    "Attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيرة الذاتية"])},
    "introductoryVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو تمهيدي"])}
  },
  "CoachingPanel": {
    "Add_Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف الخبرة"])},
    "login-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "signup-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب جديد"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا لتقديم الطلب!"])},
    "Okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسنا"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ !"])},
    "maximumW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى لعدد الكلمات 500 كلمة"])},
    "LoginPage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
      "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور ؟"])},
      "login-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل"])}
    },
    "SignupPage": {
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لنبدأ!"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلوماتك"])},
      "Institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهة العمل"])},
      "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصورة الشخصية"])},
      "profDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل المهنية"])},
      "mostjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر مسمى وظيفي"])},
      "mostOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحدث منظمة"])},
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب"])},
      "Specialities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختصاصات"])},
      "validdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال بيانات صحيحة"])}
    },
    "header": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الشخصية"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعدادات"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])}
    },
    "sideNav": {
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
      "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقويم"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الشخصية"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعدادات"])}
    },
    "settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعدادات"])},
      "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات الحساب"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
      "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العقود"])}
    },
    "calendar": {
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإعداد الأوقات المناسبة لك "])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخبرنا عندما تكون متاحًا لجلسات التدريب عن طريق تسجيل الدخول باستخدام كاليندلي وإعداد ملفك الشخصي!"])},
      "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الارتباط مع كاليندلي"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم باختيار جلساتك"])},
      "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تحديد نوعين من الأحداث على النحو التالي:"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر هنا لإنشاء نوع حدث جديد في كاليندلي"])},
      "solo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون نوع الحدث فعّال. يجب أن يكون الحدث منفردًا."])},
      "pick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم باختيار جلستك التي ستعرض للتدريب"])},
      "yourChemistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسة التعارف الخاصة بك"])},
      "chooseChemistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الملاحظة أن مدة الجلسات لهذا الحدث هي نصف ساعة (30 دقيقة)"])},
      "chemistrySession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسة التعارف:"])},
      "onetoone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث جلسة التفرد"])},
      "chooseone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الملاحظة أن مدة الجلسات لهذا الحدث هي ساعة واحدة (60 دقيقة)"])},
      "selectEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختار الحدث"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث:"])},
      "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])}
    },
    "dashboard": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدا التدريب مع همم"])},
      "stepOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطوة الاولى"])},
      "stepTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخطوة الثانية"])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكمل صفحتك الشخصية"])},
      "set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" قم بوضع الاوقات المناسبة لك مع كاليندلي"])},
      "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل مع كاليندلي لوضع الاوقات المناسبة لك"])},
      "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتقل للتقويم"])},
      "goP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتقل للصفحة الشخصية"])},
      "notreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنت غير مسجل في أي دورة"])},
      "ifwont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت تريد التسجيل"])},
      "goEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اذهب إلى الدورات"])},
      "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القادم في التقويم"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الجلسة"])},
      "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دقيقة"])},
      "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الحجوزات هذا  "])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
      "taught": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الجلسات التي تم تدريسها"])},
      "chemistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسات التعارف "])},
      "chemistryBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحجوزة"])},
      "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجلسات المحجوزة بعد جلسة "])},
      "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسات"])},
      "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعارف"])},
      "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الكل"])}
    },
    "nda": {
      "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اهلا مدرب "])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا العقد صالح حتى "])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ملاحظة أنك ستجد نسخة من هذا العقد في الإعدادات"])},
      "agreementNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء قراءة العفد والموافقة عليه"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد قرأت ووافقت على تفاصيل العقد"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة"])},
      "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الموافقة على الشروط"])}
    },
    "profile": {
      "editLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل اللغة"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء"])},
      "editAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الحساب"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
      "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهدة حساب لينكد إن"])},
      "validData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال بيانات صحيحة"])},
      "validPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان يكون سعر الدورة اكبر من 100"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول"])},
      "addAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة عني  "])},
      "describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صف من أنت كمحترف. تحدث عن المهارات والإنجازات ذات الصلة"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغات"])},
      "addLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة لغة"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
      "proficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكفاءة"])},
      "availible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلساتي للتدريب متوفرة في هذه اللغة"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو تعريفي عن نفسك"])},
      "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات تعريفية عن نفسك"])},
      "videioPre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["           إرفق فيديو تعريفي        "])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليم"])},
      "addEducation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة شهادة"])},
      "editEducation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل شهادة"])},
      "editVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الفيديو"])},
      "editcv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
      "cancelEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء التعديل"])},
      "EditExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الخبرة"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجامعة"])},
      "Degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادة"])},
      "monthes": {
        "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يناير"])},
        "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فبراير"])},
        "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مارس"])},
        "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إبريل"])},
        "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مايو"])},
        "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يونيو"])},
        "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوليو"])},
        "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أغسطس"])},
        "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبتمبر"])},
        "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكتوبر"])},
        "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوفمبر"])},
        "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ديسمبر"])}
      },
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد الالتحاق"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موعد التخرج"])},
      "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمر"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدوات التقييم والشهادات والتراخيص"])},
      "addTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التراخيص أو الشهادات"])},
      "editTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الترخيص أو الشهادة"])},
      "issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إصداره في"])},
      "credType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الاعتماد"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
      "issOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منظمة الإصدار"])},
      "notExp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تنتهي صلاحية هذا الاعتماد"])},
      "issuedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإصدار"])},
      "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإنتهاء"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هوية الاعتماد"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط الاعتماد"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خبرة العمل"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان"])},
      "employementType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوظيفة"])},
      "orgName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنظمة"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدولة"])},
      "currentlyWorking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنا حاليا أعمل في هذا الدور"])},
      "strtDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الالتحاق"])},
      "edDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
      "jobRes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسؤوليات الوظيفة "])},
      "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيرتك الذاتية"])},
      "postions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناصب التي عملت فيها "])},
      "enterBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل سيرتك الذاتية"])},
      "language": {
        "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانجليزية"])},
        "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
        "begginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبتدئ"])},
        "intermediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقدم"])},
        "nativeorfluent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متحدث بطلاقة"])}
      },
      "degree": {
        "masters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماستر"])},
        "bachelors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بكالوريوس"])},
        "associate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساعد"])},
        "doctoral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دكتوراه"])}
      },
      "types": {
        "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رخصة"])},
        "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة"])},
        "assessmenttool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أداة تقييم"])}
      },
      "emp": {
        "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوام كامل"])},
        "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوام جزئي"])},
        "self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توظيف ذاتي"])},
        "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمل حر"])}
      }
    }
  },
  "UpcomingEventGroup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم إلى برنامج الأحداث لدينا للحصول على تجربة تعليمية أفضل."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوف يمنحك حضور الأحداث العديد من الفوائد. يمكنك بناء روابط ذات مغزى , والتعلم من الآخرين , واكتساب فهم أفضل للمواضيع المختلفة , وأكثر من ذلك بكثير."])},
    "ViewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاهدة جميع الأحداث"])},
    "viewAllEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهدة جميع الأحداث"])},
    "Upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحداث القادمة"])},
    "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهدة الكل"])},
    "suggested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعات المقترحة"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد احداث متوفرة حالياً"])},
    "suggestedEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفعاليات المقترحة"])}
  },
  "lmsdahbord": {
    "Upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القادم في التقويم"])},
    "learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات الخاصة بي"])},
    "JoinNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نضم الآن"])},
    "MoreDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من التفاصيل"])},
    "Certified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدورات المعتمدة"])}
  },
  "nopurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انت لم تشتري أي شيء"])},
  "ReadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقرأ المزيد"])},
  "contactUst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التغير يبدأ بمحادثة"])},
  "OurEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع النشاطات"])},
  "OurGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المجموعات"])},
  "validEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكتب بريد إلكتروني صحيح"])},
  "subscribeThan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا لاشتراكك"])},
  "alreadySub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني مشترك بالفعل "])},
  "followhla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعنا"])},
  "followus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعنا"])},
  "teerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الاستخدام "])},
  "howto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الاستخدام"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
  "chatSupport": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدعم الفني"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نشطون الآن"])},
    "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يمكنني مساعدتك؟"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبًا"])},
    "help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يمكنني ان اساعدك؟"])}
  },
  "landingPageSlider": {
    "we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قادة الرعاية الصحية"])},
    "empower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمكن"])},
    "motivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحفز"])},
    "develop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نطور"])}
  },
  "landingPageExcerpt": {
    "hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكاديمية القيادة في الرعاية الصحية (HLA)"])},
    "excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء أكاديمية القيادة الصحية من قبل الهيئة السعودية للتخصصات الصحية والتي بدأت أنشطتها في 2017 مع التركيز على تطوير القادة على جميع المستويات داخل نظام الرعاية الصحية وتزويدهم بخبرات تعليمية عالمية المستوى لدعم تحول نظام الرعاية الصحية"])}
  },
  "landingPageTracks": {
    "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم القيادة المهنية"])},
    "leadership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنمية المهارات القيادية"])},
    "executive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوجيه القيادي"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحلول التنظيمية"])}
  },
  "aboutUs": {
    "main": {
      "accreddited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اعتماد أكاديمية قيادة الرعاية الصحية من قبل"])}
    },
    "impact-vision": {
      "hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكاديمية القيادة في الرعاية الصحية (HLA)"])},
      "excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حصل على الجائزة الذهبية للتأثير في التدريب من الرابطة الدولية للميسرين (IAF) لبرنامج التيسير (ENABLE) لقادة الرعاية الصحية."])},
      "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرؤية"])},
      "vision-excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أن نكون الخيار العالمي لتطوير القيادة في مجال الرعاية الصحية"])},
      "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهمة"])},
      "mission-excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إطلاق العنان لإمكانات القيادة الفردية والجماعية لتحقيق صحة ورعاية أفضل"])}
    },
    "values": {
      "our-values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمنا"])}
    },
    "why-us": {
      "why-hla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا HLA ..؟"])},
      "health-focused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التركيز على الرعاية الصحية"])},
      "renowned-international-faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هيئة تدريس دولية مشهورة"])},
      "evidence-based-leadership-development-programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج تنمية المهارات القيادية المسندة بالبيّنات"])},
      "Program customizable capabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قدرات البرنامج للتخصيص"])}
    },
    "leadershipModel": {
      "leadership-model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منظمة المهارات القيادية"])},
      "excerpt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صممت HLA نموذج قيادة الرعاية الصحية بالتعاون مع كبار الخبراء المحليين والدوليين. هذا النموذج هو دليل للمهارات والسلوكيات والكفاءات القيادية المحددة المتوقعة لقائد الرعاية الصحية في النظام البيئي الصحي."])}
    },
    "statistics": {
      "Programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج"])},
      "Training-Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات تدريب"])},
      "Beneficiaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستفيد"])}
    }
  },
  "Agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أوافق على الشروط"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])},
  "addBlank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة فراغ"])},
  "howtoUse": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل منصة أكاديمية القيادة في الرعاية الصحية"])},
    "coachPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف تستعمل لوحة المدرب"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف تسجل"])},
    "speakWithCustomerAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف تتحدث مع الخدمة العملاء"])},
    "addCourseAsInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية إضافة دورة"])},
    "bookASessionWithACoach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية حجز جلسة مع مدرب"])},
    "createAGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية إنشاء مجموعة"])},
    "createAnEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية إنشاء حدث"])},
    "createNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية إنشاء تسجيل بالمنصة"])},
    "filterCourseToFindYourCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية تصفية الدورة للعثور على دورتك"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية استعادة كلمة المرور"])},
    "joinAGroupAsAStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية الانضمام إلى مجموعة"])},
    "participateInDiscussionForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية المشاركة في النقاش"])},
    "postSomethingToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية النشر في مجموعة"])},
    "purchaseTicketsForAnEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية شراء تذاكر لحدث"])},
    "registerInNewCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية تسجيل في دورة جديدة"])},
    "submitAnAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية تقديم المهمة"])},
    "loginToHimam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية تسجيل الدخول إلى همم"])},
    "createCourseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية إنشاء محتوى دورة"])},
    "createRubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية إنشاء معاير التقيم"])}
  },
  "footer": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الاستخدام"])},
    "Feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآراء والمقترحات"])},
    "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])}
  },
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الطلب"])},
  "backInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة إلى دوراتي"])},
  "backQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة الى جميع الاسئلة"])},
  "backQuizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة الى جميع الامتاحانات"])},
  "englishVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل النسخة الانجليزية"])},
  "registerCoach": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب"])},
    "Cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيرة الذاتية"])},
    "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نبذة عن نفسك"])},
    "specializations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختصاص"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الجلسة"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
    "proficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكفاءة"])},
    "credentialType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الشهادة"])},
    "recentOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤسسة الحديثة"])},
    "coachingAvailableInLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التدريب متاح باللغة"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرسة"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البدء"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
    "issuingOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤسسة المصدرة"])},
    "doesNotExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لا تنتهي صلاحيته"])},
    "credentialId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرّف الاعتماد"])},
    "credentialUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان Url الخاص ببيانات الاعتماد"])},
    "introductoryVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو تمهيدي"])},
    "linkedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لينكد إن"])},
    "issuedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإصدار"])},
    "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انتهاء الصلاحية"])},
    "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة العلمية"])},
    "certificateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشهادة"])},
    "registerNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الآن"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا لتسجيلك. الآن أنت مدرب في منصتنا"])},
    "registerAsCoach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل كمدرب"])}
  },
  "arabicVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل النسخة العربية"])},
  "Enrollments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشتركين"])},
  "savedPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشورات المحفوظة"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "noSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد منشورات محفوظة بعد."])},
  "noFavoraites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد كورسات و نشاطات محفوطة بعد."])},
  "mulhem": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج ملهم"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتطوير القيادات الصحية الناشئة"])},
    "bannerData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج وطني متكامل تقدمه الرئاسة التنفيذية للشؤون الأكاديمية، وأكاديمية القيادة الصحية بالهيئة السعودية للتخصصات الصحية للقيادات الصحية الناشئة الذي يتضمن حزمة تدريبية مخصصة لدعم متدربي برامج الهيئة السعودية للتخصصات الصحية بالمهارات والجدارات القيادية اللازمة لقيادة الفرق الصحية متعددة التخصصات بثقة وكفاءة."])},
    "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسارات ملهم"])},
    "videoLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو لجعلك اقرب"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بك في برنامج ملهم"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هدفنا توفير تعليم وتدريب نوعي لمتدربي برامج الهيئة السعودية للتخصصات الصحية بهدف إطلاق قدراتهم القيادية الكامنة لتخريج قادة مسؤولين وفعالين في القطاع الصحي.\n"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدا الآن"])},
    "tracksHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسارات ملهم"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبًا بك في مساراتنا التعليمية"])},
    "educationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج ملهم عبارة عن رحلة تعليمية تأخذك عبر مسارات متنوعة بحسب احتياجاتك القيادية الشخصية، وستعطيك الفرصة لخلق رحلتك الفريدة من خلال التقديم على مسار أو أكثر من مسارات ملهم:"])},
    "leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج نوعية تصقل مهارات المتدربين القيادية الفريده."])},
    "registerNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الآن"])},
    "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا ملهم؟"])},
    "whyMulhem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيمانا بقدرات القيادات الصحية الناشئة في برامج الإختصاص والزمالة السعودية فقد أنشئ برنامج ملهم لتمكينهم وإلهامهم للتعلم والنمو المستمر من خلال تصميم برامج ذات: "])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة مجتمع القيادة الصحية."])},
    "communityDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرصة للانضمام إلى قادة الصحة الملهمين والمشاركة في دوائر القيادة والندوات عبر الإنترنت والمزيد من خلال الانضمام إلى مجتمع منصة القيادة الصحية."])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجودة و الكفاءة العالية."])},
    "qualityDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج قيادية عالية الجودة تعزز قدرات المتدربين القيادية الفريدة."])},
    "healthcare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسارات القيادة المتنوعة للرعاية الصحية"])},
    "healthcareDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ركزت المسارات المتخصصة في قيادة الرعاية الصحية على الكفاءات والمهارات الموجودة في نموذج القيادة HLA."])},
    "ourPrinciples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبادئنا"])},
    "leadershipModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعرف على نموذج القيادة لدينا"])},
    "leadershipModelTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج القيادة"])},
    "leadershipDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعتمد برنامج ملهم على الكفاءات والسلوكيات ضمن نموذج قيادة HLA"])},
    "leadershipModelContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صممت أكاديمية القيادة الصحية بالتعاون مع نخبة من الخبراء المحليين والدوليين نموذج القيادة في الرعاية الصحية (LEADERSHIP MODEL) ليصبح دليل لجميع المهتمين في تحديد المهارات والسلوكيات والكفاءات للقادة في القطاع الصحي."])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الايميل"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
    "freeQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة مكررة"])},
    "freeQuestionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا الآن"])},
    "leadershipWebinar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات المتدربين القيادية"])},
    "leadershipLectures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات القيادة الشهرية للمتدربين"])},
    "leadershipLecturesHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرات شهرية تقدم افتراضيا تركز على الجدارات والكفاءات القيادية للأفراد المشاركين بالقطاع الصحي"])},
    "leaderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسار منتدى قادة الصحة في المستقبل"])},
    "leaderContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتدى القيادة الافتراضي متاح لجميع متدربي الدراسات العليا من جميع التخصصات الصحية"])},
    "residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرات القيادة للمتدربين"])},
    "residentsContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاحة للتسجيل لجميع متدربي برامج الدراسات العليا"])},
    "chief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حلقات القيادة لممثلي المتدربين"])},
    "chiefContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة ورش العمل متوفرة لكبار السكان"])},
    "horizon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج آفاق"])},
    "registerMove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقدم الآن"])},
    "horizonContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رحلة تطوير قيادية داعمة لخمسين فردا من متدربي برامج تدريب الهيئة السعودية للتخصصات\nالصحية"])},
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل برنامج ملهم مخصص للسعوديين فقط؟"])},
    "answer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرنامج مخصص للمقيمين والزملاء المسجلين في برامج الهيئة السعودية للتخصصات الصحية من جميع التخصصات الصحية في منطقة دول مجلس التعاون الخليجي."])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل ملهم برنامج مدفوع الأجر؟"])},
    "answer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرنامج برعاية كاملة من قبل SCFHS."])},
    "answer2Danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ملاحظة أن الرعاية لا تغطي تكلفة السفر والإقامة لوحدات الأنشطة الشخصية"])},
    "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يتم تقديم أي ساعات للتعليم الطبي المستمر ضمن مسارات برنامج ملهم؟"])},
    "answer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم إعطاء ساعات التعليم الطبي المستمر لجميع مسارات برنامج ملهم."])},
    "question4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم عدد ساعات التعليم الطبي المستمر المقدمة لكل مسار؟"])},
    "answer4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم إعطاء ساعات التعليم الطبي المستمر وفقًا لوقت وطول المسار التعليمي على النحو التالي."])},
    "answer4Future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتدى قادة الصحة في المستقبل"])},
    "answer4Residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات عبر الإنترنت عن قيادة السكان"])},
    "answer4Chief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوائر قيادة السكان الرئيسيين"])},
    "answer4Horizons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوائر قيادة السكان الرئيسيين"])},
    "CME8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 CME"])},
    "CME1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 CME / ندوة عبر الإنترنت"])},
    "CME2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 CME / دائرة"])},
    "CME40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 CME"])},
    "question5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أواجه مشكلة فنية أثناء تقديم طلبي. بمن يجب علي الاتصال؟"])},
    "answer5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء التواصل مع"])},
    "question6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هو الالتزام الزمني لبرنامج آفاق؟"])},
    "answer6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتطلب البرنامج 40 HRs موزعة خلال 16 أسبوعًا. بمجرد قبولك في البرنامج ، سوف نتصل بمدير برنامج الإقامة الخاص بك لدعم تعليمك وتسهيل حضورك."])},
    "question7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل برنامج آفاق هو برنامج على الإنترنت؟"])},
    "answer7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج آفاق هو برنامج هجين أن\nتشمل كلا الوحدتين في الشخص\nمقرها الرياض وجلسات افتراضية."])},
    "question8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف هي عملية الاختيار\nأجري من أجل آفاق؟"])},
    "answer8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمر المشاركون من خلال صارمة\nعملية الاختيار ويتم اختيارهم\nمن خلال طرف ثالث من الشركات الصغيرة والمتوسطة.\nتشمل هذه العملية:"])},
    "answer8Submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقديم الطلب"])},
    "answer8Scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحلة تقييم السيرة الذاتية"])},
    "answer8Initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["االتصفية المبدئية"])},
    "answer8Final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصفية النهائية"])},
    "answer8Interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحلة المقابلة"])},
    "answer8Committee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحلة اللجنة النهائية"])},
    "answer8Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلانات النتائج"])},
    "horizonProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج الأفق"])},
    "healthLeaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" برنامج آفاق"])},
    "horizonProgramHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رحلة تدريب داعمة لخمسين من متدربي برامج تدريب الهيئة السعودية للتخصصات الصحية"])},
    "applyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الآن"])},
    "horizonBenefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الانتهاء من البرنامج"])},
    "horizonSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستجد نفسك قادرًا على"])},
    "horizonBenefit1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شرح الكفاءات والمهارات والسلوكيات اللازمة للقيادة على المستوى الفردي"])},
    "horizonBenefit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعرف على نقاط القوة والضعف ووضع خطة تحسين فردية"])},
    "horizonBenefit3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقارنة أساليب القيادة المختلفة والأدوارالمستخدمة لقيادة القرق الصحية"])},
    "horizonBenefit4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ممارسة مهارات التواصل الفعال وأساليب زيادة التأثير القيادي"])},
    "horizonBenefit5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق مناهج التفكير المنظومي في الرعاية الصحية"])},
    "horizonBenefit6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيف أساسيات جودة الرعاية الصحية ومبادئ سلامة المرضى"])},
    "horizonBenefit7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترجمة المعرفة للرفع من المسؤولية وتحسين الرعاية المتمحورة حول المريض"])},
    "horizonBenefit8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأسيس استراتيجية للمحافظة على عافية الممارس ونموه المستمر"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
    "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتيب البرنامج"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الملف"])},
    "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الملف"])},
    "uploadDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الرفع"])},
    "journey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رحلة البرنامج"])},
    "journeyHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" رحلة تدريب داعمة لخمسين من متدربي برامج تدريب الهيئة السعودية للتخصصات الصحية، لتمكين المتدربين في برامج الإختصاص والزمالة السعودية"])},
    "lecture1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التواصل الفعال"])},
    "lecture1Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند نهاية الندوة، سيتمكن المشاركون من:\nفهم مناهج التواصل النظرية الأساسية.\nإظهار كفاءة متزايدة من خلال شرح مهارات التواصل الفعال وتطبيقها في سياقات متنوعة.\nبناء استراتيجات تواصل ملائمة للنجاح الشخصي والمهني."])},
    "lecture1Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 ديسمبر 2021"])},
    "lecture1Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 8:00 مساءً"])},
    "lecture2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإهتمام بالآخرين"])},
    "lecture2Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند نهاية الندوة، سيتمكن المشاركون من:\nفهم النظريات وأفضل الممارسات لبناء الفريق في القطاع الصحي.\nمناقشة دور الفرق متعددة التخصصات في توفير رعاية آمنة وموثوقة.\nإظهار الكفاءة في تحقيق أداء عالي عند قادة الصف الأول في القطاع الصحي.\n\n"])},
    "lecture2Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 يناير 2022"])},
    "lecture2Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 8:00 مساءً"])},
    "lecture3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجسيد القيم"])},
    "lecture3Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند نهاية الندوة، سيتمكن المشاركون من:\nفهم النظريات وأفضل الممارسات لبناء الفريق في القطاع الصحي.\nمناقشة دور الفرق متعددة التخصصات في توفير رعاية آمنة وموثوقة.\nإظهار الكفاءة في تحقيق أداء عالي عند قادة الصف الأول في القطاع الصحي.\n\n"])},
    "lecture3Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 فبراير 2022"])},
    "lecture3Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 8:00 مساءً"])},
    "lecture4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثقة والمرونة القيادية"])},
    "lecture4Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند نهاية الندوة، سيتمكن المشاركون من:\nتعريف الثقة في بيئة العمل ودورها في تقديم رعاية صحية فعالة.\nالتعرف على السلوكيات التي تسرع في بناء الثقة والمرونة.\nإظهار كفاءة في بناء الثقة والمرونة عند قادة الصف الأول في القطاع الصحي."])},
    "lecture4Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 مارس 2022"])},
    "lecture4Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً  8:00 مساءً"])},
    "lecture5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتخاذ القرار"])},
    "lecture5Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند نهاية الندوة، سيتمكن المشاركون من:\nفهم أساسيات اتخاذ القرارات.\nالتعرف على معوقات اتخاذ القرار المشترك من منظور المريض وموفر الرعاية الصحية.\n وصف الاستراتيجيات اللازمة لتطبيق اتخاذ القرارات في القطاع الصحي بنجاح."])},
    "lecture5Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 يوليو 2022"])},
    "lecture5Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً  8:00 مساءً"])},
    "lecture6Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحادثات الصعبة"])},
    "lecture6Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند نهاية الندوة، سيتمكن المشاركون من\nفهم العوامل التي تساهم في خلق المحادثات الصعبة.\nالتعرف على أثر المحادثات الصعبة على كل طرف مشارك فيها.\nتبني مجموعة من الاستراتيجات التي تساعدك في إدارة المحادثات الصعبة بفعالية."])},
    "lecture6Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18 يوليو 2022"])},
    "lecture6Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 8:00 مساءً"])},
    "lecture7Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور القيادي"])},
    "lecture7Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند نهاية الندوة، سيتمكن المشاركون من\nفهم ماذا يعني الحضور القيادي وكيفية تطويره.\nالتعرف على أثر الحضور القيادي على رعاية المرضى.\nإنشاء خطة تنفيذ لتطوير الحضور القيادي المهني."])},
    "lecture7Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18 يوليو 2022"])},
    "lecture7Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 8:00 مساءً"])},
    "lecture8Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الذكاء العاطفي"])},
    "lecture8Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند نهاية الندوة، سيتمكن المشاركون من\nفهم الذكاء العاطفي والعلم وراءه.\nالتعرف على دور الذكاء العاطفي في خلق علاقات مهنية وفرق فاعلة.\nتبني مهارات وسلوكيات الذكاء العاطفي التي تعزز من دورك كقائد."])},
    "lecture8Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14 سبتمبر 2022"])},
    "lecture8Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 8:00 مساءً"])},
    "ChiefResidentLeadershipCircles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات القيادة الشهرية للمتدربين"])},
    "ChiefContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حلقات نقاش مُيسَّرة كل شهرين تُقدم لكبار المقيمين."])},
    "lectureChief1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعاون عبر النظام"])},
    "lectureChief1Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمناقشة الشبكات وفوائد التعاون للأفراد والمؤسسات."])},
    "lectureChief1Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2021 ديسمبر 25"])},
    "lectureChief1Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 9:00 مساءً"])},
    "lectureChief2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رعاية الآخرين"])},
    "lectureChief2Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمناقشة كيف يمكن للقادة الرعاية الصحية إنشاء بيئات مهنية رعاية , وتحسين مشاركة الموظفين وتعزيز الفرح في العمل."])},
    "lectureChief2Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 يناير 24"])},
    "lectureChief2Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 9:00 مساءً"])},
    "lectureChief3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيادة من أجل القيمة"])},
    "lectureChief3Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمناقشة القيادة القائمة على القيمة وفهم كيف يمكن للقادة إنشاء التوافق بين قيمهم الشخصية والقيم التنظيمية."])},
    "lectureChief3Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 مارس 13"])},
    "lectureChief3Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 9:00 مساءً"])},
    "lectureChief4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأولوية للمرضى"])},
    "lectureChief4Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمناقشة الرعاية التي تتمحور حول المريض وتطبيقاتها العملية على مستويات القيادة المختلفة في أنظمة الرعاية الصحية."])},
    "lectureChief4Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 مايو 25"])},
    "lectureChief4Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 9:00 مساءً"])},
    "lectureChief5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور القيادي"])},
    "lectureChief5Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمناقشة نماذج الأدوار واستكشاف الاستراتيجيات التي يمكن للقادة تبنيها لزيادة تأثيرهم على الآخرين."])},
    "lectureChief5Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7:00 مساءً - 9:00 مساء"])},
    "lectureChief5Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 يوليو 20"])},
    "flow1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندوات افتراضية"])},
    "flow2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ورش العمل"])},
    "flow3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم المهارات"])},
    "flow4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاريع ومهام القيادة"])},
    "flow5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جلسات توجيهية"])},
    "flow6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحفل الختامي"])}
  },
  "Hybrid:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
  "courseDetails": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول هذه الدورة"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
    "announcementMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات المرفقة"])},
    "prerequisite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتطلبات السابقة"])},
    "whatToLearn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا ستتعلم"])},
    "courseContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوى الدورة"])},
    "announcement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلانات"])},
    "meetYourInstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قابل مدرسك"])},
    "getStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البدء"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتطلبات المسبقة"])},
    "aboutCoach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن المدرب"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجاني"])},
    "issuedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إصداره في"])},
    "addNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف ملاحظة خاصة"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملحوظات"])},
    "writeNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب ملاحظتك هنا ..."])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظاتي"])},
    "editNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير مذكرة"])},
    "deleteNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الملاحظة"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يلغي"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
    "courseMyNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظاتي"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم"])},
    "lecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرة"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
    "entryFeeTaxAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوف يتم زيادة القيمة المضافة على الرسوم الدورة"])},
    "priceAfterTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر شامل 15٪ ضريبة القيمة المضافة."])}
  },
  "event": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن البرنامج"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدرب الرئيسي"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اونلاين"])},
    "onsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وجاهي"])},
    "entryFeeTaxAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوف يتم زيادة القيمة المضافة على الرسوم الفعالية"])}
  },
  "switchToCoachView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل الى واجهة المدرب"])},
  "organization": {
    "nameOfOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنظمة:"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسمى الوظيفي"])},
    "targetedLeaderShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستويات برامج تطوير القيادة التي تستهدفها"])},
    "emerging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيادات الواعدة"])},
    "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيادات الوسطى"])},
    "senior": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيادة العليا / التنفيذية"])},
    "programDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما مدة البرامج المناسبة لكم؟"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دورات قصيرة (للقيادات الواعدة 3-10 أيام)"])},
    "journeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رحلات تطوير القيادات (للقيادات الوسطى 3-6 أشهر)"])},
    "executive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج تطوير القيادات التنفيذية (للقيادات العليا / التنفيذية 9 أشهر)"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرامج التي تهتم بها"])},
    "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج تنتهي بشهادات مهنية معتمدة "])},
    "managerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج تطوير إداري"])},
    "CME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج معتمدة بساعات تعليم مستمر"])},
    "internationalOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامج مشتركة مع جهات عالمية"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديد الاحتياجات التدريبية"])},
    "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم القيادات الذاتي أو تقييم 360 درجة"])},
    "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوجيه القيادي"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبحاث في مجال القيادة الصحية"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخرى، يرجى ذكرها"])},
    "numberOfTrainees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعداد المتدربين المتوقعة:"])},
    "suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في حال لديك المزيد من الاقتراحات، يرجى إضافتها"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات التي ترغب بها"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال الطلب بنجاح"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم التواصل معك في أقرب وقت ممكن"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في الإرسال"])},
    "failedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ في الإرسال، يرجى المحاولة مرة أخرى"])}
  },
  "emailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني غير صحيح"])},
  "phoneError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف غير صحيح"])},
  "nameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم غير صحيح"])},
  "requiredError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل مطلوب"])},
  "transcript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النص"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
  "assignment": {
    "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المورد"])},
    "view_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المهمه"])},
    "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاستحقاق"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسحب الملف هنا أو انقر للتحميل"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجابة"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
    "assignmentSections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواجبات"])}
  },
  "noQuizzesSubmissionYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم تقديم اي امتحانات بعد"])},
  "assessment": {
    "rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم"])},
    "noAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد تقييم لهذا الطالب ."])}
  },
  "noAssignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مهام في هذا المساق"])}
}