import Layout from "@/layout/Layout.vue";
import {RouteRecordRaw} from "vue-router";
import studentRoutes from "./student.routes";
import mulhemRoutes from "@/router/mulhem.routes";
import mainRoutes from "@/router/main.routes";
import courseRoutes from "@/modules/course/src/router/course.route";
import profileRoutes from "@/router/profile.routes";
import coachRoutes from "@/router/coach.routes";

const main: Array<RouteRecordRaw> = [
    // Landing page
    {
        path: "/",
        name: "LandingPage",
        component: () => import("@/page/site/LandingPageV2.vue"),
        meta: {
            requiresAuth: false,
        },
    },
    // Auth Routes
    {
        path: "/",
        component: Layout,

        children: [
            {
                path: "/library",
                name: "LibraryV2",
                component: () => import("@/modules/shared/src/ui/Library.vue"),
                meta: {
                    requiresAuth: false,
                },
            },
            {
                path: "events",
                name: "new events",
                component: () => import("@/page/event/AllEvents.vue"),
                meta: {
                    requiresAuth: false,
                },
            },

            {
                path: "/event/:id",
                name: "Event Details",
                component: () => import("@/page/event/Event.vue"),
                props: true,
                meta: {
                    requiresAuth: false,
                },
            },
            {
                path: "/calendar",
                name: "Calendar",
                component: () => import("@/page/site/CoacheeCalendar.vue"),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/create-group",
                name: "CreateGroup",
                component: () => import("../component/groups/CreateGroup.vue"),
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },

    {
        path: "/site",
        name: "landing page",
        component: () => import("@/layout/LandingPageAppV2.vue"),
        meta: {
            requiresAuth: false,
        },
        children: [
            {
                path: "leadership-development",
                name: "leadership development program",
                component: () => import("@/page/site/HorizonsProgram.vue"),
            },
            {
                path: "leadership-circles",
                name: "leadershipCircles",
                component: () => import("@/page/site/LeadershipCircles.vue"),
            },
            {
                path: "organization-solutions",
                name: "OrganizationSolutions",
                component: () =>
                    import("@/page/site/OrganizationSolutions.vue"),
            },
            {
                path: "about",
                name: "About",
                component: () => import("@/modules/shared/src/ui/AboutUsPage.vue"),
            },
            {
                path: "contact-us",
                name: "ContactUs",
                component: () => import("@/modules/shared/src/ui/ContactUs.vue"),
            },
            {
                path: "feedback",
                name: "User Feedback",
                component: () => import("@/page/site/Feedback.vue"),
            },
            {
                path: "privacy-terms",
                name: "Privacy",
                component: () => import("@/modules/shared/src/ui/Terms.vue"),
            },
            {
                path: "how-to",
                name: "HowTo",
                component: () => import("@/modules/how-to/pages/HowTo.vue"),
            },
            {
                path: "how-to/:id",
                name: "HowToPage",
                component: () => import("@/modules/how-to/pages/HowToPage.vue"),
            },
        ],
    },

    {
        path: "/login",
        name: "Login Page",
        component: () => import("@/page/auth/Login.vue"),
    },
    {
        path: "/forget-password",
        name: "ForgetPassword",
        component: () => import("@/page/auth/ForgetPassword.vue"),
    },
    {
        path: "/register",
        name: "Register Page",
        component: () => import("@/page/auth/Register.vue"),
    },
    {
        path: "/register-instructor",
        name: "Register-Instructor Page",
        component: () => import("@/page/auth/RegisterInstructor.vue"),
    },
    {
        path: "/register-coach",
        name: "Coach Register",
        component: () => import("@/page/coach/RegisterCoach.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("../modules/shared/src/ui/NotFound.vue"),
    },
];

let allRoutes: Array<RouteRecordRaw> = [];
allRoutes = allRoutes.concat(
    studentRoutes,
    mulhemRoutes,
    mainRoutes,
    courseRoutes,
    mulhemRoutes,
    mainRoutes,
    profileRoutes,
    coachRoutes,
    main
);
const routes = allRoutes;

export default routes;
