'use strict';
import axios from "@/axios";
import AddQuizQuestionInterface from '@/interfaces/quiz.question.instructor.interface';

export default {

    getQuestionTopic: async function (context: any) {
        const response = await axios.get(`/question-topics`);
    
        context.commit("setQuestionTopic", response.data);
      },
      createQuestionTopic: async function (context: any, payload: any) {
        await axios.post(`/question-topics`, payload);
      },
    
      

    }

