import axios from "@/axios";
export default {
  getAllQuizes: async function (context: any) {
    const response = await axios.get("/quizes?offset=0&limit=12");
    context.commit("setQuizez", response.data);
  },

  getAll: async function (context: any) {
    const response = await axios.get("/quizes");
    context.commit("setQuizez", response.data);
  },
  AddExamToSupSection: async function (context: any, payload: any) {
    const body = payload.data;
    console.log(body.isOmniDirectional)

    await axios.post(`/quizes/${payload.id}/modules`, body);
  },
  EditExamToSupSection: async function (context: any, payload: any) {
    const body = payload.data;
    await axios.patch(`/quizes/${payload.id}/modules`, body);
  },
  getQuiz: async function (context: any, id: number) {
    const response = await axios.get(`/quizes/${id}`);
    context.commit("setQuiz", response.data);
  },
  updateExamTextGrade: async function (context: any, payload: any) {
    const response = await axios.post(`/quizes/${payload.id}/grade`,payload.body);
    context.commit("setQuiz", response.data);
  },


  createQuiz: async function (context: any, payload: any) {
    const response = await axios.post("/quizes", payload);
    context.commit("setQuiz", response.data);
    return response;
  },
  editQuiz: async function (context: any, payload: any) {
    const response = await axios.patch(`/quizes/${payload.id}`, payload.body);
    await context.commit("setQuiz", response.data);
    await context.dispatch("getQuiz", payload.id);

    return response;
  },
  addTopicQuiz: async function (context: any, payload: any) {
    const response = await axios.post("/quizes", payload);
    context.commit("setQuiz", response.data);
    return response;
  },
  updateTopicQuiz: async function (context: any, payload: any) {
    const response = await axios.post(
      `/quizes/${payload.id}/topics`,
      payload.body
    );
    context.dispatch("getQuiz", payload.id);
  },
  editTopicQuiz: async function (context: any, payload: any) {
    const response = await axios.patch(
      `/quizes/${payload.id}/topics/${payload.body.topicId}`,
      payload.body
    );
    context.dispatch("getQuiz", payload.id);
  },
  editQuestionQuiz: async function (context: any, payload: any) {
    const response = await axios.patch(
      `/quizes/${payload.id}/questions/${payload.questionId}`,
      payload.body
    );
    context.dispatch("getQuiz", payload.id);
  },
  deleteTopicQuiz: async function (context: any, payload: any) {
    await axios.delete(`/quizes/${payload.id}/topics/${payload.topicId}`);
    context.dispatch("getQuiz", payload.id);
  },
  deleteQuestionQuiz: async function (context: any, payload: any) {
    await axios.delete(`/quizes/${payload.id}/questions/${payload.questionId}`);
    context.dispatch("getQuiz", payload.id);
  },
  deleteQuiz: async function (context: any, id: number) {
    await axios.delete(`/quizes/${id}`);
    context.commit("setQuiz", null);
    context.dispatch("getAllQuizes");
  },
  createQuizQuestion: async function (context: any, payload: any) {
    const response = await axios.post(
      `/quizes/${payload.id}/questions`,
      payload.body
    );
    context.dispatch("getQuiz", payload.id);
  },
  connectQuizToModule: async function (
    context: any,
    payload: {
      quizId: number;
      subsectionId: 1;
      attemptLimit: 1;
      questionLimit: 1;
    }
  ) {
    const body = {
      subsectionId: payload.subsectionId,
      attemptLimit: payload.attemptLimit,
      questionLimit: payload.questionLimit,
    };
    return await axios.post(`/quizes/${payload.quizId}/modules`, body);
  },
  addQuizTopic: async function (
    context: any,
    payload: { quizId: number; topicId: number }
  ) {
    const body = {
      topicId: payload.topicId,
    };
    return await axios.post(`/quizes/${payload.quizId}/topics`, body);
  },

  startQuiz: async function (context: any, payload: any) {
    return await axios.post(`/quizes/${payload.quizId}/start`, payload.body);
  },
  GetExam: async function (context: any, payload: any) {
    const id = payload.body.subsectionId;
    const res = await axios.get(
      `/quizes/${payload.quizId}/start?subsectionId=${id}`
    );
    context.commit("GetExam", res.data);
  },

  SubmitQuizes: async function (context: any, payload: any) {
    const quizesId = payload.quizesId;
    const body = payload.body;
    return await axios.post(`/quizes/${quizesId}/submit`, body);
  },

  SubmitGrade: async function (context: any, payload: any) {
    const quizesId = payload.quizesId;
    const body = payload.body;
    const gradeData = await axios.get(
      `/quizes/${quizesId}/grade?subsectionId=${body.subsectionId}`
    );

    context.commit("setFullGrade", gradeData.data.fullGrade);
    context.commit("setNoAttempt", gradeData.data.attempt);
    context.commit("setMyGrade", gradeData.data.quizGrade);
    context.commit("setReviewQuestion", gradeData.data.questions);
    return gradeData;
  },

  studentGrade: async function (context: any, payload: any) {
    const quizesId = payload.quizesId;
    const body = payload.body;
    console.log(payload,'payload');
    const gradeData = await axios.get(
      `/quizes/${quizesId}/grade/instructor?subsectionId=${body.subsectionId}&&userId=${`${body.userId}`}`
    );


    context.commit("setFullGrade", gradeData.data.fullGrade);
    context.commit("setNoAttempt", gradeData.data.attempt);
    context.commit("setMyGrade", gradeData.data.quizGrade);
    context.commit("setReviewQuestion", gradeData.data.questions);
    return gradeData;
  },



  SubmitGradeInstructor: async function (context: any, payload: any) {

    const body = payload;
    console.log(body);
    context.commit("setFullGrade", body.fullGrade);
    context.commit("setNoAttempt", body.attempt);
    context.commit("setMyGrade", body.quizGrade);
    context.commit("setReviewQuestion", body.questions);


    return body;
  },



  //// Question Actions


  getAllQuizesAttempt: async function (context: any, id: number) {
    const response = await axios.get(`/quizes/all`);
    context.commit("setAllQuizesAttempt", response.data);
  },


  getAllQuizesInstructor: async function (context: any, id: number) {
    const response = await axios.get(`/quizes/all/instructor?courseId=${id}`);
    context.commit("setAllQuizesInstructor", response.data);
  },
  getQuizInstructor: async function (context: any, payload: any) {
    const response = await axios.get(`/quizes/${payload.quizesId}/instructor?subsectionId=${payload.id}`);
    context.commit("setQuizesInstructor", response.data);
  },




};
