import { RouteRecordRaw } from "vue-router";
const mainRoutes: Array<RouteRecordRaw> = [
  {
    path: "/groups",
    name: "Main",
    component: () => import("@/layout/Main.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "Main User List",
        path: "index",
        component: () => import("@/page/groups/MainUser.vue"),
      },

      {
        name: "Main Group View",
        path: "group/:id",
        component: () => import("@/page/groups/Group.vue"),
        children: [
          {
            name: "Posts Group",
            path: "",
            component: () => import("@/page/groups/GroupPosts.vue"),
          },
          {
            name: "About Group",
            path: "about",
            component: () => import("@/page/groups/AboutGroup.vue"),
          },
          {
            name: "Group Events",
            path: "events",
            component: () => import("@/page/groups/GroupEvents.vue"),
          },
          {
            name: "Create New Event",
            path: "create-event",
            component: () => import("@/page/groups/CreateEvent.vue"),
          },
        ],
      },
      {
        path: "",
        name: "All Groups",
        component: () => import("@/page/groups/AllGroup.vue"),
        meta: {
          requiresAuth: true,
        },
      },

    ],
  },
];
export default mainRoutes;
