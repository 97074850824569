<template>
  <div class="form">
    <div class="form__main">
      <input
        class="form__normalInput"
        :class="[
          !fileName
            ? 'form__normalInput'
            : 'form__normalInput form__normalInput-afterADD',
        ]"
        id="normal"
        type="text"
        name="normalInput"
        ref="normalInput"
        placeholder="Enter your message.."
        v-model.trim="msg"
        @keyup.enter="submitMessage"
        :disabled="false"
      />
      <label class="form__normalLabel" for="normal" v-if="fileName">
        <span class="form__normalLabel__btn" @click="cancleUpload"> x </span>
      </label>

      <input
        class="form__uploadInput"
        id="file-upload"
        type="file"
        name="uploadInput"
        placeholder=""
        value=""
        ref="uploadInput"
        @change="upload"
      />
      <label class="form__uploadLabel" for="file-upload" v-if="!fileName">
        <svg
          class="form__svg"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#404040"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"
          />
        </svg>
      </label>
    </div>
    <div @click="submitMessage" class="form__submit">
      <button class="form__submit-btn">Send</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: "",
      type: "",
      buffer: [],
      typing: false,
      name: localStorage.getItem("name"),
      fileName: "",
      date: null,
    };
  },
  methods: {
    submitMessage() {
      if (this.msg || this.buffer || this.type) {
        this.$emit("submitMessage", {
          msg: this.msg,
          buffer: this.buffer,
          type: this.type,
        });
        this.msg = "";
        this.buffer = "";
        this.type = "";
        this.fileName = "";
        this.$refs.normalInput.disabled = false;
        this.$refs.normalInput.value = null;
        this.$refs.uploadInput.value = null;
      }
      return;
    },

    cancleUpload() {
      this.$refs.uploadInput.value = null;
      this.$refs.normalInput.value = null;
      this.msg = "";
      this.fileName = "";
      this.$refs.normalInput.disabled = false;
    },
    async upload(event) {
      this.$refs.normalInput.value = event.target.files[0]?.name;
      this.fileName = this.msg;
      this.$refs.normalInput.disabled = true;

      var reader = new FileReader();
      await reader.readAsArrayBuffer(event.target.files[0]);
      reader.onload = async () => {
        var arrayBuffer = await reader.result;
        var bytes = await new Uint8Array(arrayBuffer);

        this.buffer = await bytes;
      };

      this.type = event.target.files[0].type;
    },
  },
  watch: {
    msg(value) {
      if (value.length && !this.typing) {
        this.$socket.emit("typing", { name: this.name, isTyping: true });
        this.typing = true;
      } else if (value.length === 0) {
        this.$socket.emit("typing", { name: this.name, isTyping: false });
        this.typing = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  border-radius: 30px 30px 24px 24px;
  display: flex;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);

  &__main {
    display: flex;
    align-items: center;
    outline: none !important;
    padding: 8px 0 8px 16px;
    font-size: 16px;
    width: 80%;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    position: relative;
  }

  &__normalInput {
    display: block;
    border: none;
    width: 96%;
    padding: 7px;
    --progress: 0%;
    border-bottom: 5px solid;
    border-image: linear-gradient(
        90deg,
        #1f2f49 var(--progress),
        white 0%,
        white 100%
      )
      1;

    @media screen and (max-width: 400px) {
      font-size: 10px;
      width: initial;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &-afterADD {
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #45464b;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #45464b;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #45464b;
      }

      animation: pulsate 2s;
    }
  }

  @keyframes pulsate {
    0% {
      --progress: 50%;
    }
    50% {
      --progress: 70%;
    }
    100% {
      --progress: 100%;
    }
  }

  &__normalLabel {
    &__btn {
      position: absolute;
      top: 16px;
      right: 13px;
      padding-right: 13px;
      transition: 0.3s;
      font-size: 20px;

      &:hover {
        color: #999999;
      }
    }
  }

  &__svg {
    transition: 0.3s;

    &:hover {
      fill: #999999;
    }
  }

  &__uploadLabel {
    position: absolute;
    top: 7px;
    right: 13px;
    padding: 8px 0;
    cursor: pointer;
    color: #404040;
    font-weight: bold;
  }

  &__uploadInput {
    display: none;
  }

  &__submit {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 15.2%;
    margin-left: auto;

    @media screen and (max-width: 400px) {
      margin: initial;
      width: initial;
    }

    &-btn {
      width: 100%;
      height: 100%;
      background: #1f2f49;
      border-radius: 3px;
      color: white;

      @media screen and (max-width: 400px) {
        margin-left: 8px;
      }
    }
  }
}
</style>
