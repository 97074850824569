import {CourseInterface} from "@/interfaces/course.interface";
import {Section, FullSection, Subsection, VideoTranscript} from "@/interfaces/course-section.interface";
import FullCalendar from "@fullcalendar/vue3";

export default {
    course(state: any): CourseInterface | null {
        return state.course;
    },
    modulesWithSubmodules(state: any): Section[] | null | FullSection {
        return state.courseModules;
    },
    courseModulesWithDiscussionBoardEnabled(state: any): Section[] | null {
        console.log(state.courseModules, 'state.courseModules');
        const modules: Section[] | null = [];
        ((state?.courseModules) as FullSection)?.sections?.map((module: Section) => {
            if (module?.isDiscussionBoardEnabled) {
                modules.push(module);
            }
        });
        return modules;
    },
    activeSubsection(state: any): Subsection | null {
        return state.activeSubsection;
    },
    activeSection(state: any): Section | null {
        return state.activeSection;
    },
    courseSubsectionsSorted(state: any): number[] {
        return state?.courseSubsectionsSorted;
    },
    isLastSubsection(state: any): boolean {
        //check if last subsection in course is active
        const lastSubsection: number = state.courseSubsectionsSorted.length > 0 ? state?.courseSubsectionsSorted[state.courseSubsectionsSorted.length - 1] : 0;
        if (lastSubsection === 0) {
            return true;
        }
        return lastSubsection === state?.activeSubsection?.id;
    },
    isFirstSubsection(state: any): boolean {
        //check if first subsection in course is active
        const firstSubsection: number = state.courseSubsectionsSorted.length > 0 ? state?.courseSubsectionsSorted[0] : 0;
        if (firstSubsection === 0) {
            return true;
        }
        return firstSubsection === state?.activeSubsection?.id && firstSubsection !== 0;
    },
    transcriptArray(state: any): VideoTranscript[] {
        return state?.activeSubsection?.file?.videoTranscripts
    },
    isHaveTranscript(state: any): boolean {
        return state?.activeSubsection?.file?.videoTranscripts?.length > 0
    },
    mediaTranscript(state: any): VideoTranscript | null {
        return state?.activeSubsection?.file?.videoTranscripts[0]?.srtFile?.url;
    }
};
