import axios from "@/axios";
import { Event } from "@/interfaces/event.interface";

export default {
  setEventData(state: any, payload: Event) {
    state.event = payload;
  },
  addHostInGroup(state: any, payload: any) {
    axios.post(`events/${payload.eventId}/hosts/${payload.item}`, {});
  },

  removeHostFromGroup(state: any, payload: any) {
    axios.delete(`events/${payload.eventId}/hosts/${payload.item}`, {});
  },
};
