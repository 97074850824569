<template>
  <div>
    <div
      v-if="msg.user.userRole === 'CUSTOMER'"
      class="chat__mymessage"
      :class="[isSame ? '' : 'chat__first']"
    >
      <!-- <p class="chat__yourmessage__time">23:38</p> -->
      <div>
        <img v-if="msg.content"
        class="chat__mymessage__img"
        :src="`${link}${msg.content}`"
        width="200"
        height="150"
        alt=""
      />

          <p v-if="msg.text && msg.type !== 'redirect'" class="chat__mymessage__paragraph">
        {{ msg.text }}
      </p>
        </div>
        
      
    </div>
    <div
      v-else-if = "msg.type !== 'redirect'"
      class="chat__yourmessage"
      :class="[isSame ? '' : 'chat__first']"
    >
      <div v-if="msg.user.userRole === 'AGENT' && msg.type !== 'redirect'" class="chat__yourmessage__avartar">
        <img
          :src="avatar"
          alt=""
          v-if="msg.user.userRole === 'AGENT'"
          class="chat__yourmessage__img"
        />
      </div>
      <div>
        <p class="chat__yourmessage__user" v-if="!isSame">
          {{ msg.user.name }}
        </p>
        <div class="chat__yourmessage__p">
          <div v-if="msg.type === 'notification'">
        <p v-if="msg.text" class="chat__yourmessage__paragraph">
        {{ msg.text }} :
        <span style="color : blue">
        {{msg.ticketReferenceNumber}}
      </span>
      </p>
      </div>
      <div v-else-if ="msg.type !== 'redirect'" >
        <p v-if="msg?.text " class="chat__yourmessage__paragraph">
            {{ msg.text }}
          </p>
          <img
            v-else-if ="msg.type && msg.type.split('/')[0] === 'image'"
            class="chat__yourmessage__paragraph"
            :src="`${link}${msg.content}`"
            width="200"
        height="150"
            alt=""
          />

          <a v-else class="mt-3" :href="`${link}${msg.content}`" download= "download" target="_blank">
              <p>file</p>
</a>
      </div>
          
          <!-- <p class="chat__yourmessage__time">23:38</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["msg", "prev", "userId"],
  data() {
    return {
      isSame: false,
      avatar: require("@/assets/svgs/avatar.svg"),
      link: process.env.VUE_APP_SOCKET_URL,
    };
  },
  methods: {
    isSamePerson(msg, prev) {
      if (prev === null) {
        return false;
      } else if (prev[0]?.user.name == msg?.user.name) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.isSame = this.isSamePerson(this.msg, this.prev);
    // if (this.msg?.from.avatar) {
    //   this.avatar = this.msg?.from.avatar;
    // }
  },
};
</script>

<style>
.chat__mymessage {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  margin: 0;
  min-height: 40px;
  line-break: anywhere;
}

.chat__mymessage__paragraph {
  margin: 0.8rem 0 0 1.4rem;
    border-radius: 20px 20px 0px 20px;
    max-width: 180px;
    background-color: #1f2f49;
    color: #ffffff;
    padding: 1.2rem;
    font-size: 14px;
}

.chat__mymessage__img{
  margin: 0.8rem 0 0 1.4rem;
  border-radius: 25px 25px 25px 25px;
  color: #ffffff;
  padding: 1.2rem 0;
  font-size: 14px;
}

.chat__first {
  margin-top: 1.4rem;
}

.chat__yourmessage {
  display: flex;
}

.chat__yourmessage__avartar {
  width: 40px;
  margin-right: 1.4rem;
}

.chat__yourmessage__img {
  margin: 0.8rem 0 0 1.4rem;
  border-radius: 25px 25px 25px 25px;
  color: #ffffff;
  padding: 1.2rem 0;
  font-size: 14px;
}

.chat__yourmessage__user {
  font-size: 14px;
  font-weight: 700;
  color: #292929;
  margin-top: 0;
  margin-block-end: 0rem;
}

.chat__yourmessage__p {
  display: flex;
  align-items: flex-end;
  line-break: anywhere;
  margin-left: 5px;
}

.chat__yourmessage__paragraph {
  margin: 0.8rem 1.4rem 0 0;
  border-radius: 0px 20px 20px 20px;
  background-color: #f6f7fb;
    color: #45464b;
  max-width: 180px;
  padding: 1.2rem;
  font-size: 14px;
}

.chat__yourmessage__time {
  margin: 0;
  font-size: 12px;
  color: #9c9c9c;
}
</style>
