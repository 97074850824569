import { createStore } from "vuex";
import { auth } from "./modules/auth";
import { group } from "./modules/group";
import support from "./modules/support";
import course from "./modules/course";
import survey from "./modules/survey";
import header from "./modules/header";
import chatSupport from "./modules/chat.support";
import howTo from "./modules/howto";
import profile from "./modules/profile/index";
import event from "@/store/modules/event";
import { calendar } from "./modules/calendar";
import { coaching } from "./modules/coaching/coaching";
import { notification } from "./modules/notification";
import { library } from "./modules/library";
import authentication from "./modules/authentication";
import groups from "@/store/modules/groups";
import courseSubsection from "@/store/modules/course-subsection";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import quiz from "./modules/quiz";
import  quizAnswer from "./modules/quiz/quiz-answer-student";
import  quizAnswerInstructor from "./modules/quiz/quiz-answer-instructor";
import  quizQuestionInstructor from "./modules/quiz/quiz-question-instructor";
import questionTopic from "./modules/quiz/quiz-topic-instructor";
import rubric from "./modules/rubric";
import grade from "./modules/grade";
import assignment from "@/store/modules/assignment";
import courseNew from "@/store/modules/course-new";
import transcript from "@/store/modules/transcript";
import desction from "./modules/desction";
import studentsAttendance  from "./modules/students-attendance";
const ls = new SecureLS({ isCompression: false });

export default createStore({
  state() {
    return {
      locale: "en",
      user: {
        userName: "Fahed td.",
      },
    };
  },
  mutations: {
    CHANGE_LOCALE(state: any, payload: any) {
      state.locale = payload.lang;
      payload.i18n.locale = payload.lang;
      location.reload();
    },
  },
  actions: {
    changeLocale(context: any, payload: any) {
      context.commit("CHANGE_LOCALE", payload);
    },
  },
  getters: {
    appLocale(state: any) {
      return state.locale;
    },
    GetUserInfo(state: any) {
      return state.user;
    },
  },
  modules: {
    groups,
    auth,
    group,
    calendar,
    coaching,
    support,
    course,
    notification,
    authentication,
    library,
    survey,
    chatSupport,
    howTo,
    profile,
    header,
    quiz,
    event,
    courseSubsection,
    assignment,
    quizAnswer,
    quizAnswerInstructor,
    quizQuestionInstructor,
    questionTopic,
    rubric,
    courseNew,
    grade,
    transcript,
    desction,
    studentsAttendance
  },
  plugins: [
    createPersistedState({
      paths: ["locale", "auth"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
