export default {

    setActiveTranscript(state: any, currentTime: number) {
        state.activeTranscript = currentTime;
    },
    runTranscript(state: any, value: boolean) {
        state.runTranscript = value;
    },
    runTranscriptAudio(state: any, value: boolean) {
        state.runTranscriptAudio = value;
    }
}
