import axios from "@/axios";
export default {
    getSection: async function (context: any, payload:any) {
        const response = await axios.get(
          `/courses/${payload.courseId}/enabled-discussion-boards`,
        );
        context.commit("setSection", response.data);
      },
    getDesction: async function (context: any, payload:any) {
        const response = await axios.get(
          `/course-sections/${payload.sectionId}/discussion-boards/statistics`,
        );
        context.commit("setDesction", response.data);
      },

}