import axios from "@/axios";
import {
  RubricAssessment,
  RubricAssessmentResponse,
  UploadedMedia,
} from "@/interfaces/assessment.interface";

export default {
  addCriteria(state: any, payload: any) {
    state.criteria.push(payload);
  },
  setTitle(state: any, payload: any) {
    state.title = payload;
  },
  findRow(state: any, payload: any) {
    const index = state.criteria.findIndex((item: any) => item == payload);
    state.indexColumn = index;
  },
  findColumn(state: any, payload: any) {
    console.log("iam in find column");

    if (state.indexColumn != -1) {
      const index = state.criteria[state.indexColumn].ratings.findIndex(
        (item: any) => item.grade == payload
      );
      state.indexRow = index;
    }
    console.log(state.indexRow);
  },
  sortColumn(state: any) {
    state.criteria[state.indexColumn].ratings.sort((a: any, b: any) => {
      return b.grade - a.grade;
    });
  },

  editCriteria(state: any, payload: any) {
    state.criteria[state.indexColumn].description = payload.description;
    state.criteria[state.indexColumn].longDescription = payload.longDescription;
  },

  setRubric(state: any, payload: any) {
    state.rubric = payload;
  },
  removeCriteria(state: any) {
    state.criteria.splice(state.indexColumn, 1);
  },
  addRating(state: any, payload: any) {
    if (state.indexRow != -1) {
      state.errorInRating = true;
    } else {
      state.criteria[state.indexColumn].ratings.push(payload);
      let max = 0;
      state.criteria[state.indexColumn].ratings.map((item: any) => {
        if (item.grade > max) {
          max = item.grade;
        }
      });
      state.criteria[state.indexColumn].maxGrade = max;
      state.errorInRating = false;
    }
  },
  removeRating(state: any, payload: any) {
    const index = state.criteria[state.indexColumn].ratings.findIndex(
      (item: any) => item == payload
    );
    if (
      state.criteria[state.indexColumn].maxGrade ==
      state.criteria[state.indexColumn].ratings[index].grade
    ) {
      state.criteria[state.indexColumn].ratings.splice(index, 1);

      const maxGrade = Math.max(
        ...state.criteria[state.indexColumn].ratings.map(
          (item: any) => item.grade
        )
      );
      state.criteria[state.indexColumn].maxGrade = maxGrade;
    } else {
      state.criteria[state.indexColumn].ratings.splice(index, 1);
    }
  },
  removeError(state: any) {
    state.errorInRating = false;
  },
  editRating(state: any, payload: any) {
    const index = state.criteria[state.indexColumn].ratings.findIndex(
      (item: any) => item == payload.rating
    );

    if (state.indexRow != -1) {
      if (
        state.criteria[state.indexColumn].ratings[index].grade ==
        payload.newRating.grade
      ) {
        state.errorInRating = false;
        state.criteria[state.indexColumn].ratings[index] = payload.newRating;
        let max = 0;
        state.criteria[state.indexColumn].ratings.map((item: any) => {
          if (item.grade > max) {
            max = item.grade;
          }
        });
        state.criteria[state.indexColumn].maxGrade = max;
      } else {
        state.errorInRating = true;
      }
    } else {
      state.errorInRating = false;

      state.criteria[state.indexColumn].ratings[index] = payload.newRating;
      let max = 0;
      state.criteria[state.indexColumn].ratings.map((item: any) => {
        if (item.grade > max) {
          max = item.grade;
        }
      });
      state.criteria[state.indexColumn].maxGrade = max;
    }
  },
  async createRubric(state: any, payload: any) {
    const rubric = {
      title: state.title,
      criteria: state.criteria,
    };
    const response = await axios.post(`/courses/${payload}/rubrics`, rubric);
  },
  setRubricData(state: any, payload: any) {
    state.title = payload.data.title;

    const newCriteria = payload.data.criteria.map((item: any) => {
      return {
        description: item.description,
        longDescription: item.longDescription,
        maxGrade: item.maxGrade,
        usesRange: item.usesRange,
        ratings: item.ratings.map((rating: any) => {
          return {
            grade: rating.grade,
            title: rating.title,
            description: rating.description,
          };
        }),
      };
    });
    state.criteria = newCriteria;
  },
  clearRubric(state: any) {
    state.criteria = [
      {
        description: " Criteria 1",
        longDescription: "",
        maxGrade: 10,
        usesRange: false,
        ratings: [
          {
            grade: 10,
            title: "Full Mark",
            description: "",
          },
          {
            grade: 0,
            title: "No Mark",
            description: "",
          },
        ],
      },
    ];
    state.title = "";
  },
  setStudentAssessment(state: any, payload: RubricAssessmentResponse | null) {
    state.studentAssessment = payload;
  },
  setAssessmentRubric(state: any, payload: RubricAssessment | null) {
    state.studentRubricAssessment = payload;
    if (state.studentRubricAssessment.criteria[0]?.selectedRating) {
      state.selectedRatings = [];
      state.studentRubricAssessment.criteria.map((criteria: any) => {
        criteria.ratings.map((rating: any) => {
          if (rating.id == criteria.selectedRating) {
            state.selectedRatings.push({
              criteriaId: rating.criterionId,
              ratingId: rating.id,
              feedback: criteria.feedback,
              grade: rating.grade,
            });
          }
        });
      });
    }
  },
  setActiveFile(state: any, payload: UploadedMedia | null) {
    state.activeFile = payload;
  },
  findCriteriaId(state: any, payload: any) {
    const index = state.selectedRatings.findIndex(
      (item: any) => item.criteriaId == payload
    );
    state.indexCriteriaId = index;
  },

  changeStudentRubricAssessment(state: any, payload: any) {
    if (state.indexCriteriaId === -1) {
      state.selectedRatings.push({
        criteriaId: payload.criteriaId,
        ratingId: payload.ratingId,
        feedback: payload.feedback,
        grade: payload.grade,
      });
    } else {
      state.selectedRatings[state.indexCriteriaId].ratingId = payload.ratingId;
      state.selectedRatings[state.indexCriteriaId].feedback = payload.feedback;
      state.selectedRatings[state.indexCriteriaId].grade = payload.grade;
    }
  },

  clearData(state: any) {
    state.selectedRatings = [];
    state.indexCriteriaId = -1;
    state.studentAssessment = null;
    state.studentRubricAssessment = null;
  },
};
