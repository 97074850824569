<template>
  <div @scroll="watchScorlling" class="chat__body" id="chat__body">
    <chat-message
      v-for="(msg, index) in messages"
      :key="index"
      :msg="msg"
      :prev="[index == 0 ? null : messages[index - 1]]"
      :next="[index == messages.length - 1 ? null : messages[index + 1]]"
      :userId="userId"
    >
    </chat-message>
    <p>{{ typingDisplay }}</p>
  </div>
</template>

<script>
import ChatMessage from "./ChatMessage.vue";

export default {
  components: {
    ChatMessage,
  },
  props: ["messages"],
  emits: ["loadMessage"],
  data() {
    return {
      userId: "",
      message: "",
      typingDisplay: "",
    };
  },

  created() {
    this.userId = +localStorage.getItem("id");
    this.$socket.on("typing", ({name, isTyping}) => {
      if (isTyping) {
        this.typingDisplay = `${name} is typing...`;
        setTimeout(() => {
          const element = document.getElementById("chat__body");
          element.scroll({ top: element.scrollHeight, behavior: "smooth" });
        }, 0);
      } else {
        this.typingDisplay = "";
      }
    });
  },

  methods: {
    watchScorlling() {
      let bottomOfWindow =
        document.getElementById("chat__body").scrollTop === 0;
      const height = document.getElementById("chat__body").scrollHeight;
      if (bottomOfWindow) {
        this.$emit("loadMessage", height);
      }
    },
  },
};
</script>

<style>
.chat__body {
  padding: 16px 24px;
  height: 100% !important;
  overflow: scroll;
  scroll-behavior: smooth;
}

.chat__body::-webkit-scrollbar {
  display: none;
}
</style>
