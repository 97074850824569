import axios from "@/axios";

export const group = {
    namespaced: true,
    state: {
        group: {},
        posts: [],
        postsOffset: 0,
        showLoadMorePosts: true
    },

    getters: {
        group(state: any) {
            return state.group;
        },

        cardAdmins(state: any) {
            if (state.group.members) {
                return state.group.members.filter((member: any) => member.groupRole === 'ADMIN');
            }
        },

        cardMembers(state: any) {
            if (state.group.members) {
                return state.group.members.filter((member: any) => member.groupRole === 'MEMBER');
            }
        },

        postsOffset(state: any) {
            return state.postsOffset
        },

        viewMorePosts(state: any) {
            return state.showLoadMorePosts;
        },

        posts(state: any) {
            return state.posts;
        },

        pinnedPosts(state: any) {
            return state.posts.filter((post: any) => post.isPinned).slice(0, 3);
        },

        upcomingEvents(state: any) {
            if (state.group.events) {
                return state.group.events;
            }
        }
    },

    mutations: {
        SET_GROUP(state: any, group: any) {
            state.group = group;
        },

        STORE_POST(state: any, post: any) {
            post.showComments = false
            post.showActions = false;
            post.comments = [];
            state.posts.unshift(post);
        },

        ADD_RECENT_ACTIVITY(state: any, posts: []) {
            state.posts = [...state.posts, ...posts];
        },

        ADD_POSTS(state: any, posts: []) {
            posts.forEach((post: any) => {
                post.showComments = false
                post.showActions = false;
                post.comments = [];
            });

            state.posts = [...state.posts, ...posts];

            if (posts.length) {
                state.showLoadMorePosts = true;
                state.postsOffset += 5;
            } else {
                state.showLoadMorePosts = false;
            }
        },

        CLEAR_POSTS(state: any) {
            state.posts = [];
            state.postsOffset = 0;
        },

        TOGGLE_SAVE(state: any, post: any) {
            state.posts.find((record: any) => record.id === post.id).isSaved = !post.isSaved;
        },

        DELETE_POST(state: any, post: any) {
            const recordIndex = state.posts.findIndex((record: any) => record.id === post.id);
            state.posts.splice(recordIndex, 1);
        },

        UPDATE_POST(state: any, post: any) {
            state.posts.find((record: any) => record.id === post.id).content = post.content;
        },

        TOGGLE_PINNED(state: any, post: any) {
            state.posts.find((record: any) => record.id === post.id).isPinned = !post.isPinned;

            state.posts.sort((a: any, b: any) => {
                return b.isPinned - a.isPinned;
            })
        },

        TOGGLE_LIKE(state: any, post: any) {
            const postRecord = state.posts.find((record: any) => record.id === post.id);
            postRecord.isLiked = !post.isLiked;
            postRecord.isLiked ? postRecord['_count'].likes++ : postRecord['_count'].likes--;
        },

        // will be ediiitt to fix number of likes
        TOGGLE_LIKE_COMMENT(state: any, comment: any) {
            const POstID = state.posts.findIndex((record: any) => record.id === comment.postId);
            const commentID = state.posts[POstID].comments.findIndex((record: any) => record.id === comment.id);
            // console.log(POstID)
            state.posts[POstID].comments[commentID].isLiked = !comment.isLiked;
            state.posts[POstID].comments[commentID].isLiked ? state.posts[POstID].comments[commentID]['_count'].likes++ : state.posts[POstID].comments[commentID]['_count'].likes--;

        },
        ADD_COMMENTS(state: any, payload: any) {
            payload.comments.forEach((comment: any) => {
                comment.showActions = false;
            });

            const postRecord = state.posts.find((record: any) => record.id === payload.post.id);

            postRecord.comments = [...postRecord.comments, ...payload.comments];
        },

        ADD_NEW_COMMENT(state: any, payload: any) {
            const postRecord = state.posts.find((record: any) => record.id === payload.post.id);
            postRecord.comments.unshift(payload.comment);
            postRecord['_count'].comments++;
        },

        RESET_COMMENTS(state: any, post: any) {
            const postRecord = state.posts.find((record: any) => record.id === post.id);

            if (postRecord) {
                postRecord.comments = [];
            }
        },

        UPDATE_COMMENT(state: any, comment: any) {
            const post = state.posts.find((record: any) => record.id === comment.postId);
            const commentIndex = post.comments.findIndex((record: any) => record.id === comment.id);
            post.comments.splice(commentIndex, 1, comment);
        },

        DELETE_COMMENT(state: any, payload: any) {
            const post = state.posts.find((record: any) => record.id === payload.post.id);
            const commentIndex = post.comments.findIndex((record: any) => record.id === payload.comment.id);
            post.comments.splice(commentIndex, 1);
            post['_count'].comments--;
        }
    },

    actions: {
        async getGroup(context: any, groupId: number) {
            const { data: group } = await axios.get(`groups/${groupId}`);
            context.commit('SET_GROUP', group);
        },

        async inviteMember(context: any, payload: any) {
            await axios.post(`groups/${payload.groupId}/invite-member`, {
                email: payload.email
            });
        },

        async loadRecentActivity(context: any, payload: any) {
            payload.groupsIds.forEach(async (groupId: any) => {
                const { data: posts } = await axios.get(`groups/${groupId}/posts?offset=0&limit=10`);
             
                posts.forEach(async (post: any) => {
                    post.groupId = groupId;
                    post.showComments = false
                    post.showActions = false;
                    post.comments = [];
                });
                if (posts.length == 0) {
                 //   window.location.assign("/upcoming-event")
                }
                context.commit('ADD_RECENT_ACTIVITY', posts);
            });
        },

        async loadPosts(context: any, payload: any) {
            const { data: posts } = await axios.get(`groups/${payload.groupId}/posts?offset=${payload.offset}&limit=10`);
            context.commit('ADD_POSTS', posts);
        },

        clearPosts(context: any) {
            context.commit('CLEAR_POSTS');
        },

        async storeNewPost(context: any, payload: any) {
            const { data: post } = await axios.post(`groups/${context.getters.group.id}/posts`,
                payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            );

            context.commit('STORE_POST', post);
        },

        async storeNewEventPost(context: any, payload: any) {

            const { data: post } = await axios.post(`groups/${payload.groupId}/posts`,
                payload.formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            );

            context.commit('STORE_POST', post);
        },


        async toggleSave(context: any, post: any) {
            const userId = context.rootGetters['auth/user'].id;

            await axios.post(`users/${userId}/saved-posts/${post.id}`, {
                save: !post.isSaved
            });

            context.commit('TOGGLE_SAVE', post);
        },

        async deletePost(context: any, post: any) {
            await axios.delete(`posts/${post.id}`);

            context.commit('DELETE_POST', post)
        },

        async updatePost(context: any, post: any) {
            await axios.patch(`posts/${post.id}`, {
                content: post.content
            });

            context.commit('UPDATE_POST', post);
        },

        async togglePin(context: any, payload: any) {
            await axios.post(`groups/${payload.groupId}/posts/${payload.post.id}`, {
                pin: !payload.post.isPinned
            });

            context.commit('TOGGLE_PINNED', payload.post);
        },

        async toggleLike(context: any, post: any) {
            const userId = context.rootGetters['auth/user'].id;

            await axios.post(`users/${userId}/likes/${post.id}`, {
                like: !post.isLiked
            });

            context.commit('TOGGLE_LIKE', post);
        },

        async toggleLikeComment(context: any, comment: any) {
            const userId = context.rootGetters['auth/user'].id;

            await axios.post(`users/${userId}/liked-comments/${comment.id}`, {
                like: !comment.isLiked
            });

            context.commit('TOGGLE_LIKE_COMMENT', comment);
        },

        async loadComments(context: any, post: any) {
            const { data: comments } = await axios.get(`posts/${post.id}/comments`);
            context.commit('ADD_COMMENTS', {
                post,
                comments
            });
        },

        async addNewComment(context: any, payload: any) {
            const { data: comment } = await axios.post(`posts/${payload.post.id}/comments`, {
                content: payload.content
            });
            const comments=comment
            comments._count= {likes: 0, replies: 0}

            context.commit('ADD_NEW_COMMENT', {
                post: payload.post,
                comment:comments
            });
            
        },

        resetComments(context: any, post: any) {
            context.commit('RESET_COMMENTS', post);
        },

        async updateComment(context: any, payload: any) {
            const { data: comment } = await axios.patch(`comments/${payload.id}`, {
                content: payload.content
            });
            const comments=comment
            comments._count= {likes:payload.count.likes, replies:payload.count. replies}

            context.commit('UPDATE_COMMENT', comments);
        },

        async deleteComment(context: any, payload: any) {
            await axios.delete(`comments/${payload.comment.id}`);

            context.commit('DELETE_COMMENT', {
                post: payload.post,
                comment: payload.comment
            });
        }
    }
}