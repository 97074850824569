import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced: true,
    state() {
        return {
            sections: [],
            subsections: [],
            finishedSubsections: [],
            SectionsData: {},
            paginationArray: [],
            courseNotes: [],
            announcementsCount: 0,
            announcements: [],
            //new api data needs to be added here for separate course data , notes , announcements , discussions
            courseModules: [],
            //new api data needs to check if user is enrolled in course
            isEnrolled: false,
            module: null,
            lightCourse: null,
            sectionNotes: [],

        };
    },
    mutations,
    actions,
    getters
};
