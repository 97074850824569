export default {
    getSearchResult(state: any) {
        return state.searchList
    },
    getNotifications(state: any) {
        return state.notificationList
    },
    getMenuStatus(state: any) {
        return state.menuActive;
    }
};
