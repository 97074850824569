<template lang="html">
  <div>
    <header :class="{ mobile: menuStatus }" class="header">
      <div class="container-lg h-100">
        <div v-if="!search" class="header-container">
          <div class="header-logo">
            <router-link to="/">
              <img
                src="@/assets/images/logo-white-footer.png"
                class="header-logo"
                alt="Himam | HLA"
              />
            </router-link>
          </div>
          <div class="header-right">
            <div v-if="!authenticated" class="header-user">
              <ul class="header-list">
                <li class="header-item">
                  <p
                    @click="updateLocale"
                    class="header-item hide-mobile mb-0"
                    role="button"
                  >
                    {{ $t("english") }}
                  </p>
                </li>
                <li class="header-item">
                  <router-link v-if="!mobile" to="/login" class="text-white">
                    {{ $t("login") }}
                  </router-link>
                </li>
                <li v-if="!mobile" class="header-item">
                  <router-link to="/register" class="text-white">
                    {{ $t("register") }}
                  </router-link>
                </li>
                <li @click="MenuClicked">
                  <div class="menu-btn" :class="{ close: menuActive }">
                    <div class="btn-line"></div>
                    <div class="btn-line"></div>
                    <div class="btn-line"></div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else class="header-auth">
              <ul class="header-list">
                <li class="header-item">
                  <p
                    @click="updateLocale"
                    class="header-item hide-mobile mb-0"
                    role="button"
                  >
                    {{ $t("english") }}
                  </p>
                </li>
                <li>
                  <HeaderAvatar @searchClicked="searchClicked" />
                </li>
                <li @click="MenuClicked">
                  <div class="menu-btn" :class="{ close: menuActive }">
                    <div class="btn-line"></div>
                    <div class="btn-line"></div>
                    <div class="btn-line"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else class="h-100">
          <div class="h-100 position-relative w-100">
            <transition name="fade">
              <div
                v-if="search"
                class="d-flex w-100 justify-content-center align-content-center h-90"
              >
                <form @submit.prevent="searchNow" class="header-search-group w-100">
                  <button type="reset" class="header-search-close" @click="resetSearch">
                    <img
                      role="button"
                      src="@/assets/img/profile/cancel.svg"
                      alt="search"
                      width="20"
                      height="20"
                    />
                  </button>
                  <div class="w-100">
                    <input
                      type="text"
                      class="header-search-input w-100"
                      v-model="searchData"
                    />
                  </div>
                  <button type="submit" class="header-search-icon">
                    <img
                      role="button"
                      src="@/assets/img/profile/search-white.svg"
                      alt="search"
                      width="22"
                      height="30"
                    />
                  </button>
                </form>
              </div>
            </transition>
            <ul v-if="isOpen" class="header-search-result">
              <li
                v-for="i in searchResults"
                :key="i.id"
                @click="changeRoute(i.id)"
                style="cursor: pointer"
              >
                {{ i.name }}
              </li>
              <li v-if="searchResults.length === 0" style="cursor: pointer">
                {{ $t("header.searchNotFound") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <transition :name="locale !== 'ar' ? 'animation' : 'animation-right'">
      <nav
        :class="{
          'header-navbar': !mobile,
          'header-navbar-mobile': mobile,
          ar: locale === 'ar',
        }"
        v-show="menuActive"
      >
        <div class="container-lg h-100">
          <div class="header-navbar-container h-100">
            <ul v-if="mobile" class="header-mobile">
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/">
                  <img
                    src="@/assets/images/logo-white-footer.png"
                    alt="Himam | HLA"
                    width="194"
                    height="41"
                  />
                </router-link>
              </li>
            </ul>
            <ul @click="MenuClicked" v-if="!authenticated" class="header-navbar-list">
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/">
                  {{ $t("header.home") }}
                </router-link>
              </li>
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/site/about">
                  {{ $t("header.aboutUs") }}
                </router-link>
              </li>
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/library">
                  {{ $t("header.library") }}
                </router-link>
              </li>
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/site/contact-us">
                  {{ $t("header.contactUs") }}
                </router-link>
              </li>
              <li class="header-navbar-item" v-if="mobile">
                <p @click="updateLocale" class="header-navbar-link" role="button">
                  {{ $t("english") }}
                </p>
              </li>
              <li v-if="mobile" class="header-navbar-item">
                <router-link to="/login" class="header-login">
                  <i class="fa-solid fa-arrow-right-to-bracket"></i>
                  {{ $t("login") }}
                </router-link>
              </li>
              <li v-if="mobile" class="header-navbar-item">
                <router-link to="/register" class="header-register">
                  <i class="fa-solid fa-user-plus"></i>
                  {{ $t("register") }}
                </router-link>
              </li>
            </ul>
            <ul @click="MenuClicked" v-else class="header-navbar-list">
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/groups/index">
                  {{ $t("header.home") }}
                </router-link>
              </li>
              <li class="header-navbar-item">
                <router-link
                  class="header-navbar-link"
                  to= '/lms/courses'
                >
                  {{ $t("header.eLearning") }}
                </router-link>
              </li>
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/groups">
                  {{ $t("header.allGroup") }}
                </router-link>
              </li>
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/coaches">
                  {{ $t("header.coaching") }}
                </router-link>
              </li>
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/events">
                  {{ $t("header.events") }}
                </router-link>
              </li>
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/library">
                  {{ $t("header.library") }}
                </router-link>
              </li>
              <li class="header-navbar-item">
                <router-link class="header-navbar-link" to="/calendar">
                  {{ $t("header.calendar") }}
                </router-link>
              </li>
              <li v-if="mobile" class="header-navbar-item">
                <router-link class="header-navbar-link" to="/site/contact-us">
                  {{ $t("header.contactUs") }}
                </router-link>
              </li>
              <li v-if="mobile" class="header-navbar-item">
                <router-link class="header-navbar-link" to="/site/about">
                  {{ $t("header.aboutUs") }}
                </router-link>
              </li>
              <li v-if="mobile" class="header-navbar-item">
                <router-link class="header-navbar-link" to="/site/feedback">
                  {{ $t("footer.Feedback") }}
                </router-link>
              </li>
              <li class="header-navbar-item" v-if="!mobile">
                <div class="dropdown">
                  <a
                    class="dropdown-toggle header-navbar-link"
                    type="button"
                    id="more"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ $t("header.more") }}
                  </a>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="more"
                    :class="{ ar: locale === 'ar' }"
                  >
                    <li class="header-navbar-item">
                      <router-link
                        class="header-navbar-link primary"
                        to="/site/contact-us"
                      >
                        {{ $t("header.contactUs") }}
                      </router-link>
                    </li>
                    <li class="header-navbar-item">
                      <router-link class="header-navbar-link primary" to="/site/about">
                        {{ $t("header.aboutUs") }}
                      </router-link>
                    </li>
                    <li class="header-navbar-item">
                      <router-link class="header-navbar-link primary" to="/site/feedback">
                        {{ $t("footer.Feedback") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="header-navbar-item" v-if="mobile">
                <p @click="updateLocale" class="header-navbar-link" role="button">
                  {{ $t("english") }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </transition>
    <div v-if="menuStatus" class="header-backdrop" @click="MenuClicked"></div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import HeaderAvatar from "@/layout/header/HeaderAvatar";

export default {
  name: "s-navbar",
  components: { HeaderAvatar },
  data() {
    return {
      scrollNavbar: null,
      mobile: false,
      menuActive: false,
      windowWidth: null,
      search: false,
      searchData: null,
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      menuStatus: "header/getMenuStatus",
      locale: "appLocale",
      searchResults: "header/getSearchResult",
      isInstructor: "auth/isInstructor",
    }),
  },
  methods: {
    ...mapActions({
      setMenuStatus: "header/menuActive",
      searchGroup: "header/searchGroup",
      changeLocale: "changeLocale",
      clearSearch: "header/clearSearch",
    }),
    checkScreenSize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 800) {
        this.mobile = false;
        this.menuActive = true;
        this.setMenuStatus(false);
        return;
      }
      this.mobile = true;
      this.menuActive = false;
    },
    checkScroll() {
      if (window.scrollY > 50) {
        this.scrollNavbar = true;
        return;
      }
      this.scrollNavbar = false;
    },
    MenuClicked() {
      if (this.mobile) {
        this.menuActive = !this.menuActive;
        this.setMenuStatus(this.menuActive);
        return;
      }
      this.menuActive = true;
      this.setMenuStatus(false);
    },
    async searchNow() {
      this.isOpen = !this.isOpen;
      if (this.searchData.length >= 3) {
        await this.searchGroup(this.searchData);
      }
    },
    searchClicked() {
      if (this.mobile) {
        this.search = !this.search;
        return;
      }
      this.search = false;
    },
    resetSearch() {
      this.searchData = null;
      this.clearSearch();
      this.search = false;
    },
    updateLocale() {
      this.locale === "en"
        ? this.changeLocale({ lang: "ar", i18n: this.$i18n })
        : this.changeLocale({ lang: "en", i18n: this.$i18n });
    },
  },
  created() {
    window.addEventListener("resize", this.checkScreenSize);
    this.checkScreenSize();
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/app.scss";

:deep .header {
  background-color: transparent;
  height: 80px;
  @include screen-md {
    background-color: $primary1;
  }

  &-avatar {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 0;
    @include screen-md {
      gap: 10px;
    }

    &-img {
      width: 35px;
      height: 35px;
      object-fit: cover;
      display: block;
      border-radius: 50%;
      @include screen-md {
        width: 30px;
        height: 30px;
      }
    }
  }

  &-notification {
    min-width: 300px;
    @include screen-xs {
      min-width: 200px;
    }

    &:after {
      content: none;
    }
  }

  &-dropdown {
    &-link {
      display: block;
      width: 100%;
      padding: 10px;
      color: $dark;
      &.router-link-exact-active {
        color: $primary;
        border-radius: $radius-md !important;
      }
    }

    &-item {
      color: $white;
      border-radius: $radius-md !important;
      overflow: hidden;
    }
  }

  &-search {
    &-group {
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $radius-sm;
      overflow: hidden;
      border: 1px solid $lines;
    }

    &-input {
      color: $white !important;

      &::placeholder {
        color: $white;
      }
    }

    &-icon {
      all: unset;
      width: 20px;
      height: 20px;
      margin-inline-end: 4px;
      background-color: $primary1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $primary1;

      // &:hover {
      //   outline: none;
      //   box-shadow: none;
      //   border-color: $primary2;
      //   background-color: $primary2;
      // }
    }

    &-close {
      all: unset;
      width: 20px;
      height: 15px;
      margin-inline-start: 4px;
      background-color: $primary1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $primary1;

      // &:hover {
      //   outline: none;
      //   box-shadow: none;
      //   border-color: $muted;
      //   background-color: $muted;
      // }

      border-radius: $radius-sm 0 0 $radius-sm;
    }

    &-input {
      display: block;
      box-sizing: border-box;
      all: unset;
      height: 40px;
      border-right: 0;
      border-left: 0;
      padding: 0 15px;
      color: $white;

      &:focus-visible {
        border-right: 0;
        border-left: 0;
      }
    }

    &-result {
      position: absolute;
      list-style: none;
      // bottom: -150px;
      width: 100%;
      z-index: 5;
      padding: 0;
      left: 1px;
      max-height: 150px;
      overflow-y: auto;
      background-color: $white;
      color: $white;
      border-radius: 0 0 5px 5px;
      min-width: calc(100% - 42px);
      box-shadow: $buttonShadow;

      li {
        padding: 10px 15px;
        color: $dark;
        transition: all 0.3s ease-in-out;

        // &:hover {
        //   color: $white;
        //   background-color: $primary1;
        // }
      }
    }
  }

  &-notification {
    max-height: 250px;
    overflow-y: auto;
    border-radius: $radius-md;
    padding: 0;

    &-item {
      padding: 10px;
      color: $dark;
      font-size: $font-s;

      // &:hover {
      //   background-color: $primary2;
      //   color: $white;
      // }
    }
  }

  &.mobile {
    z-index: 21;
    position: fixed;
    width: 100%;
  }

  &-backdrop {
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 20;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  &-mobile {
    display: block;
    padding: 30px 0 0 0;
  }

  &-navbar {
    background-color: transparent;
    display: block !important;
    padding: 2rem 0;

    &-mobile {
      background-color: $primary1;
      @include screen-md {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 250px;
        z-index: 100;
        overflow-y: auto;
        box-shadow: $buttonShadow;
        padding: 5rem 2rem;
        &.ar {
          right: 0;
          left: auto;
        }
      }
    }

    &-conatiner {
      margin: auto;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }

    &-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      max-width: 900px;
      height: 100%;
      margin: 0 auto;
      padding: 0;
      @include screen-md {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        padding-left: 5px;
        padding-top: 40px;
        overflow-y: auto;
      }
    }

    &-item {
      box-sizing: border-box;
      color: $white;
      display: block;
      width: 100%;
      position: relative;
      text-align: center;
      @include screen-md {
        text-align: start;
      }
    }

    &-link {
      color: $white;
      transition: all 0.3s ease-in-out;
      text-transform: capitalize;
      @extend .regular;

      &:hover {
        color: $white;
      }

      @include screen-md {
        color: $white;
        display: block;
        padding-right: 20px;
        &:hover {
          color: $primary2;
          text-decoration: underline;
        }
      }

      &.router-link-exact-active {
        text-decoration: underline;
        @include screen-md {
          color: $primary2;
        }
      }

      &.primary {
        color: $primary1;
        padding: 10px 15px;
        width: 100%;
        display: block;

        &:hover {
          color: $white;
          background-color: $primary2;
          text-decoration: none;
        }

        &.router-link-exact-active {
          transform: scale(1);
          text-decoration: underline;
        }
      }
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    height: 100%;
    width: 100%;
  }

  &-logo {
    position: relative;

    img {
      max-width: 200px;
      height: 5rem;
      display: block;
      @include screen-md {
        height: 45px;
        max-width: 175px;
      }
    }
  }

  &-list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: 0;
    @include screen-md {
      gap: 10px;
    }
  }

  &-item {
    box-sizing: border-box;
  }

  &-link {
    all: unset;
    color: $primary;

    &:hover {
      color: darken($primary, 10%);
    }
  }

  &-login,
  &-register {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 125px;
    width: 125px;
    gap: 10px;
    height: 40px;
    border-radius: $radius-sm;
    transition: all 0.3s ease-in-out;
  }

  &-login {
    color: $primary2;
    border: 1px solid $primary2;

    &:hover {
      background-color: $primary2;
      color: $white;
    }
  }

  &-register {
    background-color: $primary2;
    border: 1px solid $primary2;

    color: $white;

    &:hover {
      background-color: $primary;
      color: $white;
      border-color: $primary;
    }
  }
}

:deep .dropdown-menu {
  border-radius: $radius-md !important;
  margin: 0;
  padding: 0;

  .header-navbar-link {
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background-color: $primary2;
      color: $white;
      overflow: hidden;
    }
  }
}

:deep .dropdown-menu {
  // transition: all 0.3s ease-in-out;
  border: none;
  padding-top: 0;
  border-radius: $radius-md;

  padding-bottom: 0;
  box-shadow: $buttonShadow;

  .header-navbar-item {
    text-align: start;
    border-radius: $radius-md !important;
  }

  cursor: pointer;

  &.ar {
    padding: 0;
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  border-radius: $radius-md;
}

.dropdown-menu {
  //transition: all 0.3s ease-in-out;
  overflow: hidden;
  border-radius: $radius-md;
}

/* Styling the menu button */
.menu-btn {
  z-index: 3;
  right: 35px;
  top: 35px;
  cursor: pointer;
  transition: all 0.5s ease-out;
  display: none;
  @include screen-md {
    display: block;
  }
}

/* Styling the hamburger lines */
.menu-btn .btn-line {
  width: 23px;
  height: 3px;
  margin: 0 0 5px 0;
  background: $white;
  transition: all 0.5s ease-out;
  // border-radius: $radius-md;

  &:last-child {
    margin: 0;
  }
}

/* Adding transform to the X */
.menu-btn.close {
  transform: rotate(180deg);
}

/* Styling the three lines
    to make it an X */
.menu-btn.close .btn-line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-btn.close .btn-line:nth-child(2) {
  opacity: 0;
}

.menu-btn.close .btn-line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}

.animation-enter-active,
.animation-leave-active {
  transition: 1s ease all;
}

.animation-enter-from,
.animation-leave-to {
  transform: translateX(-270px);
}

.animation-enter-to {
  transform: translateX(0);
}

.animation-right-enter-active,
.animation-right-leave-active {
  transition: 1s ease all;
}

.animation-right-enter-from,
.animation-right-leave-to {
  transform: translateX(270px);
}

.animation-right-enter-to {
  transform: translateX(0);
}

:deep(p.header-item) {
  color: $white;
}
</style>
