'use strict';
import axios from "@/axios";
import AddQuizQuestionInterface from '@/interfaces/quiz.question.instructor.interface';

export default {

    createQuestion: async function (context: any, payload: AddQuizQuestionInterface) {
        const response = await axios.post(`/questions`, payload);
        context.commit("setQuestion", response.data);
      },
      getQuestion: async function (context: any, id: number) {
        const response = await axios.get(`/questions/${id}`);
        context.commit("setQuestion", response.data);
      },
    
      updateQuestion: async function (context: any, payload: any) {
        const response = await axios.patch(`/questions/${payload.id}`, payload);
        context.commit("setQuestion", response.data);
      },
    
      deleteQuestion: async function (context: any, id: number) {
        await axios.delete(`/questions/${id}`);
        context.commit("setQuestion", null);
        context.dispatch("getAllQuestion");
      },
      getAllQuestion: async function (context: any, id: number) {
        const response = await axios.get(`/questions?offset=0&limit=8`);
        context.commit("setQuestions", response.data);
      },
      getAllQuestions: async function (context: any, id: number) {
        const response = await axios.get(`/questions`);
        context.commit("setQuestions", response.data);
      },
    }

