import axios from "@/axios";
import { computed } from "vue";

export const coachProfile = {
  namespaced: true,
  state: {
    isOpen: false,
    editIsOpen: false,
    user: {
      firstName: "Mohammad",
      lastName: "Qatani",
      occupation: "Anesthiologist",
      location: "Jeddah, Saudi Arabia",
      image:
        "https://www.kodiri.com/rest/candidates/61baa778c7b6a86a528c94d2/media/avatar",
      languages: [
        {
          id: 6,
          language: "ENGLISH",
          proficiency: "NATIVE",
          coachingAvailableInLangugage: true,
          userId: 2069,
        },
      ],
      education: [],

      about: "",
    },
    aboutMe: "",
    language: {},
    video: "",
    education: [],
    tools: {
      type: "",
      name: "",
      organization: "",
      startedDate: "",
      endDate: "",
      expiration: "",
    },
    workExperience: [],
    //  workExperience:{
    //   occupation:'',
    //   organization:'',
    //   date:'',
    //   place:'',
    //  },
    positions: [],
  },

  getters: {
    aboutMe(state: any) {
      return state.aboutMe;
    },
    user(state: any) {
      return state.user;
    },
    // language(state:any){
    //   return state.language;
    // },
    video(state: any) {
      return state.video;
    },
    education(state: any) {
      return state.education;
    },
    tools(state: any) {
      return state.tools;
    },
    workExperience(state: any) {
      return state.workExperience;
    },
    positions(state: any) {
      return state.positions;
    },
    isOpen(state: any) {
      return state.isOpen;
    },
    editIsOpen(state: any) {
      return state.editIsOpen;
    },
  },

  mutations: {
    openModal(state: any, value: boolean) {
      state.isOpen = value;
    },
    openEditModal(state: any, value: boolean) {
      state.editIsOpen = value;
    },
    setUser(state: any, data: any) {
      state.user = data;
    },

    addAboutMe(state: any, payload: any) {
      state.user.about = payload;
    },
    addLanguages(state: any, payload: any) {
      for (let i = 0; i < state.user.coachLanguages.length; i++) {
        if (state.user.coachLanguages[i].language === payload.language) {
          state.user.coachLanguages[i] = payload;
          return;
        }
      }
      state.user.coachLanguages.push(payload);
    },
    editLanguages(state: any, payload: any) {
      for (let i = 0; i < state.user.coachLanguages.length; i++) {
        if (state.user.coachLanguages[i].id === payload.id) {
          state.user.coachLanguages[i] = payload;
          break;
        }
      }
    },
    deleteLanguage(state: any, payload: any) {
      for (let i = 0; i < state.user.coachLanguages.length; i++) {
        if (state.user.coachLanguages[i].id === payload.id) {
          state.user.coachLanguages.splice(i, 1);
          break;
        }
      }
    },
    addVideo(state: any, payload: any) {
      state.video = payload;
    },
    addEducation(state: any, payload: any) {
      state.user.coachEducation.push(payload);
    },
    editEducation(state: any, payload: any) {
      for (let i = 0; i < state.user.coachEducation.length; i++) {
        if (state.user.coachEducation[i].id === payload.id) {
          state.user.coachEducation[i] = payload;
          break;
        }
      }
    },
    deleteEducation(state: any, payload: any) {
      for (let i = 0; i < state.user.coachEducation.length; i++) {
        if (state.user.coachEducation[i].id === payload.id) {
          state.user.coachEducation.splice(i, 1);
          break;
        }
      }
    },
    addTools(state: any, payload: any) {
      state.tools = payload;
    },
    addWorkExperience(state: any, payload: any) {
      state.user.coachWorkExperience.push(payload);
    },
    editExperience(state: any, payload: any) {
      for (let i = 0; i < state.user.coachWorkExperience.length; i++) {
        if (state.user.coachWorkExperience[i].id === payload.id) {
          state.user.coachWorkExperience[i] = payload;
          break;
        }
      }
    },
    deleteExperience(state: any, payload: any) {
      for (let i = 0; i < state.user.coachWorkExperience.length; i++) {
        if (state.user.coachWorkExperience[i].id === payload.id) {
          state.user.coachWorkExperience.splice(i, 1);
          break;
        }
      }
    },
    addPosition(state: any, payload: any) {
      state.positions = [...state.positions, ...payload];
    },
    addCertificate(state: any, payload: any) {
      state.user.coachCertifications.push(payload);
    },
    editCertificate(state: any, payload: any) {
      for (let i = 0; i < state.user.coachCertifications.length; i++) {
        if (state.user.coachCertifications[i].id === payload.id) {
          state.user.coachCertifications[i] = payload;
          break;
        }
      }
    },
    deleteCertificate(state: any, payload: any) {
      for (let i = 0; i < state.user.coachCertifications.length; i++) {
        if (state.user.coachCertifications[i].id === payload.id) {
          state.user.coachCertifications.splice(i, 1);
          break;
        }
      }
    },
    addSpecialities(state: any, payload: any) {
      state.user.coachSpecializations.push(payload);
    },

    deleteSpecialities(state: any, payload: any) {
      for (let i = 0; i < state.user.coachSpecializations.length; i++) {
        if (state.user.coachSpecializations[i].specialization.id === payload) {
          //this should be edited
          state.user.certification.splice(i, 1);
          break;
        }
      }
    },
    addPos(state: any, payload: any) {
      state.user.coachPositions.push(payload);
    },
  },
  actions: {
    openModal({ commit }: any, value: boolean) {
      commit("openModal", value);
    },
    openEditModal({ commit }: any, value: boolean) {
      commit("openEditModal", value);
    },
    async addLanguage({ commit, state }: any, lang: any) {
      commit("addLanguages", lang);

      // state.user.languages[state.user.languages.length - 1] = res.data;
    },
    async editLanguage({ commit }: any, lang: any) {
      await axios.patch(`coach-language/${lang.id}`, lang);
      commit("editLanguages", lang);
    },
    async editEducation({ commit }: any, edu: any) {
      commit("editEducation", edu);
      await axios.patch(`coach-education/${edu.id}`, edu);
    },
    async editExperience({ commit }: any, exp: any) {
      commit("editExperience", exp);
      await axios.patch(`coachexperience/${exp.id}`, exp);
    },
    async deleteExperience({ commit }: any, exp: any) {
      commit("deleteExperience", exp);
      await axios.delete(`coachexperience/${exp.id}`);
    },
    async addEducation({ commit, state }: any, edu: any) {
      commit("addEducation", edu);
      //state.user.education[ state.user.education.length-1]=res.data;
    },
    async deleteEducation({ commit }: any, edu: any) {
      commit("deleteEducation", edu);
      await axios.delete(`coach-education/${edu.id}`);
    },
    async addExperience({ commit, state }: any, exp: any) {
      commit("addWorkExperience", exp);
      // state.user.experience[ state.user.experience.length]=res.data;
    },
    async addCertificate({ commit, state }: any, cer: any) {
      commit("addCertificate", cer);

      // state.user.certification[state.user.certification.length - 1] = res.data;
    },
    async editCertificate({ commit }: any, cer: any) {
      commit("editCertificate", cer);
      await axios.patch(`coach-certification/${cer.id}`, cer);
    },
    async addBio({ commit }: any, bio: any) {
      commit("addAboutMe", bio);
      const res = await axios.patch(`coach-details/about-me`, { aboutMe: bio });
    },
    async deleteCertificate({ commit }: any, cer: any) {
      commit("deleteCertificate", cer);
      await axios.delete(`coach-certification/${cer.id}`);
    },
    async deleteLanguage({ commit }: any, lang: any) {
      commit("deleteLanguage", lang);
      await axios.delete(`coach-language/${lang.id}`);
    },
    async addSpecialities({ commit }: any, sp: any) {
      commit("addSpecialities", sp);
      const res = await axios.post("user-on-specialization", {
        specializationId: sp.id,
      });
    },
    async deleteSpecialities({ commit }: any, sp: any) {
      commit("deleteSpecialities", sp);
      const res = await axios.delete(`user-on-specialization/${sp}`);
    },
    addPos({ commit }: any, payload: any) {
      commit("addPos", payload);
    },
  },
};
