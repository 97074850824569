

import axios from '@/axios';

export async function GetCoachSessions() {
     const res = await axios
     .get("calendly/v2/coach-sessions");
 return res
}

export async function coachingPosition() {
    const res = await axios
    .get("coaching-position");
return res
}


export async function GetCoachPosition() {
    const res = await axios
    .get("coach-positions");
return res
}
export async function AddCoachPosition(data:any) {
    const p={"positions":data}
    const res = await axios
    .post("coach-positions",
    p,
    {
      headers: {
        "Content-Type": "application/json",
      },
    });
return res
}

