import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    favoriteEvent: [],
    favoriteCourse: [],
    purchases: [],
    savedPost: [],
    myCourse: [],
    myEvents: [],
    session: [],
  },
  getters: {
    favoriteEvent(state: any) {
      return state.favoriteEvent;
    },
    favoriteCourse(state: any) {
      return state.favoriteCourse;
    },
    purchases(state: any) {
      return state.purchases;
    },
    savedPost(state: any) {
      return state.savedPost;
    },
    myCourses(state: any) {
      return state.myCourse;
    },
    myEvents(state: any) {
      return state.myEvents;
    },
    mySession(state: any) {
      return state.session;
    },
  },
  mutations: {
    setFavoriteEvent(state: any, payload: any) {
      state.favoriteEvent = payload;
    },
    setFavoriteCourse(state: any, payload: any) {
      state.favoriteCourse = payload;
    },
    setPurchases(state: any, payload: any) {
      state.purchases = payload;
    },
    setSavedPost(state: any, payload: any) {
      state.savedPost = payload;
    },
    setMyCourse(state: any, courses: any) {
      state.myCourse = courses;
    },
    setMyEvents(state: any, events: any) {
      state.myEvents = events;
    },
    setMySessions(state: any, sessions: any) {
      state.session = sessions;
    },
  },
  actions: {
    async getFavoriteEvent(context: any, payload: any) {
      const favoritesEvent = await axios.get(
        `users/${payload}/favorited-events?limit=20&offset=0`
      );
      context.commit("setFavoriteEvent", favoritesEvent.data);
    },
    async getFavoriteCourse(context: any, payload: any) {
      const favoriteCourse = await axios.get(
        `users/${payload}/wishlisted-courses?limit=20&offset=0`
      );
      context.commit("setFavoriteCourse", favoriteCourse.data.data);
    },
    async getMyCourse(context: any, userId: number) {
      const myCourses = await axios.get(
        `/users/${userId}/enrolled-courses?limit=4&offset=0`
      );
      context.commit("setMyCourse", myCourses.data.data);
    },
    async getMyEvents(context: any, userId: number) {
      const myEvents = await axios.get(
        `/users/${userId}/enrolled-events?limit=4&offset=0`
      );
      context.commit("setMyEvents", myEvents.data);
    },
    async getMyCoacheeSession(context: any, payload: any) {
      if (payload) {
        const mySession = await axios.get(
          `/calendly/v2/coachee-statistics?startTime=${payload}`
        );
        context.commit("setMySessions", mySession.data);
      } else {
        const mySession = await axios.get(`/calendly/v2/coachee-statistics`);
        context.commit("setMySessions", mySession.data);
      }
    },
    async getPurchases(context: any, payload: any) {
      const purchases = await axios.get(`users/${payload}/orders`);
      context.commit("setPurchases", purchases.data);
    },
    async getSavedPost(context: any, payload: any) {
      const posts = await axios.get(
        `users/${payload}/saved-posts?offset=0&limit=10`
      );
      context.commit("setSavedPost", posts.data);
    },
    async editProfile(context: any, payload: any) {
      await axios.patch(`users/${payload.id}`, payload.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },
};
