// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBrkLmdmv0V30is1rqmh5mYJhaiy38wTOM",
    authDomain: "himam-cd75c.firebaseapp.com",
    projectId: "himam-cd75c",
    storageBucket: "himam-cd75c.appspot.com",
    messagingSenderId: "778885333879",
    appId: "1:778885333879:web:00917f7f964607189f5772",
    measurementId: "G-LZLLR9R5G0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);