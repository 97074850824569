import {createApp} from 'vue';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import * as mdbVue from 'mdb-vue-ui-kit';
import App from './App.vue';
import router from '@/router/index.routes';
import store from '@/store';
import axiosInstance from "./axios";
import i18n from './i18n';
import './firebase';
import moment from 'moment';
import {plugin, defaultConfig} from '@formkit/vue'
import BaseButton from "@/modules/shared/src/ui/BaseButton.vue";
import Spinner from "@/modules/shared/src/ui/Spinner.vue";
// fontawesome
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {fas} from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import {fab} from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import {far} from '@fortawesome/free-regular-svg-icons';
library.add(far as any);
import {dom} from "@fortawesome/fontawesome-svg-core";
//end font awesome


dom.watch();
import {io} from "socket.io-client";
import BaseDialog from "@/modules/shared/src/ui/BaseDialog.vue";

/* add icons to the library */
const app = createApp(App);
app.config.globalProperties.$socket = io(process.env.VUE_APP_SOCKET_URL);
app.use(plugin, defaultConfig)
app.use(router);
app.use(store);
app.use(i18n);
app.component("font-awesome-icon", FontAwesomeIcon);


//App Base Components
app.component("base-button", BaseButton);
app.component("spinner", Spinner);
app.component('base-dialog', BaseDialog);
for (const component in mdbVue) {
    app.component(component, mdbVue[component]);
}
app.config.globalProperties.axios = axiosInstance;
app.config.globalProperties.moment = moment;


//global error handler
app.config.errorHandler = (err, vm, info) => {
    console.error(err);
    console.error(vm);
    console.error(info);
}
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app');


