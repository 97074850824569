
import { Event } from "@/interfaces/event.interface";

export default {
  event(state: any): Event | null {
    return state.event;
  },
  isFavorite(state: any): Event | null {
    return state?.event?.isFavorited;
  },
  favUrl(state: any): Event | null | string {
    return state?.event?.isFavorited ? "savedFill.svg" : "saved.svg";
  },
  host(state: any): Event | null {
    return state?.event?.eventHosts;
  },
  groupId(state: any): Event | null {
    return state?.event?.groupId;
  },
};
