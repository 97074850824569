import {CourseInterface} from "@/interfaces/course.interface";
import {FullSection, Section, Subsection} from "@/interfaces/course-section.interface";

export default {
    setCourse(state: any, payload: CourseInterface) {
        state.course = payload;
    },
    setCourseModules(state: any, payload: Section[] | null | FullSection) {
        state.courseModules = payload;
    },
    setCourseActiveSubsection(state: any, payload: Subsection | null) {
        state.activeSubsection = payload;
    },
    setCourseActiveSection(state: any, payload: Section | null) {
        state.activeSection = payload;
    },
    setCourseSubsectionsSorted(state: any, payload: number[] ) {
        state.courseSubsectionsSorted = payload;
    }
}


