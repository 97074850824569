export default {
    errorMessage(state: any): Promise<any> {
        return state.errorMessage;
    },

    login(state: any): Promise<any> {
        return state.login;
    },
    errorMessageSignup(state: any): Promise<any> {
        return state.errorMessageSignup;
    },
    signup(state: any): Promise<any> {
        return state.signup;
    },
};
