export default {
    setLoginError(state: any, payload: any) {
        state.errorMessage = payload
    },
    setlogin(state: any, payload: any) {
        state.login = payload
    },
    setSignupError(state: any, payload: any) {
        state.errorMessageSignup = payload
    },
    setSignup(state: any, payload: any) {
        state.signup = payload
    },
};
