import axios from "@/axios";
import { computed } from "vue";
import store from "..";

export const notification = {
  namespace: true,
  state: {
    unreadCount: 0,
  },

  getters: {
    getUnreadCount(state: any) {
      return state.unreadCount;
    },
  },

  mutations: {
    setNotificationUnreadCount(state: any) {
      axios.get("notifications/count").then((res: any) => {
        state.unreadCount = res.data.count;
      });
    },
  },

  actions: {
    setNotificationUnreadCount(context: any) {
      context.commit("setNotificationUnreadCount");
    },
  },
};
