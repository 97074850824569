import axios from '@/axios';

export  async function getCoursebyId (id: number) {
 try {
     return await axios.get(`courses/${id}`);
 }catch (e) {
     return 500;
 }
}


export  async function addDiscussionBoardsComment(id: number,comment:string) {
    const res = await axios.post(`course-sections/${id}/discussion-boards`,{content:comment});

   return res;
}
export  async function addDiscussionBoardsCommentCourse(id: number,comment:string) {
    const res = await axios.post(`courses/${id}/discussion-boards`,{content:comment});

   return res;
}

export  async function repliesDiscussionBoardsComment(id: number,comment:string,parentId:number) {
    const res = await axios.post(`course-sections/${id}/discussion-boards`,{content:comment,parentId:parentId});
   return res;
}


export  async function getCoursesectionsbyId (id: number) {
    const res = await axios.get(`course-sections?courseId=${id}`);

   return res;
}



export async function FinishSection(userId:number,sup_section_id:number){
    const res = await axios.post(
        `users/${userId}/finished-subsections/${sup_section_id}`
      );
   return res;

}


export async function getCourseSupSection(sectionId:number){
    const res = await axios.get(`course-subsections/${sectionId}`);
   return res;

}

export async function enrolledCourses(userId:number,coursesId:number){
    const res = await axios.post(
        `users/${userId}/enrolled-courses/${coursesId}`
      );

    return res;

}
export async function userEenrolledCourses(userId:number){
    const res =  await axios.get(`/users/${userId}/enrolled-courses?limit=8&offset=0`);
    return res;
}
export  function GetcourseCategories(){
    const res =   axios.get(`course-categories`);
    return res;

}

export async  function GetcoursesByCategories(categoryId:number){
    const res =   await axios.get(`courses?categories[]=${categoryId}`);
    return res;

}


export async function enrolledAllCourses(userId:number){
    const res = await axios.get(`/users/${userId}/enrolled-courses`)
    return res;

}


export async function getallCourses(){
    const res = await axios.get(`courses`)
    return res;

}
