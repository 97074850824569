<template lang="html">
  <ul class="header-avatar">
    <li class="position-relative">
      <img
        v-show="!showSearch"
        role="button"
        src="@/assets/img/profile/search.svg"
        alt="search"
        width="22"
        height="50"
        @click="searchClicked"
      />
      <transition name="fade">
        <div v-if="showSearch">
          <form @submit.prevent="search" class="header-search-group">
            <button type="reset" class="header-search-close" @click="resetSearch">
              <img
                role="button"
                src="@/assets/img/profile/cancel.svg"
                alt="search"
                width="22"
                height="50"
              />
            </button>
            <div>
              <input type="text" class="header-search-input" v-model="searchData" />
            </div>
            <button type="submit" class="header-search-icon">
              <img
                role="button"
                src="@/assets/img/profile/search-white.svg"
                alt="search"
                width="22"
                height="50"
              />
            </button>
          </form>
        </div>
      </transition>
      <ul v-if="isOpen" class="header-search-result">
        <li
          v-for="i in searchResults"
          :key="i.id"
          @click="changeRoute(i.id)"
          style="cursor: pointer"
        >
          {{ i.name }}
        </li>
        <li v-if="searchResults.length === 0" style="cursor: pointer">
          {{ $t("header.searchNotFound") }}
        </li>
      </ul>
    </li>
    <li>
      <div class="dropdown show">
        <a
          class="dropdown-toggle header-notification"
          href="#"
          role="button"
          id="notification"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="@/assets/img/profile/notification.svg"
            alt="notification"
            width="22"
            height="50"
          />
        </a>

        <ul
          class="dropdown-menu header-notification Notifaction"
          aria-labelledby="notification"
        >
          <li
            class="header-notification-item scroll-area"
            v-for="notification in notifications"
            :key="notification.id"
          >
            <span> {{ notification?.text }}</span>
          </li>
          <li class="header-notification-item" v-if="notifications.length === 0">
            {{ $t("noResultFound") }}
          </li>
        </ul>
      </div>
    </li>
    <li>
      <a
        class="dropdown-toggle header-notification"
        href="#"
        role="button"
        id="headerProfile"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          :src="
            user?.profileImage?.url
              ? user?.profileImage?.url
              : require('@/assets/images/card/user.svg')
          "
          alt="user"
          width="16"
          height="16"
          class="header-avatar-img"
        />
      </a>

      <ul
        class="dropdown-menu "
        :class="{ ar: locale === 'ar' }"
        aria-labelledby="headerProfile"
      >
        <li class="dropdown-item">
          <p
            class="dropdown-item header-dropdown-link"
            @click.prevent="changeRouteAvatar('/profile/my-course')"
          >
            {{ $t("header.profile") }}
          </p>
        </li>

        <li class="dropdown-item">
          <p
            class="dropdown-item header-dropdown-link mb-0"
            href="#"
            @click="logoutNow"
            role="button"
          >
            {{ $t("header.Logout") }}
          </p>
        </li>
      </ul>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/all.scss";
.header-dropdown-link {
  color: $dark !important;
  font-size: $font-md !important;
}

.Notifaction {
  padding: 0.5rem !important;
  .header-notification {
    padding: 10px;
    &-item {
      padding: 3rem;
      margin: 1rem;
      border-radius: $radius-sm;
      background-color: $background;
    }
  }
}
.dropdown-item {
  border-bottom: none;
  &:hover {
    background-color: $primary2;
    color: $white !important;
  }
}
.dropdown-menu{
  overflow: hidden;
}

</style>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "@/axios";

export default {
  emits: ["searchClicked"],
  data() {
    return {
      showSearch: false,
      searchData: null,
      isOpen: false,
      offset: 0,
      limit: 10,
    };
  },
  methods: {
    ...mapActions({
      searchGroup: "header/searchGroup",
      clearSearch: "header/clearSearch",
      getUserNotification: "header/getUserNotification",
      logout: "auth/login",
    }),
    logoutNow() {
      return this.logout();
    },
    async search() {
      this.isOpen = !this.isOpen;
      if (this.searchData.length >= 3) {
        await this.searchGroup(this.searchData);
      }
    },
    changeRoute(id) {
      window.location.assign(`/groups/group/${id}`);
      this.isOpen = false;
    },
    changeRouteAvatar(link) {
      this.$router.push(link);
    },
    resetSearch() {
      this.clearSearch();
      this.isOpen = false;
      this.showSearch = false;
    },
    searchClicked() {
      this.showSearch = !this.showSearch;
      this.$emit("searchClicked");
    },
  },
  computed: {
    ...mapGetters({
      searchResults: "header/getSearchResult",
      notifications: "header/getNotifications",
      user: "auth/user",
      locale: "appLocale",
      isInstructor: "auth/isInstructor",
    }),
  },
  async created() {
    await this.getUserNotification({ offset: this.offset, limit: this.limit });
  },
};
</script>
