export default {
  setRecentPost(state: any, payload: any) {
    state.recentPost = payload;
  },
  setGrouptPost(state: any, payload: any) {
    state.groupPost = payload;
  },
  setSuggestedCourses(state: any, payload: any) {
    state.suggestedCourses = payload;
  },
  setSuggestedCoaches(state: any, payload: any) {
    state.suggestedCoaches = payload;
  },
  setSuggestedEvents(state: any, payload: any) {
    state.suggestedEvents = payload;
  },
  setSuggestedGroups(state: any, payload: any) {
    state.suggestedGroups = payload;
  },
  setUserMainInfo(state: any, payload: any) {
    state.userMainData = payload;
  },
  setGroup(state: any, payload: any) {
    state.group = payload;
  },
  setAllGroups(state: any, payload: any) {
    state.allGroups = payload;
  },
  setGroupAdmin(state: any, payload: any) {
    if (payload) {
      return (state.admins = payload.filter(
        (member: any) => member.groupRole === "ADMIN"
      ));
    }
  },
  setGroupMember(state:any,payload:any){
    state.groupMember=payload
  },
  setGroupRequest(state:any,payload:any){
    state.groupRequest=payload
  },
  setGroupEvent(state:any,payload:any){
    state.groupEvents=payload
  },
};
