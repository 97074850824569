import axios from "@/axios";
export default {
  getAllGrade: async function (
    context: any,
    payload: { courseId: number; limit: 20; offset: 0; student: false }
  ) {
    const response = await axios.get(
      `/courses/${payload.courseId}/grade-items`,
      {
        params: {
          limit: payload?.limit ?? 50,
          offset: payload?.offset ?? 0,
          student: payload?.student ?? false,
        },
      }
    );
    context.commit("setGrade", response.data);
  },
  createGrade: async function (context: any, payload: any) {
    const response = await axios.post(
      `courses/${payload.courseId}/grade-items`,
      payload.body
    );
  },
  getGradeItem: async function (context: any, payload: any) {
    const response = await axios.get(
      `courses/${payload.courseId}/grade-items/${payload.gradeItemId}`
    );
    context.commit("setSingleGradeItem", response.data);
  },
  updateGradeItem: async function (context: any, payload: any) {
    await axios.patch(
      `courses/${payload.courseId}/grades/${payload.gradeItemId}`,
      payload.body
    );
  },
  getAllEnterGradeItem: async function (context: any, payload: any) {
    const response = await axios.get(
      `/courses/${payload.courseId}/grade-items/${payload.gradeItemId}/grades?limit=50&offset=0`
    );
    context.commit("setGradeItem", response.data);
  },
  SaveAllStudentGrade: async function (context: any, payload: any) {
    const body = {
      data: payload.studentArray,
    };

    await axios.patch(
      `courses/${payload.courseId}/grade-items/${payload.gradeItemId}/grades`,
      body
    );
  },
  UpdateStudentGrade: async function (
    context: any,
    payload: { studentId: number; grade: number; comment: string }
  ) {
    context.commit("findIndex", payload.studentId);
    context.commit("setStudentGrade", payload);
  },
  UpdateStudentFeedback: async function (context: any, payload: any) {
    context.commit("findIndex", payload.studentId);
    context.commit("setStudentFeedback", payload);
  },
  clearStudentsGradesArray: async function (context: any, payload: any) {
    context.commit("clearGradesArray");
  },
  addFeedbackModal: async function (context: any, payload: any) {
    if (payload.type == "open") {
      context.commit("setModalFeedback", true);
      context.commit("setModalFeedbackGrade", payload.grade);
      context.commit("setModalFeedbackItemGrade", payload.oneGradeItem);
    } else if (payload.type == "close") {
      context.commit("setModalFeedback", false);
      context.commit("setModalFeedbackGrade", null);
      context.commit("setModalFeedbackItemGrade", null);
    }
  },
  deleteGradeItem: async function (context: any, payload: any) {
    try {
      const { status, data } = await axios.delete(
        `courses/${payload.courseId}/grade-items/${payload.gradeItemId}`
      );
      context.dispatch("getAllGrade", payload);
      return status;
    } catch (e) {
      console.log(e);
      return 404;
    }
  },

};
