import { RouteRecordRaw } from "vue-router";
const mulhemRoutes: Array<RouteRecordRaw> = [

    {
        path: "/mulhem",
        name: "Mulhem Program",
        component: () => import('@/layout/Mulhem.vue'),
        children: [
            {
                name:'Main Mulhem',
                path:'',
                component:()=> import('@/page/mulhem/Landing.vue')

            },
            {
                name:'Main Lectures',
                path:'lectures',
                component:()=> import('@/page/mulhem/MulhemCourse.vue')

            },
            {
                name:'Chief Resident Leadership',
                path:'chief-resident-leadership',
                component:()=> import('@/page/mulhem/ChiefResidentLeadership.vue')

            },
            {
                name:'Horizons Lectures',
                path:'horizon-program',
                component:()=> import('@/page/mulhem/Horizons.vue')

            }


        ]
    },


]
export default mulhemRoutes;
