import { RouteRecordRaw } from "vue-router";

const studentRoutes: Array<RouteRecordRaw> = [
  {
    path: "/lms",
    name: "learning-management-system",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/layout/StudentApp.vue"),
    children: [
      {
        path: "courses",
        name: "E-learning",
        component: () => import("@/modules/course/src/pages/AllCourses.vue"),
      },
      {
        path: "course",
        name: "Course Details",
        component: () => import("@/modules/course/src/pages/CourseDetails.vue"),
      },
    ],
  },
];

export default studentRoutes;
