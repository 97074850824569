<template>
  <button
      class="button"
      v-if="buttonType === 'button'"
      :class="[classOptions ?? '']"
  >
    <slot>
      {{ text }}
    </slot>
  </button>
  <router-link to="" class="button" :class="[classOptions ?? '']" v-else>
    <slot>
      {{ text }}
    </slot>
  </router-link>
</template>
<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: "button",
    },
    classOptions: {
      type: String,
      default: "button-primary",
    },
    text: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

// @media only screen and (max-width: 457px) {
//   .button {
//     min-width: 60px !important;
//   }
// }
</style>
