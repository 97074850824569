export default {
  setQuizez(state: any, payload: any) {
    state.quizes = payload;
  },
  setQuiz(state: any, payload: any) {
    state.quiz = payload;
  },



  GetExam(state: any, payload: any) {
    state.getExam = payload;
  },

  setFullGrade(state: any, payload: any) {
    state.fullGrade = payload;
  },
  setMyGrade(state: any, payload: any) {
    state.myGrade = payload;
  },
  setReviewQuestion(state: any, payload: any) {
    state.reviewQuestion = payload;
  },
  setNoAttempt(state:any,payload:any){
    state.NoAttempt=payload
  },
  setAllQuizesAttempt(state:any,payload:any){
    state.AllQuizesAttempt=payload
  },
  setAllQuizesInstructor(state:any,payload:any){
    state.AllQuizesInstructor=payload
  }
,
setQuizesInstructor(state:any,payload:any){
  state.QuizesInstructor=payload
}
  
};
