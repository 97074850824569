<template>
  <MDBModal
    class="modal-chat"
    :model-value="showChatModal"
    centered
    :keyboard="false"
    @hidden="resetModal"
  >
    <MDBModalBody class="modal-body" @change="tri">
      <form
        @submit.prevent="sendData"
        v-if="dataRequired"
        class="guest-data-container"
      >
        <p>Please Fill Your Data :</p>
        <label for="guestName">Name</label>
        <input
          v-model.trim="name"
          type="text"
          name="guestName"
          id="guestName"
        />
        <label for="guestEmail">Email</label>
        <input
          type="email"
          v-model.trim="email"
          name="guestEmail"
          id="guestEmail"
        />
        <label for="guestTel">Phone Number</label>
        <input
          type="tel"
          v-model.trim="phoneNumber"
          name="guestTel"
          id="guestTel"
        />
        <button class="swap-button" type="submit">Submit</button>
      </form>
      <ChatContainer v-else />
      <!-- <img  src="@/assets/images/close.svg" alt="close" style="cursor : pointer" @click="toggleChatModal(false)" >

    <p>test</p> -->
    </MDBModalBody>
  </MDBModal>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import ChatContainer from "./ChatContainer.vue";

export default defineComponent({
  // components:{
  //     MDBModal,MDBModalBody ,MDBInput ,MDBBtn ,MDBSpinner
  // },

  data() {
    return {
      loading: false,
      status: true,
      name: "",
      email: "",
      phoneNumber: "",
    };
  },

  components: {
    ChatContainer,
  },

  computed: {
    ...mapGetters({
      showChatModal: "chatSupport/showChatModal",
      getRoomStatus: "chatSupport/getRoomStatus",
      referenceNumber: "chatSupport/getReferenceNumber",
      getRoomId: "chatSupport/getRoomId",
      referenceNumberDetails: "chatSupport/getReferenceNumberDetails",
      dataRequired: "chatSupport/getDataRequired",
    }),
  },

  watch: {
    referenceNumberDetails(value) {
      let i = value;
    }
  },

  methods: {
    ...mapActions({
      toggleChatModal: "chatSupport/toggleChatModal",
      addDataRequired: "chatSupport/changeDataRequired",
    }),

    resetModal() {
      this.toggleChatModal(false);
      this.loading = false;
    },
    async sendData(){
      if (this.name && this.email && this.phoneNumber){
        await this.$socket.emit(
          "updateGuest",
          {
            name: this.name,
            email: this.email,
            phoneNumber: `${this.phoneNumber}`,
            accessToken: sessionStorage.getItem("socketToken"),
          },
          async (response) => {
            this.addDataRequired(false)
          }
        );
      }
    },
  },
});
</script>

<style lang="scss">
.modal-content {
  border-radius: 30px 30px 24px 24px;
}

.modal-body {
  padding: 0;
}

.swap-button {
  outline: none;
  border: 1px solid;
  cursor: pointer;
  border-radius: 6px;
  margin-top: 10px;
}

.guest-data-container {
  height: 300px;
  background-color: #ffffff;
  border: none;
  margin: auto;
  border-radius: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}
</style>
