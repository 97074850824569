import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      grade: [],
      singleGradeItem: {},
      gradeItem: {},
      studentGrade: [],
      indexOfStudentGrade: -1,
      indexOfStudentList: -1,
      StudentListGrade : [],
      modalFeedback:false,
      modalFeedbackGrade:null,
      modalFeedbackItemGrade:null,

    };
  },
  mutations,
  actions,
  getters,
};
