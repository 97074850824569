import axios from "@/axios";
import { computed } from "vue";

export const coachAuth = {
  namespaced: true,
  state: {
    isCoach:false,
    coachEmail:'',
    coachPassword:''
  },

  getters: {
    isCoach(state:any){
      return state.isCoach;
    },
    coachEmail(state:any){
      return state.coachEmail;
    },
    coachPassword(state:any){
      return state.coachPassword;
    },
  },

  mutations: {
  isCoach(state:any,value:boolean){
    state.isCoach=value;
  },
  setCoachEmail(state:any,payload:any){
    state.coachEmail=payload;
  },
  setCoachPassowrd(state:any,payload:any){
    state.coachPassword=payload;
  },
  },
  actions: {
    setCoachEmail({commit}:any,payload:any){
      commit('setCoachEmail',payload);
    },
    setCoachPassowrd({commit}:any,payload:any){
      commit('setCoachPassowrd',payload);
    },
    // async login(context:any,user:any){
    //   const response = await axios.post('auth/login',user);
    //   // console.log(response)
    //   context.commit('auth/SET_ACCESS_TOKEN',response.data.access);
    //   context.commit('auth/SET_REFRESH_TOKEN',response.data.refresh);
    //   const res = await axios.get('auth');
    //   const coach= await axios.get(`coaches/${res.data.id}`);
    //   console.log(coach)
    // }
  },
};
