import axios from "@/axios";
import router from "@/router/index.routes";
import { computed } from "vue";

let timer: any;
export const auth = {
  namespaced: true,
  state: {
    showLoginModal: false,
    showModal: false,
    accessTokenexpirationDate: null,
    massages: false,
    forgetPassword: false,
    resetPasswordModal: false,
    showSignUpModal: false,
    showVerificationModal: false,
    authenticated: false,
    isVerified: false,
    accessToken: null,
    resetPasswordToken: null,
    refreshToken: null,
    user: {},
    codeModal: false,
  },

  getters: {
    authenticated(state: any) {
      return state.authenticated;
    },
    accessToken(state: any) {
      return state.accessToken;
    },
    refreshToken(state: any) {
      return state.refreshToken;
    },
    user(state: any) {
      return state.user;
    },
    isInstructor(state: any) {
      const role = state.user?.userRoles.filter((role: any) => {
        return role.role?.toLowerCase() === "INSTRUCTOR".toLowerCase();
      });
      return role.length > 0;
    },
    isCoach(state: any) {
      const role = state.user?.userRoles.filter((role: any) => {
        return role.role?.toLowerCase() === "COACH".toLowerCase();
      });
      return role.length > 0;
    },
    showLoginModal(state: any) {
      return state.showLoginModal;
    },
    showSignUpModal(state: any) {
      return state.showSignUpModal;
    },
    showModal(state: any) {
      return state.showModal;
    },
    showResetPasswordModal(state: any) {
      return state.resetPasswordModal;
    },
    VerificationModal(state: any) {
      return state.showVerificationModal;
    },
    resetPasswordToken(state: any) {
      return state.resetPasswordToken;
    },
    forgetPassword(state: any) {
      return state.forgetPassword;
    },
    accessTokenexpirationDate(state: any) {
      return state.accessTokenexpirationDate;
    },
    massages(state: any) {
      return state.massages;
    },
    codeModal(state: any) {
      return state.codeModal;
    },
    isAdmin(state: any) {
      let userState = false;
      state.user?.userRoles.map((item: any) => {
        if (item.role === "ADMIN") {
          userState = true;
        }
      });
      return userState;
    },
  },

  mutations: {
    openCodeModal(state: any, value: any) {
      state.codeModal = value;
    },
    SET_AUTHENTICATED(state: any, value: any) {
      state.authenticated = value;
    },
    SET_ACCESS_TOKEN(state: any, value: any) {
      state.accessToken = value;
    },
    SET_REFRESH_TOKEN(state: any, value: any) {
      state.refreshToken = value;
    },
    SET_USER(state: any, user: any) {
      state.user = user;
    },
    setAccessTokenexpirationDate(state: any, payload: any) {
      state.accessTokenexpirationDate = payload;
    },
    openLoginModal(state: any) {
      state.showLoginModal = true;
    },
    openSignUpModal(state: any) {
      state.showSignUpModal = true;
    },
    openMassages(state: any) {
      state.massages = true;
    },
    openModal(state: any) {
      state.showModal = true;
    },
    openVerificationModal(state: any) {
      state.showVerificationModal = true;
    },
    openForgetPassword(state: any) {
      state.forgetPassword = true;
    },
    openResetPasswordModal(state: any) {
      state.resetPasswordModal = true;
    },
    setResetPasswordToken(state: any, payload: any) {
      state.resetPasswordToken = payload;
    },

    closeLoginModal(state: any) {
      state.showLoginModal = false;
    },
    closeSignUpModal(state: any) {
      state.showSignUpModal = false;
    },
    closeModal(state: any) {
      state.showModal = false;
    },
    closeVerificationModal(state: any) {
      state.showVerificationModal = false;
    },
    closeForgetPassword(state: any) {
      state.forgetPassword = false;
    },
    closeResetPasswordModal(state: any) {
      state.resetPasswordModal = false;
    },
    closeMassages(state: any) {
      state.massages = false;
    },
    clearResetPasswordToken(state: any) {
      state.resetPasswordToken = null;
    },
  },

  actions: {
    openCodeModal({ commit }: any, value: boolean) {
      commit("openCodeModal", value);
    },
    async authenticate(context: any, tokens: any) {
      context.commit("SET_AUTHENTICATED", true);
      context.commit("SET_ACCESS_TOKEN", tokens.access);
      context.commit("SET_REFRESH_TOKEN", tokens.refresh);
      await context.dispatch("setUser");
    },

    async setUser(context: any) {
      const { data: user } = await axios.get("auth");
      context.commit("SET_USER", user);

    },
    async logout(context: any) {
      await axios.post("auth/logout");
      context.dispatch("removeAuthentication");
    },
    removeAuthentication(context: any) {
      context.commit("SET_AUTHENTICATED", false);
      context.commit("SET_ACCESS_TOKEN", null);
      context.commit("SET_REFRESH_TOKEN", null);
      context.commit("SET_USER", {});
      context.commit("verification", null);

      router.push("/");
    },
    async loginByToken(context: any, token: any) {
      try {
        const tokens = await axios.post(`/auth/2fa?token=${token}`, token);
        await context.dispatch("authenticate", tokens.data);
        await context.dispatch("setUser").then(() => {
          router.replace({ path: "/groups/index" }).then(() => {
            location.reload();
          });
        });
      } catch (err) {
        console.log(err);
      }
    },
    async confirmEmail(context: any, token: any) {
      try {
        await axios.post("email-confirmation/confirm", {
          token: token,
        });
      } catch {
        // context.commit('isReConfirmed');
        // context.commit('isNotConfirmed');
      }
    },
    async getNewToken(context: any) {
      const refreshToken = context.getters["refreshToken"];
      const tokenExpiration = computed(() => {
        return context.getters["accessTokenexpirationDate"];
      });
      //log the token

      if (tokenExpiration.value) {
        timer = setTimeout(async () => {
          const responseData = await axios.get(
            `auth/refresh?token=${refreshToken}`
          );
          //   localStorage.setItem("access_token", responseData.data.access);
          context.commit("SET_ACCESS_TOKEN", responseData.data.access);
        }, +tokenExpiration.value);
      }
    },
    async login(context: any, data: any) {
      const tokens = await axios.post("auth/login", data);
      await context.dispatch("authenticate", tokens.data);
    },
    autoLogin(context: any) {
      const tokenExpiration = context.getters["accessTokenexpirationDate"];
      const expiresIn = tokenExpiration - new Date().getTime();
      // if (expiresIn < 0) {
      //   context.dispatch("getNewToken");

      //   return;
      // }
      // timer = setTimeout(() => {
      //   context.dispatch("getNewToken");

      // }, expiresIn);
    },
    async refreshUserData(context: any, token: string) {
      try {
        await context.dispatch("authenticate", token);
        await context.dispatch("setUser").then(() => {
          // const isVerified = computed(() => {
          //   return context.getters["isVerified"];
          // });
          // if ( tokens) {
          //   //here is it
          //   // store.commit("auth/SET_AUTHENTICATED", false);

          //   // store.commit("auth/closeLoginModal");
          //   context.commit("openModal");
          //   context.commit("openVerificationModal");

          //   return;
          // }

          router.replace({ name: "Home" }).then(() => {
            location.reload();
          });
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
