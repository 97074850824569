import { group } from "../group";

export default {
  recentPost(state: any) {
    return state.recentPost;
  },
  suggestedCourses(state: any) {
    return state.suggestedCourses;
  },
  suggestedCoaches(state: any) {
    return state.suggestedCoaches;
  },
  suggestedEvents(state: any) {
    return state.suggestedEvents;
  },
  suggestedGroups(state: any) {
    return state.suggestedGroups;
  },
  groupPost(state: any) {
    return state.groupPost;
  },
  userMainInfo(state: any) {
    return state.userMainData;
  },
  group(state: any) {
    return state.group;
  },
  allGroups(state: any) {
    return state.allGroups;
  },
  groupsAdmins(state: any) {
    return state.admins;
  },
  groupMember(state: any) {
    return state.groupMember;
  },
  groupRequest(state: any) {
    return state.groupRequest;
  },
  groupEvents(state: any) {
    return state.groupEvents;
  },
  isAdmin(state: any, _: any, rootState: any) {
    let GroupStatus = false;
    state.group?.members.map((item: any) => {
      if (item.user.id === rootState?.auth?.user?.id) {
        if (item.groupRole === "ADMIN") {
          GroupStatus = true;
        } else {
          GroupStatus = false;
        }
      }
    });
    return GroupStatus;
  },
  isJoined(state: any) {
    return state.group?.status === "ACCEPTED";
  },
};
