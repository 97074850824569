export default {
    getActiveTranscript(state: any): number {
        return state.activeTranscript;
    },
    getRunTranscript(state: any): boolean {
        return state.runTranscript;
    },
    getRunTranscriptAudio(state: any): boolean {
        return state.runTranscriptAudio;
    }

}
