import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";
import routes from "./routes";
import axios from "@/axios";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
router.beforeEach(async (to, from, next) => {
    if (to.fullPath === "/coachingpanel") {
        const res = await axios.get("calendly/v2/check-calendly-login");
        if (res.data) {
            const resp = await axios.get("calendly/v2/user-event-types");
            if (resp.data.length > 0) {
                next({name: "CoachDashboard"});
            } else {
                next({name: "Sessions"});
            }
        } else {
            next({name: "dashboard"});
        }
    }
    if (to.params.id && to.name === "profile") {
        await store.dispatch("profile/getUser", to.params.id);
        next();
    }
    if (to.query.token && to.name === "Login Page") {
        await store.dispatch("auth/loginByToken", to.query.token).then(() => {
            const accessTokenexpirationDate = new Date().getTime() + 6 * 60 * 1000; //edite
            store.commit(
                "auth/setAccessTokenexpirationDate",
                accessTokenexpirationDate
            );
        });
    }
    if (to.name === "Calendar") {
        await store.dispatch("calendar/getEvents");
        next();
    }
    if (to.query.code && to.params.pathMatch[1] === "calendly") {
        const res = await axios.post("calendly/v2/login-to-calendly", {code: to.query.code});
        next({name: "Sessions"});
    } else {
        next();
    }
});
router.beforeEach((to, from, next) => {
    if (
        to.fullPath === "/coaching-panel/coach-dashboard" ||
        to.fullPath === "/coaching-panel/dashboard"
    ) {
        store.dispatch("coaching/setCurrent", "dashboard");
    } else if (
        to.fullPath === "/coaching-panel/calendar" ||
        to.fullPath === "/coaching-panel/coach-calendar" ||
        to.fullPath === "/coaching-panel/sessions"
    ) {
        store.dispatch("coaching/setCurrent", "calendar");
    } else if (to.fullPath === "/coaching-panel/profile") {
        store.dispatch("coaching/setCurrent", "profile");
    } else if (to.fullPath === "/coaching-panel/settings") {
        store.dispatch("coaching/setCurrent", "settings");
    }

    if (to.path === "/confirm-email") {
        store.dispatch("auth/confirmEmail", to.query.token);
        next({name: "LandingPage"});
        // open the password modal
    }
    if (to.path === "/reset-password") {
        store.commit("auth/setResetPasswordToken", to.query.token);
        window.location.assign("/");
        setTimeout(() => {
            location.reload();
        }, 100);
        // open the password modal
    }
    const authenticated = store.getters["auth/authenticated"];
    const isVerified = store.getters["auth/verification"];
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        authenticated && !isVerified ? next() : next({name: "Register Page"});
    } else {
        next();
    }
});
export default router;
