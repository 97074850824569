import axios from "@/axios";
import moment from "moment";

export default {
  async getRecentPost(context: any, payload: any) {
    const recentPost = await axios.get(
      `users/${payload.id}/home?offset=0&limit=${payload.limits}`
    );
    context.commit("setRecentPost", recentPost.data);
  },
  async savePost(context: any, payload: any) {
    await axios.post(`users/${payload.userId}/saved-posts/${payload.postId}`, {
      save: !payload.isSave,
    });
  },
  async likePost(context: any, payload: any) {
    await axios.post(`users/${payload.userId}/likes/${payload.postId}`, {
      like: !payload.isLike,
    });
  },
  async getSuggestedCourse(context: any, params: any) {
    if (!params.limit) {
      params.limit = 3;
    }
    if (!params.offset) {
      params.offset = 0;
    }
    params.sort = "POPULARITY";
    const suggestedCourses = await axios.get(`/courses`, {
      params: params,
    });
    context.commit("setSuggestedCourses", suggestedCourses.data.data);
  },

  async getCommentPost(context: any, payload: any) {
    const comment = await axios.get(`posts/${payload.postId}/comments`);

    return comment.data;
  },

  async likeComment(context: any, payload: any) {
    await axios.post(
      `users/${payload.userId}/liked-comments/${payload.commentId}`,
      {
        like: !payload.isLike,
      }
    );
  },

  async ReplyComment(context: any, payload: any) {
    await axios.post(
      `course-sections/${payload.sectionId}/discussion-boards`,
      {
        content: payload.content,
        parentId: payload.parentId,
      }
    );
  },


  async ReplyCommentCourse(context: any, payload: any) {
    await axios.post(
      `courses/${payload.id}/discussion-boards`,
      {
        content: payload.content,
        parentId: payload.parentId,
      }
    );
  },

  // async deleteReply(context: any, payload: any) {
  //   await axios.delete(
  //     `course/${payload.id}/discussion-boards`,
  //     {
  //       parentId: payload.parentId,
  //     }
  //   );
  // },

  async editComment(context: any, payload: any) {
    await axios.patch(`comments/${payload.id}`, {
      content: payload.content,
    });
  },
  async deleteComment(context: any, payload: any) {
    await axios.delete(`comments/${payload.id}`);
  },
  async addComment(context: any, payload: any) {
    await axios.post(`posts/${payload.postId}/comments`, {
      content: payload.content,
    });
  },

  async getGroupPost(context: any, payload: any) {
    const recentPost = await axios.get(
      `groups/${payload.id}/posts?offset=0&limit=${payload.limits}`
    );
    context.commit("setGrouptPost", recentPost.data);
  },
  async deletePost(context: any, payload: any) {
    await axios.delete(`posts/${payload.id}`);
  },
  async pinnedPost(context: any, payload: any) {
    await axios.post(`groups/${payload.groupId}/posts/${payload.postId}`, {
      pin: !payload.isPinned,
    });
  },
  async createPost(context: any, payload: any) {
    await axios.post(`groups/${payload.id}/posts`, payload.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async editPost(context: any, payload: any) {
    await axios.patch(`posts/${payload.id}`, payload.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    location.reload();
  },
  async getSuggestedCoaches(context: any, params: any) {
    if (!params.limit) {
      params.limit = 3;
    }
    if (!params.offset) {
      params.offset = 0;
    }
    params.sort = "MOST_POPULAR";
    const suggestedCoaches = await axios.get(`/coaches`, {
      params: params,
    });
    context.commit("setSuggestedCoaches", suggestedCoaches.data);
  },
  async getSuggestedEvents(context: any) {
    let day: string | Date = new Date();
    day = day.toISOString();
    day = moment(day).format("YYYY-MM-DD");
    const suggestedEvents = await axios.get(
      `events?teaser=false&startTime=${day}`
    );
    context.commit("setSuggestedEvents", suggestedEvents.data);
  },
  async getSuggestedGroups(context: any) {
    const groups = await axios.get(`groups?limit=3&offset=0`);
    context.commit("setSuggestedGroups", groups.data);
  },

  async getUserMainInfo(context: any) {
    const userId = context.rootState.auth.user?.id;
    const userMain = await axios.get(`users/${userId}/home/info`);

    context.commit("setUserMainInfo", userMain.data);
  },
  async getGroupData(context: any, payload: any) {
    const { data: group } = await axios.get(`groups/${payload}`);

    context.commit("setGroup", group);
    context.commit("setGroupAdmin", group.members);
  },
  async getAllGroups(context: any) {
    const userId = context.rootState.auth.user?.id;
    const groups = await axios.get(`users/${userId}/groups?offset=0&limit=10`);
    context.commit("setAllGroups", groups.data);
  },
  async getGroupMember(context: any, payload: any) {
    const { data: member } = await axios.get(
      `groups/${payload.id}/members?offset=0&limit=${payload.limits}&requests=false`
    );
    context.commit("setGroupMember", member);
  },
  async getGroupRequest(context: any, payload: any) {
    const { data: requestGroup } = await axios.get(
      `groups/${payload.id}/members?offset=0&limit=${payload.limits}&requests=true`
    );
    context.commit("setGroupRequest", requestGroup);
  },
  async getGroupEvent(context: any, payload: any) {
    const { data: event } = await axios.get(`events?groupId=${payload}`);
    context.commit("setGroupEvent", event);
  },
  async groupRequestMember(context: any, payload: any) {
    await axios.post(`groups/${payload.groupId}/members/${payload.id}`, {
      action: payload.value,
    });
    await context.dispatch("getGroupMember", {
      id: payload.groupId,
      limits: 5,
    });
    await context.dispatch("getGroupRequest", {
      id: payload.groupId,
      limits: 5,
    });
    await context.dispatch("getGroupData", payload.groupId);
  },
  async ShareEventPost(context: any, payload: any) {
    //

    const { data: post } = await axios.post(
      `groups/${payload.groupId}/posts`,
      payload.formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    await context.dispatch("getGroupPost", { id: payload.groupId, limits: 5 });
  },
};
