import { RouteRecordRaw } from "vue-router";

const courseRoutes: Array<RouteRecordRaw> = [
    {
        path: "/course/:id",
        name: "Course-Inside-Screen",
        component: () => import("@/modules/course/src/pages/CourseMain.vue"),
        props: true,
        children: [
            {
                path: "content",
                name: "Course Landing page",
                component: () => import("@/modules/course/src/components/Content.vue"),
            },

            {
                path: "discussion",
                name: "course discussion page",
                component: () => import("@/modules/course/src/components/CourseDiscussion.vue"),
            },
            {
                path: "notes",
                name: "course notes page",
                component: () => import("@/modules/course/src/components/CourseNotes.vue"),
            },
            {
                path: "announcement",
                name: "course announcement page",
                component: () => import("@/modules/course/src/components/CourseAnnouncements.vue"),
            },
            {
                path: "quizzes",
                name: "quizzes page",
                component: () => import("@/modules/course/src/components/AllQuizzes.vue"),
            },
            {
                path: "grades",
                name: "grades page",
                component: () => import("@/modules/course/src/components/Grades.vue"),
            },
            {
                path: "review-quiz/:quizId/:subSectionId",
                name: "review-quiz",
                component: () => import("@/modules/course/src/components/ReviewAllAnswer.vue"),
            },
        ],
    },
];

export default courseRoutes;
