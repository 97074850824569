'use strict';
import axios from "@/axios";
import SubmitQuizAnswerInterface from '@/interfaces/quiz.answer.student.interface';
export default {
    SubmitAnswer: async function (context: any, payload: SubmitQuizAnswerInterface) {
        const questionId = payload.questionId;
        const body = payload;
        return await axios.post(`/answers/${questionId}/send`, body);
      },

}