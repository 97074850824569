import { GetCoachPosition } from "@/services/coach.service";

import { coachProfile } from "./coachProfile";
import { coachAuth } from "./coachAuth";
import axios from "@/axios";

export const coaching = {
  namespaced: true,
  state: {
    isConnected: false,
    isNda: false,
    currentTab: "",
    positions: [],
    positionsid: [],
  },

  getters: {
    isConnected(state: any) {
      return state.isConnected;
    },
    isNda(state: any) {
      return state.isNda;
    },
    currentTab(state: any) {
      return state.currentTab;
    },
    positions(state: any) {
      return state.positions;
    },
    positionsid(state: any) {
      return state.positionsid;
    },

  },

  mutations: {
    setIsConnected(state: any, payload: any) {
      state.isConnected = payload;
    },
    setIsNda(state: any, payload: any) {
      state.isNda = payload;
    },
    setCurrent(state: any, payload: any) {
      state.currentTab = payload;
    },
    setPositions(state: any, payload: any) {
      state.positions = payload;
    },
    setpositionsid(state: any, payload: any) {
      state.positionsid = payload;
    },
  },
  actions: {
    setIsConnected({ commit }: any, payload: any) {
      commit("setIsConnected", payload);
    },

    setIsNda({ commit }: any, payload: any) {
      commit("setIsNda", payload);
    },

    setCurrent({ commit }: any, payload: any) {
      commit("setCurrent", payload);
    },

   async setPositions({commit}:any,payload:any){

      const response = await GetCoachPosition();
      const Array2: any = [];
      const Array: any = [];

      response.data.map((item: any) => {
        Array.push(item.coachingPosition.id);
        Array2.push(item.coachingPosition.position);
      });
      commit("setpositionsid", Array);

      commit("setPositions", Array2);
    },

    async getCoach({ commit }: any, payload: any) {
      const coach = await axios.get(`coaches/${payload.user.id}`);
      console.log("coach", coach.data);

      commit("coachProfile/setUser", coach.data);
    },
  },
  modules: {
    coachProfile,
    coachAuth,
  },
};
