export default {
    sections(state: any): Promise<any> {
        return state.sections;
    },

    finishedSubsections(state: any): Promise<any> {
        return state.finishedSubsections;
    },

    SectionsData(state: any): Promise<any> {
        return state.SectionsData;
    },
    paginationArray(state: any): Promise<any> {
        return state.paginationArray;
    },
    courseNotes(state: any): Promise<any> {
        return state.courseNotes;
    },
    announcementsCount(state: any): Promise<any> {
        return state.announcementsCount;
    },
    announcements(state: any): Promise<any> {
        return state.announcements;
    },
    courseModules(state: any): Promise<any> {
        return state.courseModules;
    },
    courseModulesWithDiscussionBoardEnabled(state: any): Promise<any> {
        // return  course modules with discussion board enabled
        return state.courseModules.filter((module: any) => {
            return module.isDiscussionBoardEnabled;
        }   )// end filter
    },
    isEnrolled(state: any): Promise<any> {
        return state.isEnrolled;
    },
    module(state: any): Promise<any> {
        return state.module;
    },
    lightCourse(state: any): Promise<any> {
        return state.lightCourse;
    },
    courseSubsectionNotes(state :any): Promise<void> {
        return state.sectionNotes;
    }
};
