import axios from "@/axios";
import {
  RubricAssessmentResponse,
  UploadedMedia,
} from "@/interfaces/assessment.interface";

type RubricAssessments = RubricAssessmentResponse | null;
export default {
  addCriteria: async function (context: any, payload: any) {
    const newCriteria = {
      description: payload.description,
      longDescription: payload.longDescription,
      maxGrade: payload.maxGrade,
      usesRange: false,
      ratings: [
        {
          grade: payload.maxGrade,
          title: "Full Marks",
          description: "",
        },
        {
          grade: 0,
          title: "No Marks",
          description: "",
        },
      ],
    };
    context.commit("addCriteria", newCriteria);
  },
  editCriteria: async function (context: any, payload: any) {
    context.commit("findRow", payload.criteria);
    context.commit("editCriteria", payload.newCriteria);
  },
  removeCriteria: async function (context: any, payload: any) {
    context.commit("findRow", payload);
    context.commit("removeCriteria");
  },
  getAllCourseRubric: async function (context: any, payload: any) {
    const response = await axios.get(
      `/courses/${payload.courseId}/rubrics?limit=50&offset=0`
    );
    context.commit("setRubric", response.data);
  },
  addRating: async function (context: any, payload: any) {
    context.commit("findRow", payload.criteria);
    context.commit("findColumn", payload.rating.grade);
    context.commit("addRating", payload.rating);
    context.commit("sortColumn");
  },
  removeRating: async function (context: any, payload: any) {
    context.commit("findRow", payload.criteria);
    context.commit("removeRating", payload.rating);
    context.commit("sortColumn");
  },
  editRating: async function (context: any, payload: any) {
    context.commit("findRow", payload.criteria);
    context.commit("findColumn", payload.newRating.grade);
    context.commit("editRating", payload);
    context.commit("sortColumn");
  },

  editTitle: async function (context: any, payload: any) {
    context.commit("setTitle", payload.title);
  },
  createRubric: async function (context: any, payload: any) {
    context.commit("createRubric", payload);
    context.dispatch("getAllCourseRubric", { courseId: payload });
  },

  setRubricData: async function (context: any, payload: any) {
    const response = await axios.get(
      `courses/${payload.courseId}/rubrics/${payload.rubricId}`
    );
    context.commit("setRubricData", response);
  },
  updateRubric: async function (context: any, payload: any) {
    const rubric = {
      title: context.state.title,
      criteria: context.state.criteria,
    };
    try {
      const { status, data } = await axios.put(
        `courses/${payload.courseId}/rubrics/${payload.rubricId}`,
        rubric
      );

      return status;
    } catch (e) {
      console.log(e);
      return 404;
    }
  },

  clearRubric: async function (context: any) {
    context.commit("clearRubric");
  },
  async getStudentRubricAssessment(
    context: any,
    payload: { studentId: number; gradeItemId: number; courseId: number }
  ): Promise<number> {
    try {
      const { data, status } = await axios.get<RubricAssessments>(
        `courses/${payload.courseId}/grade-items/${payload.gradeItemId}/grades/${payload.studentId}`
      );
      if (status === 200) {
        context.commit("setStudentAssessment", data);
        //get data assignmentSubmission for last submission of student in the array
        if (data && data?.assignmentSubmission?.uploadedMedia?.length > 0) {
          const lastSubmission =
            data?.assignmentSubmission?.uploadedMedia.length - 1;
          await context.dispatch(
            "changeActiveFile",
            data?.assignmentSubmission?.uploadedMedia[lastSubmission].id
          );
        }
        if (data && data?.rubricAssessment) {
          context.commit("setAssessmentRubric", data.rubricAssessment);
        }
      }
      return status;
    } catch (e) {
      console.log(e);
      return 404;
    }
  },
  async changeActiveFile(context: any, fileId: number) {
    try {
      context.state.activeFile = null;
      const file =
        context.state.studentAssessment?.assignmentSubmission?.uploadedMedia.find(
          (file: UploadedMedia) => {
            return file.id === fileId;
          }
        );
      context.commit("setActiveFile", file);
    } catch (e) {
      console.log(e);
      return 404;
    }
  },
  async changeStudentRubricAssessment(context: any, payload: any) {
    context.commit("findCriteriaId", payload.criteriaId);
    context.commit("changeStudentRubricAssessment", payload);
  },
  async saveStudentRubricAssessment(context: any, payload: any) {
    const body = {
      selectedRatings: payload.body,
    };
    await axios.post(
      `courses/${payload.courseId}/grade-items/${payload.gradeItemId}/grades/${payload.studentId}/assessment`,
      body
    );
  },
  deleteRubric: async function (context: any, payload: any) {
    try {
      const { status, data } = await axios.delete(
        `courses/${payload.courseId}/rubrics/${payload.rubricId}`
      );
      context.dispatch("getAllCourseRubric", payload);
      return status;
    } catch (e) {
      console.log(e);
      return 404;
    }
  },
  clearData(context: any) {
    context.commit("clearData");
  },
  removeError(context: any) {
    context.commit("removeError");
  },
};
