<template>
  <div class="chat-container">
    <div class="chat__header">
      <div class="d-flex justify-content-between">
        <span class="chat__header__greetings"> Chat Support </span>

        <button @click="leaveRoom" class="close-btn">X</button>
      </div>


      <div class="d-flex justify-content-between mt-3">
        <p style="font-size: 1.2rem" v-if="typeof numberOfTicketsInWaiting === 'number'">
          {{ numberOfTicketsInWaiting === 0 ? "No" : numberOfTicketsInWaiting }}
          {{ numberOfTicketsInWaiting === 1 ? "customer" : "customers" }} in waiting before you </p>
        <p style="font-size: 1.2rem" class="px-2"
           v-if="typeof numberOfTicketsInWaiting === 'number' && numberOfTicketsInWaiting > 0 && responseTime> 0">
          Response time : {{ numberOfTicketsInWaiting * responseTime }} min</p>
      </div>
    </div>

    <!-- <button v-if="!hideLoadMore" @click="loadMessage">Load More</button> -->
    <chat-list :messages="messages" @loadMessage="loadMessage"></chat-list>

    <chat-form @submitMessage="sendMessage"></chat-form>
  </div>
</template>

<script>
import ChatList from "./message/ChatList.vue";
import ChatForm from "./message/ChatForm.vue";
import {mapActions, mapGetters} from "vuex"


export default {
  name: "chat-container",
  data() {
    return {
      messages: [],
      messageText: "",
      buffer: [],
      type: "",
      joined: false,
      name: "",
      typingDisplay: "",
      userId: "",
      referenceNumber: "",
      numberOfTicketsInWaiting: "",
      responseTime: "",
      accessToken: "",
      take: 15,
      cursor: undefined,
      hideLoadMore: false,
      chatListHeight: []
    };
  },
  components: {
    ChatList,
    ChatForm,
  },
  computed: {
    ...mapGetters({
      status: "chatSupport/getRoomStatus",
      roomName: "chatSupport/getRoomId",
      counter: "chatSupport/getMessageCounter"
    }),
  },


  async created() {
    this.userId = localStorage.getItem("id");
    this.accessToken = sessionStorage.getItem("socketToken");

    await this.$socket.emit("joinRoom", {roomId: this.roomName, accessToken: this.accessToken}, (response) => {
      let i = response
    });

    const findMessages = await this.$socket.on("joinRoom", (response) => {
      this.$socket.emit("numberOfTicketsInWaiting", {}, (response) => {
        this.numberOfTicketsInWaiting = response
      })
      this.$socket.emit("responseTime", {}, (response) => {
        this.responseTime = response
      })

      if (response.id) {
        this.addRoomStatus(response.status)
        if (response.status !== 'CLOSE') {
          this.referenceNumber = response.tickets[0].referenceNumber
        }
        return true
      }
      return false;
    });
    await this.$socket.emit("getAllMessages", {take: this.take, cursor: this.cursor}, (response) => {
      this.messages = response.reverse();
      this.$socket.on("message", (message) => {
        this.messages.push(message);
      });
      // console.log(this.messages.length)
      if (this.messages.length) {
        setTimeout(() => {
          const element = document.getElementById("chat__body");
          element.scroll({top: element.scrollHeight, behavior: "smooth"});
        }, 500);
      }
    });
    if (this.counter) {
      this.$socket.emit("resetCustomerMessageCounter", (response) => {
        this.addMessageCounter(response)
      });
    }


    this.$socket.on("sendMessage", (message) => {
      this.messages.push(message);
      setTimeout(() => {
        const element = document.getElementById("chat__body");
        element.scrollTop = element.scrollHeight;
      }, 0);
    });

    this.$socket.on("changeRoomStatus", (status) => {
      this.addRoomStatus(status)
    });


    // });
  },

  methods: {
    ...mapActions({
      addRoomName: "chatSupport/addRoomId",
      addRoomStatus: "chatSupport/changeRoomStatus",
      toggleChatModal: "chatSupport/toggleChatModal",
      addMessageCounter: "chatSupport/addMessageCounter",
    }),
    async sendMessage({msg, buffer, type}) {
      this.messageText = msg;
      this.buffer = buffer;
      this.type = type;
      if (this.status === "CLOSE") {
        await this.$socket.emit("changeRoomStatus", {status: "OPEN"}, (response) => {
          this.referenceNumber = response.tickets[0].referenceNumber
          this.$socket.emit(
              "sendMessage",
              {
                text: msg,
                accessToken: this.accessToken,
                status: "OPEN",
                buffer: Buffer.from(buffer),
                type: type,
                ticketReferenceNumber: this.referenceNumber,
              },
              (response) => {
                this.messageText = "";
                this.type = "";
                this.content = "";
                this.buffer = "";
              }
          );
          this.addRoomStatus("OPEN");
        });

      } else {
        this.$socket.emit(
            "sendMessage",
            {
              text: this.messageText,
              accessToken: this.accessToken,
              buffer: Buffer.from(this.buffer),
              type: this.type,
              ticketReferenceNumber: this.referenceNumber,
              status: this.status
            },
            (response) => {
              this.messageText = "";
              this.type = "";
              this.content = "";
              this.buffer = "";
            }
        );
      }

      this.messageText = "";
    },

    leaveRoom() {
      this.$socket.emit("leaveRoom", {accessToken: this.accessToken}, (response) => {
        let i = response
      });
      this.toggleChatModal(false)
    },

    async loadMessage() {
      this.cursor = this.messages[0].id
      if (!this.hideLoadMore) {
        await this.$socket.emit("getAllMessages", {take: this.take, cursor: this.cursor}, async (response) => {

          this.messages = [...response.reverse(), ...this.messages];
          if (response.length < this.take) {
            this.hideLoadMore = true
          }
        });
      }

    },
  },

  watch: {
    "messages"(value, oldValue) {
      this.chatListHeight.push(document.getElementById("chat__body").scrollHeight)
      if (this.chatListHeight.length >= 2) {
        const element = document.getElementById("chat__body");
        element.scroll({
          top: this.chatListHeight[this.chatListHeight.length - 1] - this.chatListHeight[this.chatListHeight.length - 2],
          behavior: "instant"
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  height: 500px;
  background-color: #ffffff;
  border: none;
  border-radius: 5rem;
  box-shadow: 0px 1px 20px #9c9cc855;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.chat__header {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 24px 24px 0px 0px;
  padding: 1.4rem 2.4rem;
  font-size: 16px;
  font-weight: 700;
}

.chat__header__greetings {
  color: #1f2f49;
}

.close-btn {
  background-color: white;
  border: none;
  cursor: pointer;
}

.close-btn-img {
  width: 25px;
  height: 20px;
}
</style>
