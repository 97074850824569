import axios from "@/axios";
export default {
  getGrade(state: any) {
    return state.grade;
  },
  singleGradeItem(state: any) {
    return state.singleGradeItem;
  },
  getGradeIem(state: any) {
    return state.gradeItem;
  },
  getStudentGrade(state: any) {
    return state.studentGrade;
  },

  getRubricState(state: any) {
    return state.addAllRubric;
  },
  getStudentListGrade(state: any) {
    return state.StudentListGrade;
  },
  modalFeedback(state: any) {
    return state.modalFeedback;
  },
  modalFeedbackGrade(state: any) {
    return state.modalFeedbackGrade;
  },
  modalFeedbackItemGrade(state: any) {
    return state.modalFeedbackItemGrade;
  },
};
