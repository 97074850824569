<template lang="html">
  <div style="z-index: 10">
    <div v-if="show" @click="this.$emit('close')" class="backdrop"></div>
    <dialog
      open
      v-if="show"
      :class="[
        { 'md-size': size == 'md' },
        { 'lg-size': size == 'lg' },
        { 'sm-size': size == 'sm' },
        'md-size',
      ]"
      style="z-index: 10"
    >
      <section>
        <div class="d-flex justify-content-between mb-4">
          <div>
            <h3 class="title" v-if="title">{{ title }}</h3>
          </div>
          <div>
            <img
              @click="this.$emit('close')"
              class="cursor"
              src="@/assets/images/close.svg"
              alt="close"
            />
          </div>
        </div>
        <slot></slot>
      </section>
    </dialog>
  </div>
</template>

<script>
export default {
  name: "BaseDialog",
  props: ["show", "title", "size"],
  emits: ["close"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/all.scss";
.backdrop {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  height: 100vh !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.829) !important;
  z-index: 10;
  opacity: 0.3;
}
dialog {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.452) !important;
  padding: 0;
  margin: 0;
  background-color: $white;
  max-height: 90vh;
  overflow: auto;
  &.md-size {
    width: 50%;
    @include screen-lg {
      width: 90%;
    }
  }
  &.sm-size {
    width: 30%;
    @include screen-lg {
      width: 90%;
    }
  }
  &.lg-size {
    width: 80%;
    @include screen-lg {
      width: 90%;
    }
  }
  .title {
    color: $primary2;
  }
  .cursor {
    cursor: pointer;
  }
}
section {
  padding: 2rem;
}
</style>
